import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tab } from '@mui/material'
import { styled } from '@mui/system'
import toast from 'react-hot-toast'
import { get4MChangeAbnorlInformationById } from 'app/services/fourMChangeAbnormalInformation.service'
import { useLocation } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function FourMChangeAbnormalChecksheet() {
    const id = new URLSearchParams(useLocation().search).get('id') || ''
    const [data, setData] = useState([])

    useEffect(() => {
      const fetchData = async () => {
          try {
              const res = await get4MChangeAbnorlInformationById(id);
              console.log(res.data, "API Response"); // Debugging API response
              
              if (res.data.success) {
                  setData(res.data.data); // Ensure res.data.data exists
              } else {
                  toast.error("Failed to fetch data");
              }
          } catch (error) {
              console.error(error);
              toast.error(error.message || "An error occurred");
          }
      };
  
      fetchData();
  }, [id]);

  console.log(data, "data") 
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={3} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} rowSpan={1} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                        DEPT.: PRODUCTION
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>PRD-F-004</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                        TITLE : 4M CHANGE & ABNORMAL INFORMATION SHEET
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>REV.</StyledTableCell>
                                    <StyledTableCell colSpan={2}>00/10.10.2021</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>EFF. DATE</StyledTableCell>
                                    <StyledTableCell colSpan={2}>12-03-25</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell>DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.dateCompareString}</StyledTableCell>
                                    <StyledTableCell>Machine No.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.machineNo}</StyledTableCell>
                                    <StyledTableCell>Part:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.productName}</StyledTableCell>
                                    <StyledTableCell>Setup:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.setup}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell>Shift: </StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.shift}</StyledTableCell>
                                    <StyledTableCell colSpan={9}>Tick '√' in Appropriate Box</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>Type Of Change:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.typeOfChange}</StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        Man
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Machine
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Material
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Abnormality
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>Operator Name:</StyledTableCell>
                                    <StyledTableCell colSpan={10}>{data?.operatorName}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={12}>Change Details:</StyledTableCell>
                                </TableRow>

                                {data?.changeDetails?.map((item, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell colSpan={12}>{item}</StyledTableCell>
                                    </TableRow>
                                ))}

                                <TableRow>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Sign Of Originator:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        {data?.signOfOriginator}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4}>Receipt conformation in Quality:</StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        QA Sign:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        {data?.QASign}
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>Time:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data?.qualityTime}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={6} align="center" subheader>
                                        Required Action
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center" subheader>
                                        Action Done
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center" subheader>
                                        Date & Time
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center" subheader>
                                        Sign
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data?.action?.map((item, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell colSpan={6}>{item.requieredAction}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.actionDone}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.dateAndTime}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.sign}</StyledTableCell>
                                    </TableRow>
                                ))}

                                <TableRow>
                                    <StyledTableCell colSpan={4} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        QA Sign
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        {data?.QASign}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>Remarks (if any)</StyledTableCell>
                                    <StyledTableCell colSpan={10}>{data?.remarks}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={12}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>Approved By:</StyledTableCell>
                                    <StyledTableCell colSpan={3}>{data?.approvedBy}</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Final Status:</StyledTableCell>
                                    <StyledTableCell colSpan={3}>{data?.finalStatus}</StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid> 
            </Box>
        </>
    )
}
