import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import {
    deleteProductData,
    getAllProductData,
} from 'app/redux/actions/Product/product.action'
import { getStockData } from 'app/services/PendingStockGroup.service'
import { getAllCurrentStock, getAllRawMaterialStock } from 'app/services/RawMaterialStock.service'
import { getAllStock } from 'app/services/product.service'
import { getAllProductStock } from 'app/services/productstock.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'

export default function StockCheck() {
    let counter = 1
    const [productArr, setProductArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getAllCurrentStock()
            if (res) {
                setProductArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Stocks'}>
               
                <ContentTable
                    data={productArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Description',
                            selector: (row) => row?.description,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Category',
                            selector: (row) => row?.category,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Part No',
                            selector: (row) => row?.partNo,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Unit',
                            selector: (row) => `${row?.quantityPer} ${row?.units}`,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Current Stock',
                            selector: (row) => row?.currentStock,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Total Qty',
                            selector: (row) => row?.currentStock * row?.quantityPer,
                            sortable: true,
                            searchable: true,
                        }
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
