import { Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addAssemblyProductToDB, updateAssemblyProduct, getAssemblyProductById } from 'app/services/AssemblyProducts.service'
import { getAllAssemblyProducts, getAllOtherProduct } from 'app/services/product.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAllRawMaterial } from '../../../services/rawMaterial.service'
import Select from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddAssemblyProduct() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const id = new URLSearchParams(useLocation().search).get('id') || ''

    const [productArr, setProductArr] = useState([])
    const [assemblyProductArr, setAssemblyProductArr] = useState([])
    const [selectedOutputProductObj, setSelectedOutputProductObj] = useState({})
    const [selectInputProduct, setSelectInputProduct] = useState([])
    const [selectedProductArr, setSelectedProductArr] = useState([])
    const [rawMaterials, setRawMaterials] = useState([])
    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([])

    const [combinationArr, setCombinationArr] = useState([])

    const getOtherProducts = async () => {
        try {
            const { data: res } = await getAllOtherProduct()
            if (res) {
                setProductArr(res?.data?.map((el) => ({ ...el, checked: false, label: el.name, value: el.name })))
            }
        } catch (error) {
            toastError(error)
        }
    }

    console.log(productArr, 'hzdjyg')

    const getAssemblyProduct = async () => {
        try {
            const { data: res } = await getAllAssemblyProducts()
            if (res) {
                setAssemblyProductArr(res.data.map((el) => ({ ...el, checked: false, label: el.name, value: el.name })))
            }
        } catch (error) {
            toastError(error)
        }
    }

    const [name, setName] = useState('')
    const [assemblyNo, setAssemblyNo] = useState('')

    const handleSubmit = async () => {
        try {
            let mergedProductArr = [
                ...selectedProductArr,
                ...selectInputProduct
            ];
    
            let uniqueProductArr = mergedProductArr.reduce((acc, curr) => {
                if (!acc.some(item => item._id === curr._id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);
            let obj = {
                name,
                assemblyNo: assemblyNo,
                productArr: uniqueProductArr.map((el) => {
                    return { productId: el._id }
                }),
                productOutId: selectedOutputProductObj?._id,
            }

            console.log(selectedProductArr, 'hzdjyg')
            console.log(obj, 'hzdjyg')

            if (id) {
                let { data: res } = await updateAssemblyProduct(id, obj)
                if (res) {
                    toastSuccess(res?.message)
                }
            } else {
                let { data: res } = await addAssemblyProductToDB(obj)
                if (res) {
                    toastSuccess(res?.message)
                }
            }

            navigate('/admin/viewAssemblyProduct')
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    useEffect(() => {
        getOtherProducts()
        getRawMaterials()
        getAssemblyProduct()
    }, [])

    const getRawMaterials = async () => {
        try {
            const { data: res } = await getAllRawMaterial()
            if (res) {
                setRawMaterials(res.data.filter((el) => el.category == 'ASSEMBLY').map((el) => ({ ...el, label: el.name, value: el.name })))
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleProductSelect = (index) => {
        let tempArr = productArr.map((el, i) => {
            if (i == index) {
                el.checked = !el.checked
            }
            return el
        })
        console.log(tempArr)
        setProductArr([...tempArr])
    }
    const handleOutputProductSelect = (index) => {
        let tempArr = assemblyProductArr.map((el, i) => {
            if (i == index) {
                el.checked = !el.checked
            } else {
                el.checked = false
            }
            return el
        })
        console.log(tempArr)
        setAssemblyProductArr([...tempArr])
    }

    const handleCombinationArr = (arr) => {
        setSelectedRawMaterialArr(arr)
        setCombinationArr([...selectedProductArr.map((el) => ({ ...el, rawMaterialArr: arr }))])
        console.log([...selectedProductArr.map((el) => ({ ...el, rawMaterialArr: arr }))])
    }

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const { data: res } = await getAssemblyProductById(id)
                    console.log(res.data, 'res')
                    if (res) {
                        setName(res.data[0].name)
                        setAssemblyNo(res.data[0].assemblyNo)
                        setSelectInputProduct(res?.data[0]?.products?.map((el) => ({ ...el, checked: false, label: el.name, value: el.name })))
                        setSelectedProductArr(res?.data[0]?.products?.map((el) => ({ ...el, checked: false, label: el.name, value: el.name })))
                        setSelectedOutputProductObj({
                            value: res?.data[0]?.productOutName,
                            label: res?.data[0]?.productOutName,
                        })
                    }
                } catch (error) {
                    console.error(error)
                    toastError(error)
                }
            }
            fetchData()
        }
    }, [id])

    console.log(selectedProductArr, 'selectedProductArr')
    console.log(selectedOutputProductObj, 'selectedOutputProductObj')

    return (
        <Container>
            <SimpleCard title="Add Assembly Product">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <div style={{ marginTop: 15 }}>Product name</div>
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />
                    <TextField type="text" name="Assembly Number" onChange={(e) => setAssemblyNo(e.target.value)} value={assemblyNo || ''} label="Assembly Number" sx={{ width: '100%', mt: 2 }} />

                    <div style={{ marginTop: 15 }}>Select Input Products</div>
                    <Select options={productArr} placeholder="Select Input Products" value={selectedProductArr} isMulti onChange={(e) => setSelectedProductArr(e)} />
                    {/* <div className="row">
                        <div className="col-12">
                            {productArr &&
                                productArr.map((el, index) => {
                                    return <FormControlLabel key={`${new Date().getTime()}${index}`} control={<Checkbox onChange={() => handleProductSelect(index)} checked={el?.checked} />} label={el?.name} />
                                })}
                        </div>
                    </div> */}
                    <div style={{ marginTop: 15 }}>Select Output Products</div>
                    <Select options={assemblyProductArr} placeholder="Select Output Product" value={selectedOutputProductObj} onChange={(e) => setSelectedOutputProductObj(e)} />

                    {/*<div style={{ marginTop: 15 }}>Raw Materials</div>
                    <Select options={rawMaterials} placeholder="Select  Raw Materials" onChange={(e) => handleCombinationArr(e)} isMulti />
                    {combinationArr &&
                        combinationArr.length > 0 &&
                        combinationArr.map((el) => {
                            return (
                                <>
                                    {el.rawMaterialArr &&
                                        el.rawMaterialArr.length > 0 &&
                                        el?.rawMaterialArr.map((elx) => {
                                            return <TextField type="text" name={`${el?.name} | ${elx.name}`} label={`${el?.name} | ${elx.name}`} sx={{ width: '100%', mt: 2 }} />
                                        })}
                                </>
                            )
                        })} */}

                    {/* <div className="row">
                        <div className="col-12">
                            {assemblyProductArr &&
                                assemblyProductArr.map((el, index) => {
                                    return <FormControlLabel key={`${new Date().getTime()}${index}`} control={<Checkbox onChange={() => handleOutputProductSelect(index)} checked={el?.checked} />} label={el?.name} />
                                })}
                        </div>
                    </div> */}

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Add
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
