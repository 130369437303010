import React, { useEffect, useState } from 'react'
import { Container, Typography, TextField, Grid, Paper, Button, Box, InputAdornment, CircularProgress, Divider, FormLabel, FormControl, Select, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import { getAllRawMaterial } from 'app/services/rawMaterial.service'
import { addPlatingConsumption, getPlatingConsumption, updatePlatingConsumption } from 'app/services/platingConsumption.service'

const AddChemical = () => {
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [loading, setLoading] = useState(true)
    const [inputValues, setInputValues] = useState({})
    const [unitValues, setUnitValues] = useState({})

    useEffect(() => {
        const fetchRawMaterialData = async () => {
            try {
                setLoading(true)
                const { data: res } = await getAllRawMaterial({ category: 'PLATING' })
                if (res) {
                    setRawMaterialArr(res.data)
                    const initialValues = {}
                    res.data.forEach((material) => {
                        initialValues[`${material._id}-consumption`] = ''
                        initialValues[`${material._id}-area`] = ''
                    })
                    setInputValues(initialValues)
                }
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        fetchRawMaterialData()
    }, [])

    useEffect(() => {
        const fetchPlatingConsumption = async () => {
            try {
                setLoading(true)
                const { data: res } = await getPlatingConsumption()
                console.log(res?.data, 'res.data')

                setInputValues((prevValues) => {
                    const updatedValues = { ...prevValues }
                    res?.data?.forEach((consumption) => {
                        updatedValues[`${consumption.rawMaterialId}-consumption`] = consumption.consumption || ''
                        updatedValues[`${consumption.rawMaterialId}-area`] = consumption.area || ''
                    })
                    return updatedValues
                })
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        }

        if (rawMaterialArr.length) {
            fetchPlatingConsumption()
        }
    }, [rawMaterialArr])

    const handleInputChange = (id, value) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [id]: value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const formData = rawMaterialArr.map((material) => ({
                rawMaterialId: material._id,
                rawMaterialName: material.name,
                consumption: inputValues[`${material._id}-consumption`] || '',
                area: inputValues[`${material._id}-area`] || '',
                consumptionUnit: 'ml',
                areaUnit: 'sq cm',
            }))

            const data = { formData }

            const res = await addPlatingConsumption(data)

            if (res) {
                console.log(res)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 3, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h4" component="h1" gutterBottom color="primary">
                        Add Chemical Consumption
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        Enter consumption values for plating chemicals
                    </Typography>
                </Box>

                <Divider sx={{ mb: 4 }} />

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            {rawMaterialArr.map((material) => (
                                <Grid item xs={12} sm={6} key={material._id}>
                                    <FormControl fullWidth>
                                        <FormLabel
                                            htmlFor={`material-${material._id}`}
                                            sx={{
                                                mb: 1,
                                                fontWeight: 'medium',
                                                color: 'text.primary',
                                            }}
                                        >
                                            {material.name}
                                        </FormLabel>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 2,
                                                alignItems: 'flex-end',
                                            }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    Consumption
                                                </Typography>
                                                <TextField
                                                    id={`material-${material._id}-consumption`}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Enter quantity"
                                                    value={inputValues[`${material._id}-consumption`]}
                                                    onChange={(e) => handleInputChange(`${material._id}-consumption`, e.target.value)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">ml</InputAdornment>,
                                                    }}
                                                />
                                            </Box>

                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    Area
                                                </Typography>
                                                <TextField
                                                    id={`material-${material._id}-area`}
                                                    variant="outlined"
                                                    fullWidth
                                                    placeholder="Enter Area"
                                                    value={inputValues[`${material._id}-area`]}
                                                    onChange={(e) => handleInputChange(`${material._id}-area`, e.target.value)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">sq cm</InputAdornment>,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </FormControl>
                                </Grid>
                            ))}
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, gap: 2 }}>
                            <Button variant="outlined" color="inherit">
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
                                Save Consumption Data
                            </Button>
                        </Box>
                    </form>
                )}
            </Paper>
        </Container>
    )
}

export default AddChemical
