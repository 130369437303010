import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Autocomplete
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { getBinById } from 'app/services/Bin.service'
import { getAllProduct } from 'app/services/product.service'
import { addProductBinMapping, deleteProductBinMapping, getBinProductMapping } from 'app/services/ProductBinMapping.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import moment from 'moment'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    }
}))

export default function ViewBinProductMapping() {
    const { id } = useParams()
    const [binDetails, setBinDetails] = useState(null)
    const [mappings, setMappings] = useState([])
    const [loading, setLoading] = useState(false)
    const [openMapModal, setOpenMapModal] = useState(false)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [quantity, setQuantity] = useState(1)

    useEffect(() => {
        fetchData()
    }, [id])

    const fetchData = async () => {
        setLoading(true)
        try {
            const [binRes, mappingRes, productsRes] = await Promise.all([
                
                getBinById(id),
                getBinProductMapping(id),
                getAllProduct()
            ])
            console.log(binRes.data.data,"@@@")
            setBinDetails(binRes.data.data)
            setMappings(mappingRes.data.data)
            setProducts(productsRes.data.data)
        } catch (error) {
            toastError('Error fetching data')
        }
        setLoading(false)
    }

    const handleAddMapping = async () => {
        if (!selectedProduct || quantity < 1) {
            toastError('Please fill all required fields')
            return
        }

        try {
            await addProductBinMapping({
                binId:id,
                binName: binDetails.name,
                productId: selectedProduct._id,
                productName: selectedProduct.name,
                quantity
            })
            toastSuccess('Product mapped successfully')
            setOpenMapModal(false)
            setSelectedProduct(null)
            setQuantity(1)
            fetchData()
        } catch (error) {
            toastError('Error mapping product')
        }
    }

    const handleDeleteMapping = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this mapping?')) {
                await deleteProductBinMapping(id)
                toastSuccess('Mapping deleted successfully')
                fetchData()
            }
        } catch (error) {
            toastError('Error deleting mapping')
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '80px',
            center: true
        },
        {
            name: 'Product Name',
            selector: (row) => row.productName,
            sortable: true,
            searchable: true,
            center: true
        },
        {
            name: 'Quantity',
            selector: (row) => row.quantity,
            sortable: true,
            center: true
        },
        {
            name: 'Created At',
            selector: (row) => moment(row.createdAt).format('DD-MM-YYYY HH:mm'),
            sortable: true,
            center: true
        },
        {
            name: 'Actions',
            cell: (row) => (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteMapping(row._id)}
                    size="small"
                >
                    Delete
                </Button>
            ),
            center: true
        }
    ]

    return (
        <Container>
            <SimpleCard title="Bin Details">
                {binDetails && (
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">Name:</Typography>
                                <Typography>{binDetails.name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">Bin Type:</Typography>
                                <Typography>{binDetails.binType}</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="subtitle1">Bin Size:</Typography>
                                <Typography>{binDetails.binSize}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </SimpleCard>

            <Box sx={{ mt: 3 }}>
                <SimpleCard title="Product Mappings">
                    <Box sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenMapModal(true)}
                            sx={{ mb: 2 }}
                        >
                            Map New Product
                        </Button>
                        <ContentTable
                            columns={columns}
                            data={mappings}
                            loading={loading}
                            reload={fetchData}
                        />
                    </Box>
                </SimpleCard>
            </Box>

            <Dialog
                open={openMapModal}
                onClose={() => setOpenMapModal(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Map Product to Bin
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenMapModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <Autocomplete
                            options={products}
                            getOptionLabel={(option) => option.name}
                            value={selectedProduct}
                            onChange={(_, newValue) => setSelectedProduct(newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Product"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                        <TextField
                            fullWidth
                            label="Quantity"
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 0))}
                            InputProps={{ inputProps: { min: 1 } }}
                            sx={{ mt: 2 }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenMapModal(false)}>Cancel</Button>
                    <Button onClick={handleAddMapping} variant="contained" color="primary">
                        Map Product
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
