import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getMRN } from 'app/services/mrn.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'

export default function ViewMrn() {
    let counter = 1
    const [mrnArr, setMrnArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getAllMrns()
    }, [])

    const getAllMrns = async () => {
        try {
            let { data: res } = await getMRN()
            if (res) {
                setMrnArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDownload = (id) => {
        navigate(`/download-mrn/${id}`)
    }
    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View MRN'}>
                <ContentTable
                    data={mrnArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'MRN Number',
                            selector: (row) => row?.mrnNumber,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'MRN Date',
                            selector: (row) => row?.mrnDate,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Supplier',
                            selector: (row) => row?.supplierName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'QA Judgement',
                            selector: (row) => row?.qaJudgement,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'QR Code',
                            selector: (row) => (
                                <Button variant="contained" color="primary" onClick={() => handleDownload(row?._id)}>
                                    Download
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
