import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deletePlatingCategory, getAllPlatingCategory } from '../../../services/platingCategory.service'

export default function ViewPlatingCategory() {

    const [PlatingCategory, setPlatingCategory] = useState([])

    const navigate = useNavigate()



    const getPlatingCategoryData = async () => {
        try {
            const { data: res } = await getAllPlatingCategory()
            setPlatingCategory(res.data)
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getPlatingCategoryData()
    }, [])



    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this material?')) {
                const res = await deletePlatingCategory(id)
                if (res.data?.message) toastSuccess(res.data.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleUpdate = async (id) => {
        try {
            navigate(`/admin/ProductRecipe/addPlatingCategory?id=${id}`)
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Plating Category'}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => navigate('/admin/ProductRecipe/addPlatingCategory')}
                    >
                        Add Sub Category
                    </Button>
                </Box>

                <ContentTable
                    data={PlatingCategory}
                    columns={[
                        {
                            name: 'Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Description',
                            selector: (row) => row?.description,
                            searchable: true,
                            sortable: true,
                        },


                        {
                            name: 'Update',
                            cell: (row) => (
                                <>
                                    <Button variant="outlined" color="primary" onClick={() => handleUpdate(row?._id)}>
                                        Update
                                    </Button>
                                </>
                            ),
                        },
                        {
                            name: 'Delete',
                            cell: (row) => (
                                <>
                                    <Button variant="outlined" color="error" onClick={() => handleDelete(row?._id)}>
                                        Delete
                                    </Button>
                                </>
                            ),
                        },

                    ]}
                />
            </SimpleCard>

        </Container>
    )
}
