import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Autocomplete,
    Grid,
    Typography,
    Paper
} from '@mui/material'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'
import { getAllBinType } from 'app/services/BinType.service'
import { getAllBinSize } from 'app/services/BinSizes.service'
import { getAllProduct } from 'app/services/product.service'
import { addBin, bulkGenerateBin, getAllBin } from 'app/services/Bin.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'

export default function BulkGenerateBin() {
    const [binTypes, setBinTypes] = useState([])
    const [binSizes, setBinSizes] = useState([])
    const [products, setProducts] = useState([])
    const [selectedBinType, setSelectedBinType] = useState('')
    const [selectedBinSize, setSelectedBinSize] = useState('')
    const [selectedProducts, setSelectedProducts] = useState([])
    const [productQuantities, setProductQuantities] = useState({})
    const [loading, setLoading] = useState(false)
    const [binQuantity, setBinQuantity] = useState(1)
    const [isProductMapping, setIsProductMapping] = useState(false)
    const [assignedProducts, setAssignedProducts] = useState([])

    useEffect(() => {
        fetchInitialData()
    }, [])

    const fetchInitialData = async () => {
        try {
            const [binTypeRes, binSizeRes, productRes] = await Promise.all([
                getAllBinType(),
                getAllBinSize(),
                getAllProduct()
            ])

            setBinTypes(binTypeRes.data.data || [])
            setBinSizes(binSizeRes.data.data || [])
            setProducts(productRes.data.data || [])
        } catch (error) {
            toastError('Error fetching data')
        }
    }

    const handleProductSelect = (event, values) => {
        // Check if product is already selected
        const uniqueProducts = values.filter(product =>
            !selectedProducts.some(selected => selected._id === product._id)
        )

        // Add quantity property to newly selected products
        const productsWithQuantity = uniqueProducts.map(product => ({
            ...product,
            quantity: 1
        }))

        // Combine with existing selected products
        setSelectedProducts([...selectedProducts, ...productsWithQuantity])
    }

    const handleQuantityChange = (productId, value) => {
        setSelectedProducts(prev =>
            prev.map(product =>
                product._id === productId
                    ? { ...product, quantity: parseInt(value) || 0 }
                    : product
            )
        )
    }

    const handleSelectAll = () => {
        // Add quantity property when selecting all products
        const allProductsWithQuantity = products.map(product => ({
            ...product,
            quantity: 1
        }))
        setSelectedProducts(allProductsWithQuantity)
    }

    const handleDeselectAll = () => {
        setSelectedProducts([])
        setProductQuantities({})
    }

    const handleSubmit = async () => {
        if (!selectedBinType || !selectedBinSize || binQuantity < 1) {
            toastError('Please fill all required fields')
            return
        }

        if (isProductMapping && selectedProducts.length === 0) {
            toastError({ message: "Please Select Products for mapping" })
            return
        }

        setLoading(true)
        try {
            const binsToCreate = []

            // Create bins based on binQuantity
            for (let i = 0; i < binQuantity; i++) {
                binsToCreate.push({
                    binTypeId: selectedBinType,
                    binSizeId: selectedBinSize,
                    productId: isProductMapping ? selectedProducts[0]?._id : null,
                    name: `BIN-${i + 1}`, // You can modify naming convention,
                    productsArr: selectedProducts.map(el => ({
                        productId: el._id,
                        quantity: el.quantity,
                        productName: el.name
                    }))
                })
            }

            console.log(binsToCreate, "Bins to create")

            let { data: res } = await bulkGenerateBin({ binArr: binsToCreate });
            if (res) {

                toastSuccess(res.message)
                // Reset form
                setSelectedBinType('')
                setSelectedBinSize('')
                setSelectedProducts([])
                setBinQuantity(1)
                setIsProductMapping(false)
            }

        } catch (error) {
            toastError('Error generating bins')
        }
        setLoading(false)
    }

    return (
        <Box sx={{ m: 3 }}>
            <SimpleCard title="Bulk Generate Bins">
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Bin Type</InputLabel>
                                <Select
                                    value={selectedBinType}
                                    label="Bin Type"
                                    onChange={(e) => setSelectedBinType(e.target.value)}
                                >
                                    {binTypes.map((type) => (
                                        <MenuItem key={type._id} value={type._id}>
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Bin Size</InputLabel>
                                <Select
                                    value={selectedBinSize}
                                    label="Bin Size"
                                    onChange={(e) => setSelectedBinSize(e.target.value)}
                                >
                                    {binSizes.map((size) => (
                                        <MenuItem key={size._id} value={size._id}>
                                            {size.name} ({size.length}x{size.width}x{size.height})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Number of Bins"
                                type="number"
                                value={binQuantity}
                                onChange={(e) => setBinQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                                InputProps={{ inputProps: { min: 1 } }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Product Mapping</InputLabel>
                                <Select
                                    value={isProductMapping}
                                    label="Product Mapping"
                                    onChange={(e) => {
                                        setIsProductMapping(e.target.value)
                                        if (!e.target.value) {
                                            setSelectedProducts([])
                                        }
                                    }}
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {isProductMapping && (
                            <>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                        <Button
                                            variant="outlined"
                                            onClick={handleSelectAll}
                                            disabled={!products.length}
                                        >
                                            Select All Products
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={handleDeselectAll}
                                            disabled={!selectedProducts.length}
                                        >
                                            Deselect All
                                        </Button>
                                    </Box>
                                    <Autocomplete
                                        multiple
                                        options={[...products, ...(products.length === 0 ? [] :
                                            products.filter(p => assignedProducts.includes(p._id))
                                        )]}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedProducts}
                                        onChange={handleProductSelect}
                                        getOptionDisabled={(option) => assignedProducts.includes(option._id)}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    opacity: assignedProducts.includes(option._id) ? 0.5 : 1
                                                }}>
                                                    <span>{option.name}</span>
                                                    {assignedProducts.includes(option._id) &&
                                                        <Typography color="error" variant="caption">
                                                            Already assigned
                                                        </Typography>
                                                    }
                                                </Box>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Products"
                                                variant="outlined"
                                                error={isProductMapping && selectedProducts.length === 0}
                                                helperText={
                                                    isProductMapping && selectedProducts.length === 0
                                                        ? "Please select at least one product"
                                                        : "Products already assigned to bins are disabled"
                                                }
                                            />
                                        )}
                                    />
                                </Grid>

                                {selectedProducts.length > 0 && (
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2 }}>
                                            <Typography variant="h6" gutterBottom>
                                                Specify Quantities
                                            </Typography>
                                            <Grid container spacing={2}>
                                                {selectedProducts.map((product) => (
                                                    <Grid item xs={12} md={4} key={product._id}>
                                                        <TextField
                                                            fullWidth
                                                            label={product.name}
                                                            type="number"
                                                            value={product.quantity || ''}
                                                            onChange={(e) => handleQuantityChange(product._id, e.target.value)}
                                                            InputProps={{ inputProps: { min: 1 } }}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )}
                            </>
                        )}

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={loading}
                                fullWidth
                            >
                                {loading ? 'Generating Bins...' : 'Generate Bins'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleCard>
        </Box>
    )
}
