import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Paper, TableContainer, InputAdornment } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllRawMaterialSubCategory } from 'app/services/RawMaterialSubCategory.service'
import { getAllMachine, getAllMould } from 'app/services/machine.service'
import { CATEGORY, UNITS } from 'app/utils/constant'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { addProductRecipe, getProductRecipeById, updateProductRecipe } from '../../../services/ProductRecipe.service'
import { addBOM, getMouldingBOMByProductId, getBOMByProductId, getBOMById, updateBOM } from 'app/services/BOM.service'
import { getAllProduct } from '../../../services/product.service'
import { getAllRawMaterial } from '../../../services/rawMaterial.service'
import { getAllAssemblyProduct } from 'app/services/AssemblyProducts.service'
import { getAllPlatingCategory } from 'app/services/platingCategory.service'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const SelectionSection = styled('div')(({ theme }) => ({
    height: '300px',
    overflow: 'hidden',
    marginBottom: '20px',
}))

const FormContainer = styled('div')({
    '& .MuiTextField-root, & .MuiSelect-root': {
        marginBottom: '8px',
    },
    '& .form-label': {
        fontWeight: 'bold',
        marginBottom: '4px',
        color: '#000',
    },
    '& .form-row': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        borderBottom: '1px solid #ccc',
    },
    '& .form-label-cell': {
        width: '200px',
        padding: '8px',
        backgroundColor: '#e6e6e6',
        borderRight: '1px solid #ccc',
    },
    '& .form-value-cell': {
        flex: 1,
        padding: '8px',
    },
    '& .table-container': {
        marginTop: '16px',
        border: '1px solid #ccc',
    },
    '& .table-header': {
        backgroundColor: '#e6e6e6',
        borderBottom: '1px solid #ccc',
        padding: '8px',
    },
})

export default function AddProductRecipe() {
    const navigate = useNavigate()

    const id = new URLSearchParams(useLocation().search).get('id') || ''

    const [product, setProduct] = useState([])
    const [productArr, setProductArr] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [mainRawMaterialArr, setMainRawMaterialArr] = useState([])
    const [selectedRawMaterials, setSelectedRawMaterials] = useState([])
    const [recipeData, setRecipeData] = useState([])
    const [productError, setProductError] = useState(false)
    const [rawMaterialError, setRawMaterialError] = useState(false)
    const [mould, setMould] = useState([])

    const [mouldBOM, setMouldBOM] = useState([])
    const [bom, setBom] = useState({})

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const recipeId = query.get('id')
    const [selectedProductDetails, setSelectedProductDetails] = useState(null)

    const [hsnCode, setHsnCode] = useState('')

    const [selectedUnit, setSelectedUnit] = useState('')
    const [selectedStage, setSelectedStage] = useState(null)
    const [mouldingBOM, setMouldingBOM] = useState({})
    const [platingArr, setPlatingArr] = useState([])
    const [selectedPlatingCategory, setSelectedPlatingCategory] = useState([])

    const [subCategoryArr, setSubCategoryArr] = useState([])

    const [selectedSubCategory, setSelectedSubCategory] = useState([])

    const [machineArr, setMachineArr] = useState([])

    const [packingStandard, setPackingStandard] = useState('')
    const [alternateUnit, setAlternateUnit] = useState('')
    const [selectedMachine, setSelectedMachine] = useState('')
    const [partCavity, setPartCavity] = useState(0)
    const [masterBatch, setMasterBatch] = useState('')
    const [show, setShow] = useState(false)

    const [grossWeight, setGrossWeight] = useState('')
    const [partWeight, setPartWeight] = useState('')
    const [runnerWeight, setRunnerWeight] = useState('')
    const [additionalInfo, setAdditionalInfo] = useState('')

    const [platingArea, setPlatingArea] = useState('')

    const [area, setArea] = useState('')

    const [minimum, setMinimum] = useState('')
    const [maximum, setMaximum] = useState('')
    const [source, setSource] = useState('')

    const [subCategoryRawMaterials, setSubCategoryRawMaterials] = useState([
        {
            subCategory: null,
            material: null,
            quantity: '',
            subCategoryUnit: null,
        },
    ])

    const unitArr = [
        { label: 'Kg', value: 'kg' },
        { label: 'gm', value: 'gm' },
        { label: 'Pcs', value: 'pcs' },
        { label: 'ML', value: 'ml' },
        { label: 'L', value: 'l' },
        { label: 'MM', value: 'mm' },
        { label: 'CM', value: 'cm' },
        { label: 'M', value: 'm' },
        { label: 'FT', value: 'ft' },
    ]

    useEffect(() => {
        const fetchPlatingCategory = async () => {
            try {
                const { data: res } = await getAllPlatingCategory()
                setPlatingArr(res?.data || [])
            } catch (error) {
                toastError(error)
            }
        }

        fetchPlatingCategory()
    }, [])

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const params = selectedStage?.value === CATEGORY.PLATING ? { plating: true } : {}

                let products = []

                if (selectedStage?.value !== CATEGORY.ASSEMBLY) {
                    const { data: res } = await getAllProduct(params)
                    products = res?.data || []
                } else {
                    const { data: res } = await getAllAssemblyProduct()
                    products = res?.data || []
                }

                setProduct(products)

                console.log(products, 'products')

                if (products.length) {
                    const tempArr = products.map((el) => ({
                        label: el.name,
                        value: el._id,
                        description: el.description,
                        partNo: el.id,
                    }))
                    setProductArr(tempArr)
                }
            } catch (error) {
                toastError(error)
            }
        }

        fetchProducts()
    }, [selectedStage?.value])

    useEffect(() => {
        const fetchRawMaterials = async () => {
            try {
                let rawMaterials = []
                if (selectedStage?.value == CATEGORY.PLATING) {
                    let { data: res } = await getAllRawMaterial({ category: 'PLATING' })
                    rawMaterials = res.data
                } else if (selectedStage?.value == CATEGORY.MOULDING || selectedStage?.value == CATEGORY.ASSEMBLY) {
                    let { data: res } = await getAllRawMaterial({ category: ['MOULDING', 'ASSEMBLY'] })
                    rawMaterials = res.data
                } else {
                    let { data: res } = await getAllRawMaterial()
                    rawMaterials = res.data
                }

                if (rawMaterials) {
                    let tempArr = rawMaterials?.map((el) => ({
                        label: el.name,
                        value: el._id,
                        unit: el.units,
                        quantityPer: el.quantityPer,
                        subCategoryId: el.subCategoryId,
                    }))
                    setRawMaterialArr(tempArr)
                    setMainRawMaterialArr(tempArr)
                }
            } catch (error) {
                toastError(error)
            }
        }
        fetchRawMaterials()
    }, [selectedStage])

    useEffect(() => {
        getAllRawMaterialSubCategoryArr()
        getAllMachineData()
    }, [recipeId])

    useEffect(() => {
        const fetchBOMData = async () => {
            if (!selectedProduct?.value) return

            if (selectedStage?.value === CATEGORY.PLATING) {
                try {
                    const id = selectedProduct?.value
                    const { data: res } = await getMouldingBOMByProductId(id)

                    if (res?.data) {
                        setMouldBOM(res.data)
                        setAdditionalInfo(res.data.additionalInfo)
                        setHsnCode(res.data.hsnCode)
                        setSelectedUnit({ label: res.data.unit, value: res.data.unit })
                        setSelectedMachine(res.data?.machineId)
                        setPackingStandard(res.data.packingStandard)
                        setAlternateUnit(res.data.ulternetUnit)
                        setPartCavity(res.data.cavity)
                        setMasterBatch(res.data.masterBatch)
                        setGrossWeight(res.data.grossWeight)
                        setPartWeight(res.data.partWeight)
                        setRunnerWeight(res.data.runnerWeight)
                    }
                } catch (error) {
                    toastError(error)
                }
            }
        }

        fetchBOMData()

        const timeout = setTimeout(() => {
            fetchBOMData()
        }, 500) // 500ms ke baad dobara fetch

        return () => clearTimeout(timeout)
    }, [selectedProduct])

    console.log(mouldBOM, 'mouldBOM')

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption)
        // console.log(selectedOption,"@@")

        let tempProductIndex = productArr.findIndex((elx) => elx.value == selectedOption?.value)
        //console.log(tempProductIndex, '@@', productArr[tempProductIndex])
        if (tempProductIndex != -1) {
            setSelectedProductDetails(productArr[tempProductIndex])
        }
        setProductError(false)
    }

    const handleRawMaterialChange = (selectedOptions) => {
        setSelectedRawMaterials(selectedOptions)
        setRawMaterialError(false)
    }

    const handleSubmit = () => {
        if (!selectedProduct) {
            setProductError(true)
            return
        }
        if (selectedRawMaterials.length === 0) {
            setRawMaterialError(true)
            return
        }

        // Generate recipe data for the selected raw materials
        const data = selectedRawMaterials.map((rawMaterial) => ({
            rawMaterial,
            quantity: '',
        }))
        setRecipeData(data)
    }

    const handleQuantityChange = (index, value) => {
        const updatedData = [...recipeData]
        updatedData[index].quantity = value
        setRecipeData(updatedData)
    }

    const handleFinalSubmit = async () => {
        try {
            let obj = {
                productId: selectedProduct?.value,
                productName: selectedProduct?.label,
                partNo: selectedProductDetails?.partNo,
                additionalInfo: additionalInfo,
                category: selectedStage?.value,
                hsnCode: hsnCode,
                unit: selectedUnit?.value,
                packingStandard: packingStandard,
                ulternetUnit: alternateUnit,
                machineId: selectedMachineMemo?.value || null,
                machineName: selectedMachineMemo?.label || null,
                cavity: partCavity,
                masterBatch: masterBatch,
                grossWeight: grossWeight,
                partWeight: partWeight,
                weightUnit: 'gm',
                runnerWeight: runnerWeight,
                platingArea: platingArea,
                platingAreaUnit: 'sq cm',
                platingCategory: selectedPlatingCategory,
                mouldingRawMaterialArr: selectedRawMaterials.map((item) => ({
                    rawMaterialId: item?.material?.value,
                    rawMaterialName: item?.material?.label,
                    rawMaterialQty: Number(item?.quantity),
                    unit: item?.unit?.label,
                })),
                mouldingRawMaterialSubCategoryArr: subCategoryRawMaterials.map((item) => ({
                    subCategoryId: item?.subCategory?.value,
                    subCategoryName: item?.subCategory?.label,
                    subCategoryQty: item?.quantity,
                    rawMaterialId: item?.material?.value,
                    rawMaterialName: item?.material?.label,
                    unit: item?.unit?.label,
                })),
                // Ensure it's an array
            }

            console.log(obj, 'obj')

            if (id) {
                let res = await updateBOM(id, obj)

                if (res) {
                    toastSuccess('Bom updated Successfully')
                    navigate('/admin/ProductRecipe/View')
                }
            } else {
                let res = await addBOM(obj)

                if (res) {
                    toastSuccess('Bom added Successfully')
                    navigate('/admin/ProductRecipe/View')
                }
            }

            // let obj = {
            //     productId: selectedProduct?.value,
            //     productName: selectedProduct?.label,
            //     mouldingRawMaterialArr: recipeData.map((el) => {
            //         return {
            //             rawMaterialId: el?.rawMaterial?.value,
            //             rawMaterialName: el?.rawMaterial?.label,
            //             rawMaterialQty: Number(el?.quantity),
            //             unit: el?.rawMaterial?.unit,
            //         }
            //     }),
            // }
            // let res
            // if (recipeId) {
            //     res = await updateProductRecipe(recipeId, obj)
            // } else {
            //     res = await addProductRecipe(obj)
            // }
            // if (res) {
            //     toastSuccess(res.message)
            // }
        } catch (error) {
            toastError(error)
        }
    }

    const getAllRawMaterialSubCategoryArr = async () => {
        try {
            const { data: res } = await getAllRawMaterialSubCategory()
            if (res) {
                setSubCategoryArr(res.data)
                console.log(res.data, 'res.data')
            }
        } catch (error) {
            toastError(error)
        }
    }

    const getAllMachineData = async () => {
        try {
            let { data: res } = await getAllMachine()
            if (res) {
                setMachineArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const selectedMachineMemo = useMemo(() => {
        if (!selectedMachine) return null

        const machineObj = machineArr.find((el) => el._id === selectedMachine)
        console.log(machineObj, 'machineObj')

        return machineObj ? { label: machineObj.name, value: machineObj._id } : null
    }, [selectedMachine, machineArr])

    const getFilteredRawMaterials = (subCategoryId) => {
        if (!subCategoryId) return []

        console.log(mainRawMaterialArr.filter((material) => material.subCategoryId === subCategoryId), 'mainRawMaterialArr.filter((material) => material.subCategoryId === subCategoryId)')

        return mainRawMaterialArr.filter((material) => material.subCategoryId === subCategoryId)
    }

    useEffect(() => {
        const fetchBOMData = async () => {
            if (id !== '') {
                try {
                    const { data: res } = await getBOMById(id)
                    if (res) {
                        setSelectedStage({ label: res.data.category, value: res.data.category })
                        setSelectedProduct({ label: res.data.productName, value: res.data.productId })
                        if (productArr.length > 0) {
                            const matchedProduct = productArr.find((el) => el.value === res.data.productId)
                            setSelectedProductDetails(matchedProduct)
                            console.log('Matched Product:', matchedProduct)
                        }

                        setAdditionalInfo(res.data.additionalInfo)
                        setHsnCode(res.data.hsnCode)
                        setSelectedUnit({ label: res.data.unit, value: res.data.unit })
                        setSelectedMachine(res.data?.machineId)
                        setPackingStandard(res.data.packingStandard)
                        setAlternateUnit(res.data.ulternetUnit)
                        setPartCavity(res.data.cavity)
                        setMasterBatch(res.data.masterBatch)
                        setGrossWeight(res.data.grossWeight)
                        setPartWeight(res.data.partWeight)
                        setRunnerWeight(res.data.runnerWeight)
                        setSelectedPlatingCategory(res.data.platingCategory)
                        setPlatingArea(res.data.platingArea)
                        setSelectedRawMaterials(
                            res.data.mouldingRawMaterialArr.map((item) => ({
                                material: { label: item.rawMaterialName, value: item.rawMaterialId },
                                quantity: item.rawMaterialQty,
                                unit: { label: item.unit, value: item.unit },
                            }))
                        )

                        setSubCategoryRawMaterials(
                            res.data.mouldingRawMaterialSubCategoryArr.map((item) => ({
                                subCategory: { label: item.subCategoryName, value: item.subCategoryId },
                                material: { label: item.rawMaterialName, value: item.rawMaterialId },
                                quantity: item.subCategoryQty,
                                unit: { label: item.unit, value: item.unit },
                            }))
                        )
                    }
                } catch (error) {
                    toastError(error)
                }
            }
        }

        fetchBOMData()
    }, [id, productArr])
    useEffect(() => {
        const fetchMould = async () => {
            try {
                if (!selectedMachine || !selectedProduct?.value) return // Prevent unnecessary API calls

                const res = await getAllMould(selectedMachine, selectedProduct.value)
                if (res) {
                    setMould(res.data.data)
                }
            } catch (error) {
                console.error('Error fetching mould data:', error)
            }
        }

        fetchMould()
    }, [selectedMachine, selectedProduct?.value])


    return (
        <>
            <Container>
                <SimpleCard title="Bill of Material">
                    <FormContainer>
                        {/* Category */}
                        <div className="form-row">
                            <div className="form-label-cell">CATEGORY:</div>
                            <div className="form-value-cell">
                                <Select
                                    value={selectedStage} // Store the whole object
                                    options={[
                                        { label: CATEGORY.MOULDING, value: CATEGORY.MOULDING },
                                        { label: CATEGORY.PLATING, value: CATEGORY.PLATING },
                                    ]}
                                    placeholder="Select Category"
                                    onChange={(e) => setSelectedStage(e)} // Store entire object
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: productError ? 'red' : base.borderColor,
                                        }),
                                        container: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        {/* Part Info */}
                        <div className="form-row">
                            <div className="form-label-cell">PART NAME:</div>
                            <div className="form-value-cell">
                                <Select
                                    value={selectedProduct}
                                    options={productArr}
                                    placeholder="Select Product"
                                    onChange={handleProductChange}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: productError ? 'red' : base.borderColor,
                                        }),
                                        container: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                                {productError && <Typography color="error">Please select a product</Typography>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-label-cell">PART NO.</div>
                            <div className="form-value-cell">
                                <TextField fullWidth variant="outlined" size="small" value={selectedProductDetails?.partNo || ''} disabled />
                            </div>
                        </div>

                        {/* Additional Description */}
                        <div className="form-row">
                            <div className="form-label-cell">ADDITIONAL DESCRIPTION:</div>
                            <div className="form-value-cell">
                                <TextField fullWidth variant="outlined" size="small" value={additionalInfo} onChange={(e) => setAdditionalInfo(e.target.value)} />
                            </div>
                        </div>

                        {/* Continue with all fields from screenshot */}
                        <div className="form-row">
                            <div className="form-label-cell">HSN CODE</div>
                            <div className="form-value-cell">
                                <TextField fullWidth variant="outlined" size="small" value={hsnCode} onChange={(e) => setHsnCode(e.target.value)} />
                            </div>
                        </div>

                        {/* Add all missing fields in order */}
                        <div className="form-row">
                            <div className="form-label-cell">UNIT</div>
                            <div className="form-value-cell">
                                <Select
                                    value={selectedUnit}
                                    options={[
                                        { label: UNITS.KG_BAG, value: UNITS.KG_BAG },
                                        { label: UNITS.KG_CAN, value: UNITS.KG_CAN },
                                        { label: UNITS.LITERS_CAN, value: UNITS.LITERS_CAN },
                                        { label: UNITS.PC_PER_PACKET, value: UNITS.PC_PER_PACKET },
                                        { label: UNITS.UNIT, value: UNITS.UNIT },
                                    ]}
                                    placeholder="Select Unit"
                                    onChange={(e) => setSelectedUnit(e)}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            borderColor: productError ? 'red' : base.borderColor,
                                        }),
                                        container: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-label-cell">PACKING STANDARD</div>
                            <div className="form-value-cell">
                                <TextField fullWidth variant="outlined" size="small" onChange={(e) => setPackingStandard(e.target.value)} value={packingStandard} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-label-cell">ULTERNET UNIT</div>
                            <div className="form-value-cell">
                                <TextField fullWidth variant="outlined" size="small" onChange={(e) => setAlternateUnit(e.target.value)} value={alternateUnit} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-label-cell">MACHINE</div>
                            <div className="form-value-cell">
                                <Select
                                    value={selectedMachineMemo}
                                    options={machineArr.map((el) => ({ label: el.name, value: el._id }))}
                                    placeholder="Select Machine"
                                    onChange={(e) => setSelectedMachine(e.value)} // Ensure we only store the ID
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                        }),
                                        container: (base) => ({
                                            ...base,
                                            width: '100%',
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        {mould &&
                            mould.map((el) => (
                                <div className="form-row">
                                    <div className="form-label-cell">MOULD</div>
                                    <div className="form-value-cell">
                                        <Typography>{el.name}</Typography>
                                    </div>
                                </div>
                            ))}

                        <div className="form-row">
                            <div className="form-label-cell">MASTER BATCH</div>
                            <div className="form-value-cell">
                                <TextField fullWidth variant="outlined" size="small" onChange={(e) => setMasterBatch(e.target.value)} value={masterBatch} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-label-cell">GROSS WEIGHT</div>
                            <div className="form-value-cell">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setGrossWeight(e.target.value)}
                                    value={grossWeight}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">gm</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-label-cell">PART WEIGHT</div>
                            <div className="form-value-cell">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setPartWeight(e.target.value)}
                                    value={partWeight}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">gm</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-label-cell">RUNNER WEIGHT</div>
                            <div className="form-value-cell">
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setRunnerWeight(e.target.value)}
                                    value={runnerWeight}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">gm</InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>

                        {selectedStage?.value === CATEGORY.PLATING && (
                            <div className="form-row">
                                <div className="form-label-cell">PLATING CATEGORY</div>
                                <div className="form-value-cell">
                                    <Select
                                        isMulti={true}
                                        value={platingArr.filter((el) => selectedPlatingCategory.includes(el._id)).map((el) => ({ label: el.name, value: el._id }))}
                                        options={platingArr.map((el) => ({ label: el.name, value: el._id }))}
                                        placeholder="Select Plating Area"
                                        onChange={(selectedOptions) => setSelectedPlatingCategory(selectedOptions.map((option) => option.value))}
                                        styles={{
                                            control: (base) => ({
                                                ...base,
                                            }),
                                            container: (base) => ({
                                                ...base,
                                                width: '100%',
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {selectedStage?.value === CATEGORY.PLATING && (
                            <div className="form-row">
                                <div className="form-label-cell">PLATING AREA</div>
                                <div className="form-value-cell">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setPlatingArea(e.target.value)}
                                        value={platingArea}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">sq cm</InputAdornment>,
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        {selectedStage?.value !== CATEGORY.PLATING && (
                            <>
                                {/* Raw Material Selection Section */}
                                <div className="table-container">
                                    <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Raw Materials</span>
                                        <Button variant="contained" size="small" onClick={() => setSelectedRawMaterials([...selectedRawMaterials, { material: null, quantity: '' }])}>
                                            + Add More
                                        </Button>
                                    </div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Raw Material</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Unit</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedRawMaterials.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ width: '50%' }}>
                                                        <Select
                                                            value={item.material}
                                                            options={rawMaterialArr}
                                                            placeholder="Select Raw Material"
                                                            onChange={(selected) => {
                                                                const newMaterials = [...selectedRawMaterials]
                                                                newMaterials[index].material = selected
                                                                setSelectedRawMaterials(newMaterials)
                                                            }}
                                                            styles={{
                                                                container: (base) => ({
                                                                    ...base,
                                                                    width: '100%',
                                                                }),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            fullWidth
                                                            value={item.quantity}
                                                            onChange={(e) => {
                                                                const newMaterials = [...selectedRawMaterials]
                                                                newMaterials[index].quantity = e.target.value
                                                                setSelectedRawMaterials(newMaterials)
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={item.unit}
                                                            options={unitArr}
                                                            placeholder="Select Unit"
                                                            onChange={(selected) => {
                                                                const newMaterials = [...selectedRawMaterials]
                                                                newMaterials[index].unit = selected
                                                                setSelectedRawMaterials(newMaterials)
                                                            }}
                                                            styles={{
                                                                container: (base) => ({
                                                                    ...base,
                                                                    width: '100%',
                                                                }),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="error"
                                                            size="small"
                                                            onClick={() => {
                                                                const newMaterials = selectedRawMaterials.filter((_, i) => i !== index)
                                                                setSelectedRawMaterials(newMaterials)
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>

                                {/* Sub Category Raw Material Selection Section */}
                                <div className="table-container">
                                    <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <span>Sub Category Raw Materials</span>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                                const newSubCatMaterials = [
                                                    ...subCategoryRawMaterials,
                                                    {
                                                        subCategory: null,
                                                        material: null,
                                                        quantity: '',
                                                        subCategoryUnit: null,
                                                    },
                                                ]
                                                setSubCategoryRawMaterials(newSubCatMaterials)
                                            }}
                                        >
                                            + Add More
                                        </Button>
                                    </div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sub Category</TableCell>
                                                <TableCell>Raw Material</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Unit</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {subCategoryRawMaterials.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{ width: '30%' }}>
                                                        <Select
                                                            value={item.subCategory}
                                                            options={subCategoryArr.map((el) => ({
                                                                label: el.name,
                                                                value: el._id,
                                                            }))}
                                                            placeholder="Select Sub Category"
                                                            onChange={(selected) => {
                                                                const newMaterials = [...subCategoryRawMaterials]
                                                                newMaterials[index].subCategory = selected
                                                                setSubCategoryRawMaterials(newMaterials)
                                                            }}
                                                            styles={{
                                                                container: (base) => ({
                                                                    ...base,
                                                                    width: '100%',
                                                                }),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: '30%' }}>
                                                        <Select
                                                            value={item.material}
                                                            options={item.subCategory ? getFilteredRawMaterials(item.subCategory.value) : []}
                                                            placeholder="Select Raw Material"
                                                            isDisabled={!item.subCategory}
                                                            onChange={(selected) => {
                                                                const newMaterials = [...subCategoryRawMaterials]
                                                                newMaterials[index].material = selected
                                                                setSubCategoryRawMaterials(newMaterials)
                                                            }}
                                                            styles={{
                                                                container: (base) => ({
                                                                    ...base,
                                                                    width: '100%',
                                                                }),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            type="number"
                                                            size="small"
                                                            fullWidth
                                                            value={item.quantity}
                                                            onChange={(e) => {
                                                                const newMaterials = [...subCategoryRawMaterials]
                                                                newMaterials[index].quantity = e.target.value
                                                                setSubCategoryRawMaterials(newMaterials)
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={item.unit}
                                                            options={unitArr}
                                                            placeholder="Select Unit"
                                                            onChange={(selected) => {
                                                                const newMaterials = [...subCategoryRawMaterials]
                                                                newMaterials[index].unit = selected
                                                                setSubCategoryRawMaterials(newMaterials)
                                                            }}
                                                            styles={{
                                                                container: (base) => ({
                                                                    ...base,
                                                                    width: '100%',
                                                                }),
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="error"
                                                            size="small"
                                                            onClick={() => {
                                                                const newMaterials = subCategoryRawMaterials.filter((_, i) => i !== index)
                                                                setSubCategoryRawMaterials(newMaterials)
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </>
                        )}

                        {selectedStage?.value === CATEGORY.PLATING && selectedProduct && (
                            <Box>
                                {mouldBOM && (
                                    <Box sx={{ maxWidth: 1350, mx: 'auto', my: 4 }}>
                                        {/* Header */}
                                        <Box
                                            sx={{
                                                bgcolor: '#ffcdb2',
                                                p: 1,
                                                textAlign: 'center',
                                                mb: 1,
                                                borderRadius: '4px 4px 0 0',
                                            }}
                                        >
                                            <Typography variant="h5" component="h1" fontWeight="bold">
                                                BILL OF MATERIAL
                                            </Typography>
                                        </Box>
                                        <TableContainer component={Paper} elevation={3} sx={{ mb: 4, width: '100%' }}>
                                            <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                                                <TableBody>
                                                    {/* Part Name and Number */}
                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                PART NAME:
                                                            </Typography>
                                                            <Typography>{mouldBOM?.productName || 'YTA CHROME BSM ASSEMBLED SET'}</Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                PART NO.
                                                            </Typography>
                                                            <Typography>{mouldBOM?.partNo || 'BPA003288'}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={4} sx={{ p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                ADDITIONAL DESCRIPTION:
                                                            </Typography>
                                                            <Typography>{mouldBOM?.additionalInfo || '-'}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={2} sx={{ p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                CATEGORY:
                                                            </Typography>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography sx={{ flexGrow: 1 }}>{mouldBOM?.category || 'FINISHED GOOD'}</Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>

                                                    {/* HSN Code and GST Rate */}
                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                HSN CODE:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.hsnCode || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                UNIT:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.unit || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                PACKING STANDARD:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.packingStandard || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                ULTERNET UNIT:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.ulternetUnit || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                MACHINE:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.machineName || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    {/* <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                            PART CAVAITY:
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                            {mouldBOM?.cavity || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow> */}

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                MASTER BATCH:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.masterBatch || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                GROSS WEIGHT:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.grossWeight || ''} {mouldBOM?.weightUnit || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                PART WEIGHT:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.partWeight || ''} {mouldBOM?.weightUnit || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                RUNNER WEIGHT:
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {mouldBOM?.runnerWeight || ''} {mouldBOM?.weightUnit || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    {mouldBOM?.mouldingRawMaterialArr && mouldBOM.mouldingRawMaterialArr.length > 0 && (
                                                        <>
                                                            <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                <TableCell colSpan={6} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                        RAW MATERIALS
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            {mouldBOM.mouldingRawMaterialArr.map((item, index) => (
                                                                <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            {item.rawMaterialName}
                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {item?.rawMaterialQty || ''} {item?.unit || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </>
                                                    )}

                                                    {Array.isArray(mouldBOM?.mouldingRawMaterialSubCategoryArr) && mouldBOM.mouldingRawMaterialSubCategoryArr.length > 0 && (
                                                        <>
                                                            {/* <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                            <TableCell colSpan={6} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                    SUBCATEGORY RAW MATERIALS
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow> */}
                                                            <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                <TableCell colSpan={1} sx={{ p: 1 }}>
                                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}></Typography>
                                                                </TableCell>

                                                                <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                        NAME & SIZE
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                        QTY
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>

                                                            {Array.isArray(mouldBOM?.mouldingRawMaterialSubCategoryArr) &&
                                                                mouldBOM.mouldingRawMaterialSubCategoryArr.map((item, index) => (
                                                                    <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                        <TableCell colSpan={1} sx={{ width: '50%', p: 1, bgcolor: '#f5f5f5' }}>
                                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                {item.subCategoryName}
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                {item.rawMaterialName}
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                {item.subCategoryQty} {item.unit}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                            </Box>
                        )}

                        {/* Add remaining fields */}
                        {/* 
                    <div className="form-row">
                        <div className="form-label-cell">PVC CAP</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="5"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">STICKER</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="LABEL CARTON CLOSURE"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">PAD PRINTING INK</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="-"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">USER GUIDE</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="INST. MANUAL YTA BSM"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">OTHER BOP PARTS</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="-"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">MINIMUM</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="-"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">MAXIMUM</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="-"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-label-cell">SOURCE</div>
                        <div className="form-value-cell">
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value="INHOUSE"
                            />
                        </div>
                    </div> */}

                        <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleFinalSubmit}>
                            Save Bill of Material
                        </Button>
                    </FormContainer>
                </SimpleCard>
            </Container>
        </>
    )
}
