import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/machine`

export const addMachine = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllMachine = () => {
    return axios.get(`${url}`)
}
export const getMachineById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deleteMachine = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateMachine = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const exportMachineData=()=>{
    window.open(`${url}/exportMachineData`)
}

export const getMachineWithMouldData=()=>{
    return axios.get(`${url}/getMachineWithMouldData`)
}


export const MachineReport = () => {
    return axios.get(`${url}/getMachineReport`)
}


export const getAllMould = async (machineId, productId) => {
    return axios.get(`${url}/getAllMould`, { params: { machineId, productId } })
}