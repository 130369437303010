import React, { useEffect, useState } from 'react'
import { getAllShiftDataForQcCompleted } from 'app/services/shiftData.service'
import ContentTable from 'app/components/Table/ContentTable'
import { toastError } from 'app/utils/toastUtils'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function ViewPendingProduct() {
    const navigate = useNavigate()
    const [shiftData, setShiftData] = useState([])

    useEffect(() => {
        getAllShiftData()
    }, [])

    const getAllShiftData = async () => {
        try {
            const res = await getAllShiftDataForQcCompleted()
            if (res) {
                setShiftData(res.data.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    console.log(shiftData, 'shiftData')
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Pending Products
                </Typography>

                <Button onClick={() => navigate('/PendingProducts/add')} variant="contained" color="primary">
                    Stock Entry in SFG/FG Store
                </Button>
            </Box>

            <ContentTable
                data={shiftData}
                columns={[
                    {
                        name: 'Product Name',
                        selector: (row) => row?.productName,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Quantity',
                        selector: (row) => row?.quantity,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Stage',
                        selector: (row) => row?.stage,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Shift',
                        selector: (row) => row?.shift,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Bin',
                        selector: (row) => row?.binName,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Bin Stage',
                        selector: (row) => row?.qcBinStage,
                        searchable: true,
                        sortable: true,
                    },
                ]}
            />
        </>
    )
}
