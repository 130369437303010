import { Button, Box, Card, CardContent, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { getAllBinByBinType, getAllBin, getAllBinDataByBinType, deleteBin } from 'app/services/Bin.service'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))

export default function ViewBin() {
    const [bins, setBins] = useState([])
    const [binTypes, setBinTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        try {
            const [binTypesRes, binsRes] = await Promise.all([getAllBinByBinType(), getAllBin()])
            setBinTypes(binTypesRes.data?.data || [])
            setBins(binsRes.data?.data || [])
        } catch (error) {
            toastError(error)
        }
        setLoading(false)
    }

    const getBinByBinType = async (id) => {
        setLoading(true)
        try {
            let { data: res } = await getAllBinDataByBinType(id)
            setBins(res?.data || [])
        } catch (error) {
            toastError(error)
        }
        setLoading(false)
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Bin?')) {
                await deleteBin(id)
                toastSuccess('Bin deleted successfully!')
                setBins((prevBins) => prevBins.filter((bin) => bin._id !== id))
            }
        } catch (error) {
            toastError(error)
        }
    }

    const columns = [
        { name: 'S.No', selector: (row, index) => index + 1, sortable: true, width: '80px', center: true },
        { name: 'Name', selector: (row) => row.name, sortable: true, searchable: true, center: true },
        { name: 'Bin Type', selector: (row) => row.binType, sortable: true, center: true },
        { name: 'Bin Size', selector: (row) => row.binSize, sortable: true, center: true },
        { name: 'Created At', selector: (row) => moment(row.createdAt).format('DD-MM-YYYY HH:mm'), sortable: true, center: true },
        {
            name: 'Actions',
            cell: (row) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button variant="outlined" color="primary" onClick={() => navigate(`/DownloadBinBarcode/${row.barcodeId}`)} size="small">
                        Bar Code
                    </Button>
                    <Button variant="outlined" color="info" onClick={() => navigate(`/product-bin-mapping/${row._id}`)} size="small">
                        Mapping
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => navigate(`/edit-bin/${row._id}`)} size="small">
                        Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(row._id)} size="small">
                        Delete
                    </Button>
                </div>
            ),
            center: true,
        },
    ]

    return (
        <Container>
            <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
                <Button variant="contained" color="primary" onClick={() => navigate('/add-bin')}>
                    Create Bin
                </Button>
                <Button variant="contained" color="primary" onClick={() => navigate('/bulk-generate-bins')}>
                    Bulk Generate Bins
                </Button>
            </Box>

            <SimpleCard title="View Bins">
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {binTypes
                        .sort((a, b) => b.count - a.count)
                        .map((bin) => (
                            <Card
                                key={bin._id}
                                sx={{ border: '1px solid #e0e0e0', width: '330px', height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', cursor: 'pointer' }}
                                onClick={() => getBinByBinType(bin._id)}
                            >
                                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6" className="font-medium mb-2 text-gray-800">
                                            {bin.binTypeName}
                                        </Typography>
                                        <Typography variant="body2" className="text-gray-500 mb-3 text-sm">
                                            {bin.binTypeDescription}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#3f51b5', fontSize: '28px' }}>
                                            {bin.count}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                </Box>
            </SimpleCard>

            <SimpleCard title="Bin List">
                <ContentTable columns={columns} data={bins} />
            </SimpleCard>
        </Container>
    )
}
