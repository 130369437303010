import { Grid, CircularProgress, Box, Button } from '@mui/material'
import { styled } from '@mui/system'
import { getQrDataToDownload } from 'app/services/mrn.service'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

export default function DownloadQr() {
    const [qrArr, setQrArr] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(50)
    const [pageIndex, setPageIndex] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const printRef = useRef()
    
    useEffect(() => {
        getAllMrns()
    }, [pageIndex, pageSize])
    
    const handleNextPage = () => {
        setPageIndex(prev => prev + 1)
    }
    
    const handlePrevPage = () => {
        setPageIndex(prev => Math.max(0, prev - 1))
    }

    const getAllMrns = async () => {
        try {
            setLoading(true)
            let { data: res } = await getQrDataToDownload(params.id, { pageSize, pageIndex })
            if (res) {
                console.log(res)
                setQrArr(res.data || [])
                setTotalItems(res.total || 0)
            }
        } catch (error) {
            toastError(error)
        } finally {
            setLoading(false)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const PaginationBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        '@media print': {
            display: 'none',
        },
    }))

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {qrArr.length > 0 && (
                        <PaginationBox>
                            <Button 
                                variant="contained" 
                                disabled={pageIndex === 0} 
                                onClick={handlePrevPage}
                            >
                                Previous Page
                            </Button>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                Page {pageIndex + 1} | Showing {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, totalItems)} of {totalItems}
                            </Box>
                            <Button 
                                variant="contained" 
                                disabled={(pageIndex + 1) * pageSize >= totalItems} 
                                onClick={handleNextPage}
                            >
                                Next Page
                            </Button>
                        </PaginationBox>
                    )}
                    
                    {qrArr.length > 0 &&
                    qrArr.map((el, i) => {
                        return (
                            <div key={i} className="center_print" style={{ pageBreakAfter: 'always', height: "100%", display: "grid", placeItems: "center" }}>
                                <div>
                                    <Barcode height={50} value={el?.barcodeId} key={i} width={3} />
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <span style={{ textAlign: 'center', color: "#000" }}>PART NO : {el?.partNo}</span>
                                            <br />
                                            <span style={{ textAlign: 'center', color: "#000" }}>LOT SIZE : {el?.displayLotQty}</span>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{ textAlign: 'center', color: "#000" }}>PART DESC : {el.partDescription}</span>
                                            <br />
                                            <span style={{ textAlign: 'center', color: "#000" }}>MRN NO : {el?.mrnNo}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{ textAlign: 'center', color: "#000" }}>PART NAME : {el.partName}</span>
                                            <br />
                                            <span style={{ textAlign: 'center', color: "#000" }}>SUPPLIER : {el?.supplierName?.slice(0, 20)}...</span>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <span style={{ textAlign: 'center', color: "#000" }}><b>IQC : OK</b></span>
                                            <br />
                                            <span style={{ textAlign: 'center', color: "#000" }}>DATE : {moment(el?.createdAt).format("DD-MM-YYYY")}</span>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        )
                    })}
                </>
            )}
        </>
    )
}
