import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/defect-sub-category`

export const addDefectSubCategory = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllDefectSubCategory = () => {
    return axios.get(`${url}`)
}

export const deleteDefectSubCategory = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getDefectSubCategoryById = async (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const updateDefectSubCategory = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}