import { Button, Box } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteMachineData, getAllMachineData } from 'app/redux/actions/Machines/machine.action'
import { deleteMouldData, getAllMouldData } from 'app/redux/actions/Mould/mould.action'
import { deleteMachine, getAllMachine } from 'app/services/machine.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'
import { getAllProduct } from 'app/services/product.service'
import Select from 'react-select'
import { getMouldByProduct } from 'app/services/mould.service'
import { get } from 'lodash'

export default function ViewMould() {
    let counter = 1
    const mouldArr = useSelector((state) => state.mould.mouldArr)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [productArr, setProductArr] = useState([])
    const [selectedProduct, setSelectedProduct] = useState()
    const [moulds, setMoulds] = useState([])
    // console.log(mouldArr, "asd")
    // const [machineArr, setMachineArr] = useState([])

    useEffect(() => {
        getAllMoulding()
    }, [])

    const getAllMoulding = async () => {
        try {
            dispatch(getAllMouldData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleUpdate = async (id) => {
        try {
            navigate(`/admin/updatemould/${id}`)
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this machine?')) {
                dispatch(deleteMouldData(id))
            }
        } catch (error) {
            toastError(error)
        }
        getAllMoulding()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllProduct()
                console.log('Fetched Products:', res.data.data)
                if (res.data.success) {
                    setProductArr(res.data.data || [])
                }
            } catch (error) {
                console.error('Error fetching products:', error)
                toastError(error)
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (selectedProduct) {
            console.log(selectedProduct, 'selectedProduct')
            getMouldByProduct(selectedProduct)
                .then((res) => setMoulds(res.data.data))
                .catch((error) => toastError(error))
        } else {
            setMoulds(mouldArr)
        }
    }, [selectedProduct, mouldArr])

    console.log(moulds, 'moulds')

    return (
        <Container>
            <SimpleCard title={'View Moulds'}>
                <Box sx={{ mb: '24px' }}>
                    <Select
                        options={productArr?.map((el) => ({ label: el.name, value: el._id }))}
                        isMulti
                        isSearchable
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Product"
                        name="product"
                        value={selectedProduct}
                        onChange={(selectedOption) => {
                            const productIds = selectedOption ? selectedOption.map((item) => item.value) : []
                            setSelectedProduct(selectedOption)

                            if (productIds.length > 0) {
                                getMouldByProduct(productIds)
                                    .then((res) => setMoulds(res.data.data))
                                    .catch((error) => toastError(error))
                            } else {
                                setMoulds(mouldArr)
                            }
                        }}
                        styles={{
                            control: (base) => ({ ...base, height: 40, minHeight: 40, width: '100%', minWidth: 0 }),
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                            menuList: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                    />
                </Box>
                <ContentTable
                    data={moulds}
                    columns={[
                        {
                            name: '#',
                            selector: (index) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                            sortable: true,
                        },
                        {
                            name: 'Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Mould Number',
                            selector: (row) => (row?.mouldNumber ? row?.mouldNumber : 'NA'),
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Cycle Time',
                            selector: (row) => row?.cycleTime,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Products',
                            selector: (row) => row?.productArr.map((el, i, arr) => `${el?.productObj?.name}${i < arr.length - 1 ? ',' : ''}\n`),
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Update',
                            selector: (row) => (
                                <Button variant="contained" color="primary" onClick={() => handleUpdate(row._id)}>
                                    Update
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
