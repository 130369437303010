import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllBin } from 'app/services/Bin.service'
import { getAllITProducts } from 'app/services/ITProducts.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'

export default function ViewITProducts() {
    let counter = 1
    const [blockArr, setBlockArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getAllITProducts()
            if (res) {
                setBlockArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this It Product?')) {

            }
        } catch (error) {
            toastError(error)
        }
        getUsers()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>

            <SimpleCard title={'View IT Products'}>
                <ContentTable
                    data={blockArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            sortable: true,
                            button: true,
                        },
                        {
                            name: 'Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Description',
                            selector: (row) => row?.description,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Issued To',
                            selector: (row) => row?.user?.name,
                            searchable: true,
                            sortable: true,
                        },
                        
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
