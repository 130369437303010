import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/BinTypes`

export const addBinType = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllBinType = () => {
    return axios.get(`${url}`)
}

export const deleteBinType = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}


export const getBinTypeById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}


export const updateBinType = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
