import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'

import { getAllReworkPartMonitoring } from 'app/services/reworkPartMonitoring.service'
import toast from 'react-hot-toast'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function ReworkPartMonitoringChecksheet() {
    const [reworkMonitor, setReworkMonitor] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllReworkPartMonitoring()
                setReworkMonitor(res.data.data)
            } catch (error) {
                toast.error('not found')
            }
        }

        fetchData()
    }, [])

    console.log(reworkMonitor, 'revoke')
    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={4} align="center">
                                    <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                </StyledTableCell>
                                <StyledTableCell colSpan={12} rowSpan={1} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                    DEPT.: MOULDING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                <StyledTableCell colSpan={2}>MLD-F-007</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={12} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                    TITLE : REWORK PART MONITORING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>REV NO./ DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2}>00/10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>EFFT DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2}>10.10.2021</StyledTableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Sn. No.</StyledTableCell>
                                <StyledTableCell align="center">Date</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    Part Name/No.
                                </StyledTableCell>
                                <StyledTableCell align="center">Qty for R/W</StyledTableCell>
                                <StyledTableCell colSpan={3} align="center">
                                    Problem
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} align="center">
                                    Rework Method
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    OK Qty <br /> After R/W
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    NG Qty
                                    <br /> After R/W
                                </StyledTableCell>
                                <StyledTableCell align="center">Batch No.</StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    R/W Operator
                                    <br /> Name
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Checked
                                    <br /> By QA <br /> (Name/
                                    <br /> Sign)
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Sign QA <br /> Head
                                </StyledTableCell>
                                <StyledTableCell align="center">Remarks</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Array.isArray(reworkMonitor) && reworkMonitor.map((item, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                    <StyledTableCell align="center">{item.dateCompareString}</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        {item.productName} - {item.productNo}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{item.qtyForRW}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        {item.problem}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        {item.reworkMethod}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{item.okQty}</StyledTableCell>
                                    <StyledTableCell align="center">{item.ngQty}</StyledTableCell>
                                    <StyledTableCell align="center">{item.batchNo}</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        {item.rwOperatorName}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{item.CheckedByQAName}</StyledTableCell>
                                    <StyledTableCell align="center">{item.signQAHead}</StyledTableCell>
                                    <StyledTableCell align="center">{item.remarks}</StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}
