import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getStockLocation } from 'app/services/rawMaterial.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'

export default function ViewStockLocation() {
    let counter = 1
    const [stockLocationArr, setStockLocationArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('partNo') || '';
    useEffect(() => {
        getUsers()
    }, [data])

    const getUsers = async () => {
        try {
            let query=`partNo=${data}`;
            let { data: res } = await getStockLocation(query);
            if (res) {
                setStockLocationArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Stock'}>
                

                <ContentTable
                    data={stockLocationArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Part No',
                            selector: (row) => row?.partNo,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Quantity',
                            selector: (row) => row?.qty,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Units',
                            selector: (row) => row?.units,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Quantity Per',
                            selector: (row) => row?.quantityPer,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Store',
                            selector: (row) => row?.storeName,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Block',
                            selector: (row) => row?.blockName,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Rack',
                            selector: (row) => row?.rackName,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Bin',    
                            selector: (row) => row?.binName,
                            sortable: true,
                            searchable: true,
                        }
                        
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
