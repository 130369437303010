import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Card, CardContent, Avatar, Chip, Fade, Tooltip, Divider } from '@mui/material'
import { styled } from '@mui/system'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'

import {  getAllMachine } from 'app/services/hopperCleaningChecksheet.service'

// Styled components for enhanced visuals
const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        background: 'linear-gradient(45deg,rgb(202, 227, 253) 30%,rgb(196, 218, 236) 90%)',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1.5),
        fontSize: '0.95rem',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
    }),
    ...(subheader && {
        background: 'linear-gradient(45deg, #FFF8E1 30%, #FFF7E0 90%)',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 0.4)',
    padding: theme.spacing(1.5),
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(245, 250, 254, 0.9)',
    },
    '&:hover': {
        backgroundColor: 'rgba(232, 244, 253, 0.8)',
        transition: 'background-color 0.2s ease',
    },
    transition: 'background-color 0.2s ease',
}))

const ActionButton = styled(Button)(({ theme }) => ({
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
    textTransform: 'none',
    fontWeight: 'bold',
    padding: theme.spacing(0.8, 2),
}))

const PageHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2, 0),
}))


export default function HopperCleaningChecksheet() {
    const navigate = useNavigate()
    const id = new URLSearchParams(useLocation().search).get('id') || ''
    const [processParameter, setProcessParameter] = useState({})

    const [machines, setMachines] = useState([])

   

    const getMachineData = async () => {
        try {
            const res = await getAllMachine()

            if (res) {
                setMachines(res?.data.data)
            }
        } catch (error) {
            if (error?.response?.data?.message) alert(error?.response?.data?.message)   
            else alert(error?.message)
        }
    }

    useEffect(() => {
        getMachineData()
    }, [])

    console.log(machines, 'machines')

    const handleDelete = (id) => {
        const updatedMachines = machines.filter((machine) => machine.id !== id)
        setMachines(updatedMachines)
    }

    const handleCustomFormat = (id) => {
        navigate(`/checksheet/hopperCleaningChecksheet/customFormat?id=${id}`)
    }

    return (
        <Fade in={true} timeout={500}>
            <Box sx={{ p: 3, backgroundColor: '#f7f9fc', minHeight: '100vh' }}>
                <Card elevation={3} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <CardContent sx={{ p: 3 }}>
                        <PageHeader>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar sx={{ backgroundColor: '#1976d2', mr: 2 }}>
                                    <PrecisionManufacturingIcon />
                                </Avatar>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1a237e' }}>
                                    Hopper Cleaning Checksheet
                                </Typography>
                            </Box>
                        </PageHeader>

                        <Divider sx={{ mb: 3 }} />

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer
                                    component={Paper}
                                    elevation={2}
                                    sx={{
                                        borderRadius: '12px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Table size="medium" aria-label="process parameter table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell header>S.No</StyledTableCell>
                                                <StyledTableCell header>Machine Name</StyledTableCell>
                                                <StyledTableCell header>Checksheet Type</StyledTableCell>
                                                <StyledTableCell header>Actions</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(machines) &&  machines?.map((machine, index) => (
                                                <StyledTableRow key={machine.id}>
                                                    <StyledTableCell align="center">
                                                        <Typography variant="body1" fontWeight="bold">
                                                            {index + 1}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <PrecisionManufacturingIcon sx={{ color: '#1976d2', mr: 1 }} fontSize="small" />
                                                            <Typography variant="body1" fontWeight="medium">
                                                                {machine.machineName}
                                                            </Typography>
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Typography variant="body1" fontWeight="medium">
                                                        HOPPER_CLEANING_CHECK_SHEET
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                                                            <Tooltip title="Custom Format">
                                                                <ActionButton variant="contained" color="primary" size="small" onClick={() => handleCustomFormat(machine._id.machineId)}>
                                                                    Custom Format
                                                                </ActionButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Machine">
                                                                <ActionButton variant="contained" color="error" size="small" onClick={() => handleDelete(machine.id)}>
                                                                    Delete
                                                                </ActionButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Fade>
    )
}
