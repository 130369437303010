import { Roles } from 'Roles'

export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
        role: [Roles.admin, Roles.SUB_ADMIN, Roles.MOULDING, Roles.manager],
    },
    {
        label: 'Master Data',
        type: 'label',
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Users',
        icon: 'people',
        role: [Roles.admin],
        children: [
            {
                name: 'Add User',
                path: '/admin/adduser',
                role: [Roles.admin],
            },
            {
                name: 'View Users',
                path: '/admin/viewuser',
                role: [Roles.admin],
            },
        ],
    },
    {
        name: 'Moulding',
        icon: 'precision_manufacturing',
        role: [Roles.admin, Roles.manager],
        children: [
            {
                name: 'Category',
                children: [
                    {
                        name: 'Add Category',
                        path: '/admin/addcategory',
                        role: [Roles.admin],
                    },
                    {
                        name: 'View Categories',
                        path: '/admin/viewcategory',
                        role: [Roles.admin, Roles.manager],
                    },
                ],
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Mould',
                children: [
                    {
                        name: 'Add Mould',
                        path: '/admin/addmould',
                        role: [Roles.admin, Roles.manager],
                    },
                    {
                        name: 'View Moulds',
                        path: '/admin/viewmould',
                        role: [Roles.admin, Roles.manager],
                    },
                ],
                role: [Roles.admin, Roles.manager],
            },
        ],
    },
    {
        name: 'Products',
        icon: 'inventory_2',
        role: [Roles.admin, Roles.manager],
        children: [
            {
                name: 'Add Product',
                path: '/admin/addproduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Products',
                path: '/admin/viewproduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Stocks',
                path: '/admin/viewstocks',
                role: [Roles.admin, Roles.manager],
            },
        ],
    },
    {
        name: 'Store Management',
        icon: 'store',
        role: [Roles.admin, Roles.manager, Roles.STORE],
        children: [
            {
                name: 'Store',
                children: [
                    {
                        name: 'Add Store',
                        path: '/add-store',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'View Stores',
                        path: '/view-store',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                ],
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Bin Management',
                children: [
                    {
                        name: 'Bins',
                        path: '/view-bin',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Bin Types',
                        path: '/ViewBinType',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Bin Sizes',
                        path: '/ViewBinSize',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'SFG/FG Store Entry',
                        path: '/BinStockEntry',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    
                ],
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Pending Products',
                iconText: 'V',
                path: '/PendingProducts',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Issue Products',
                iconText: 'V',
                path: '/issueProducts',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Raw Material Request',
                iconText: 'A',
                path: '/raw-material-request',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Issue Raw Material',
                iconText: 'V',
                path: '/RawMaterialIssue',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Raw Material Entry',
                iconText: 'V',
                path: '/stock-entry',
                role: [Roles.admin, Roles.manager],
            },
        ],
    },
    {
        name: 'BOM',
        icon: 'description',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/ProductRecipe/Add',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Add Assembly BOM',
                iconText: 'A',
                path: '/admin/ProductRecipe/AddAssembly',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Add Chemical Consumption',
                iconText: 'A',
                path: '/admin/ProductRecipe/AddChemical',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Plating Category',
                iconText: 'A',
                path: '/admin/ProductRecipe/viewPlatingCategory',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/admin/ProductRecipe/View',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'BOM OverView',
                iconText: 'V',
                path: '/admin/ProductRecipe/Overview',
                role: [Roles.admin, Roles.manager],
            }
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Machine',
        icon: 'precision_manufacturing',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addmachine',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/admin/viewmachine',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Process Parameter',
        icon: 'precision_manufacturing',
        children: [
            {
                name: 'Moulding',
                children: [
                    {
                        name: 'View Process Parameter',
                        path: '/admin/viewParameter',
                        role: [Roles.admin, Roles.manager],
                    },
                ],
                role: [Roles.admin, Roles.manager],
            }
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Staff Allocation',
        icon: 'groups',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addStaffAllocation',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewStaffAllocation',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Raw Materials',
        icon: 'inventory',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addrawmaterial',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Sub Category',
                iconText: 'A',
                path: '/admin/viewrawmaterialsubcategory',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/admin/viewrawmaterial',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'IT Products',
        icon: 'computer',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/AddItProducts',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/ViewItProducts',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Sub Category',
                iconText: 'V',
                path: '/view-it-product-sub-category',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Issue',
                iconText: 'V',
                path: '/IssueItProduct',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Client',
        icon: 'business',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addclient',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewclient',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Requirements',
                iconText: 'A',
                path: '/admin/addRequirement',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Mould',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addmould',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewmould',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Assembly Product',
        icon: 'settings_applications',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addassemblyProduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewassemblyProduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Assembly Requirements',
                iconText: 'M',
                path: '/admin/AddAssemblyRequirement',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Scheduling',
        icon: 'schedule',
        children: [
            {
                name: 'Manage',
                iconText: 'A',
                path: '/admin/addSchedule',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Planning',
                iconText: 'A',
                path: '/admin/ViewProductionPlanning',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Defects',
        icon: 'bug_report',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addDefects',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'A',
                path: '/admin/ViewDefects',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Defect Sub Category',
                iconText: 'A',
                path: '/admin/ViewDefectSubCategory',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'MRN',
        icon: 'receipt',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/material-receipt-note',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'View',
                iconText: 'A',
                path: '/view-mrn',
                role: [Roles.admin, Roles.manager, Roles.STORE, Roles.STORE_EXE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE, Roles.STORE_EXE],
    },
    {
        name: 'MRN QC',
        icon: 'fact_check',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/mrn-qc',
                role: [Roles.admin, Roles.manager, Roles.STORE, Roles.QC_PDI, Roles.STORE_EXE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE, Roles.QC_PDI, Roles.STORE_EXE],
    },
    {
        name: 'Supplier',
        icon: 'local_shipping',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/AddSupplier',
                role: [Roles.admin, Roles.STORE],
            },
            {
                name: 'View',
                iconText: 'A',
                path: '/ViewSupplier',
                role: [Roles.admin, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.STORE],
    },
    {
        name: 'Waste Management',
        icon: 'delete_sweep',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/view-waste',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    // {
    //     name: 'Stock Entry',
    //     icon: 'add_box',
    //     children: [
    //         {
    //             name: 'View',
    //             iconText: 'A',
    //             path: '/stock-entry',
    //             role: [Roles.admin, Roles.manager, Roles.STORE],
    //         },
    //     ],
    //     role: [Roles.admin, Roles.manager, Roles.STORE],
    // },
    {
        name: 'Stock Location',
        icon: 'place',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/stock-location',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    // {
    //     name: 'Stock Check',
    //     icon: 'checklist',
    //     children: [
    //         {
    //             name: 'View',
    //             iconText: 'A',
    //             path: '/stock-check',
    //             role: [Roles.admin, Roles.manager, Roles.STORE],
    //         },
    //     ],
    //     role: [Roles.admin, Roles.manager, Roles.STORE],
    // },
    {
        name: 'Jigs',
        icon: 'build',
        children: [
            {
                name: 'Add Jig Master',
                iconText: 'A',
                path: '/AddJigMaster',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Manage Master',
                iconText: 'B',
                path: '/view-jigs',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'All Jigs',
                iconText: 'B',
                path: '/ViewAllJigs',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Production',
        icon: 'factory',
        children: [
            {
                name: 'Moulding',
                iconText: 'B',
                path: '/ViewMouldingProduction',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Actual VS Planned',
                iconText: 'B',
                path: '/actualplanneddata',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'CheckSheet',
                iconText: 'B',
                children: [
                    {
                        name: 'Process Parameter',
                        iconText: 'P',
                        path: '/checksheet/processParameterChecksheet',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Mould Setup',
                        iconText: 'M',
                        path: '/checksheet/mouldSetupChecksheet',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Mould Setup And Unloading',
                        iconText: 'U',
                        path: '/checksheet/mouldSetupAndUnloadingChecksheet',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Hopper Cleaning',
                        iconText: 'C',
                        path: '/checksheet/hopperCleaningChecksheet',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Pre Heating Record',
                        iconText: 'P',
                        path: '/checksheet/preHeatingRecord',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Rework Part Monitoring',
                        iconText: 'R',
                        path: '/checksheet/reworkPartMonitoring',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Scrap Data Note',
                        iconText: 'S',
                        path: '/checksheet/scrapDataNote',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'First & Last Part Approval',
                        iconText: 'F',
                        path: '/checksheet/firstLastPartApproval',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Hourly Inspection Report',
                        iconText: 'H',
                        path: '/checksheet/hourlyInspectionReport',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Abnormal Condition Inspection Report',
                        iconText: 'A',
                        path: '/checksheet/abnormalConditionInspectionReport',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: '4M Change Inspection Report',
                        iconText: 'M',
                        path: '/checksheet/4mmChangeInspectionReport',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: '4M Change & Abnormal Information Report',
                        iconText: 'A',
                        path: '/checksheet/4mmChangeAbnormalChecksheet',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                    {
                        name: 'Setup Approval Report',
                        iconText: 'S',
                        path: '/checksheet/setupApprovalReport',
                        role: [Roles.admin, Roles.manager, Roles.STORE],
                    },
                ],
                role: [Roles.admin, Roles.manager, Roles.STORE],
            }
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Question',
        icon: 'description',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/view-question',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Report',
        icon: 'assessment',
        children: [
            {
                name: 'View Reports',
                iconText: 'A',
                path: '/admin/reports',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Daily Stocks Report',
                iconText: 'A',
                path: '/admin/dailystockreport',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Current Stocks Report',
                iconText: 'A',
                path: '/admin/currentstockreport',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Stocks Report',
                iconText: 'A',
                path: '/admin/stocks-report',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'All Items Report',
                iconText: 'A',
                path: '/admin/all-items-report',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
]
