import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/Supplier`

export const addSupplier = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllSupplier = () => {
    return axios.get(`${url}`)
}

export const deleteSupplier = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getSupplierById = (id) => {
    return axios.get(`${url}/getById/${id}`);
}

export const updateSupplierById = (obj, id) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}


export const getSupplierByRawMaterial = async (params) => {
    return await axios.get(`${url}/getDataByRawMaterial`, {
        params: { rawMaterialArr: JSON.stringify(params.rawMaterialArr) }, 
    });
};

export const getSupplierBySupplierType = async (params) => {
    return await axios.get(`${url}/getDataBySupplierType`, {
        params: { supplierType: params }, 
    });
}