import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/bom`

export const addBOM = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllBOM = () => {
    return axios.get(`${url}/`)
}

export const deleteBOM = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getBOMById = async (id) => {
    return axios.get(`${url}/getById/${id}`)
}


export const updateBOM = async (id,obj) => {
    return axios.patch(`${url}/updateById/${id}`,obj)
}

export const getMouldingBOMByProductId = async (id) => {
    return axios.get(`${url}/getMouldingBOMByProductId/${id}`)
}


export const getBOMByProductId = async (id) => {
    return axios.get(`${url}/getBOMByProductId/${id}`)
}