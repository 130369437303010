import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteDefectById, getAllDefects } from 'app/services/Defect.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

import ContentTable from 'app/components/Table/ContentTable'
export default function ViewDefects() {
    let counter = 1
    const [displayArr, setDisplayArr] = useState([])

    const [mainArr, setMainArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [optionArr, setOptionArr] = useState([
        { label: 'All', value: 'All' },
        { label: 'Moulding', value: 'Moulding' },

        { label: 'Plating', value: 'Plating' },
        { label: 'Assembly', value: 'Assembly' },
        { label: 'IQC', value: 'IQC' },
        { label: 'MRN', value: 'MRN' },
    ])
    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getAllDefects()
            if (res) {
                setDisplayArr(res.data)
                setMainArr(res.data)
            }
        } catch (error) {
            toastError(error)
            // alert(error)
        }
    }

    const handleFilter = (e) => {
        let tempArr = [...mainArr]
        tempArr = tempArr.filter((el) => el.defectCategory == e.value)
        setDisplayArr([...tempArr])
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Defect?')) {
                const res = await deleteDefectById(id)
                if (res.data?.message) alert(res.data.message)
                // toastSuccess(res.data.message)
            }
        } catch (error) {
            toastError(error)
            // alert(error)
        }
        getUsers()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    //handle the update functionality - redirection to the update page
    const handleUpdate = (id) => {
        console.log("Navigating to update defect with ID:", id);
        navigate(`/admin/addDefects?id=${id}`); //redirects to addDefects page with the defect id
    }

    return (
        <Container>
            <SimpleCard title={'Manage Defects'}>
                <Select placeholder="Filter" options={optionArr} onChange={(e) => handleFilter(e)} />
               
                <ContentTable
                                    data={displayArr}
                                    columns={[
                                        {
                                            name: '#',
                                            selector: (row) => counter++,
                                            sortable: true,
                                            searchable: true,
                                        },
                                        {
                                            name: 'Name',
                                            selector: (row) => row?.name,
                                            searchable: true,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Description',
                                            selector: (row) => row?.description,
                                            searchable: true,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Defect Category',
                                            selector: (row) => row?.defectCategory,
                                            searchable: true,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Update',
                                            selector: (row) => (
                                                <Button variant="contained" color="primary" onClick={() => handleUpdate(row._id)}>
                                                    Update
                                                </Button>
                                            ),
                                            ignoreRowClick: true,
                                            allowOverflow: true,
                                            button: true,
                                        },
                                        {
                                            name: 'Delete',
                                            selector: (row) => (
                                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                                    Delete
                                                </Button>
                                            ),
                                            ignoreRowClick: true,
                                            allowOverflow: true,
                                            button: true,
                                        },
                                    ]}
                                />
            </SimpleCard>
        </Container>
    )
}
