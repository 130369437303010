import { Button, Grid, TextField, Typography, Box } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import { getMouldById, UpdateMouldById } from 'app/services/mould.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function UpdateMould() {
    const params = useParams()
    const dispatch = useDispatch()
    const productArr = useSelector((state) => state.product.productArr)

    const [localProductsArr, setLocalProductsArr] = useState([])
    const [name, setName] = useState('')
    const [mouldNumber, setMouldNumber] = useState('')
    const [cycleTime, setCycleTime] = useState(0)

    // Added fields to match AddMould.js
    const [numberOfCavities, setNumberOfCavities] = useState(0)
    const [cavityProductArr, setCavityProductArr] = useState([])
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const [mouldWeight, setMouldWeight] = useState(0)
    const [mouldHeight, setMouldHeight] = useState(0)
    const [mouldWidth, setMouldWidth] = useState(0)
    const [mouldLength, setMouldLength] = useState(0)

    const handleSubmit = async () => {
        try {
            // Keep existing products for backward compatibility
            let productArray = localProductsArr
                .filter((el) => el.checked == true)
                .map((el) => {
                    return { productId: el._id }
                })

            // Add cavity products if they exist - new format matching AddMould.js
            if (cavityProductArr && cavityProductArr.length > 0) {
                let tempArr = [...new Set(cavityProductArr.map((el) => el.productId).filter(Boolean))]
                productArray = tempArr.map((el) => ({ productId: el }))
            }

            let obj = {
                name,
                mouldNumber: mouldNumber,
                productArr: productArray,
                cycleTime: parseInt(cycleTime),
                numberOfCavities: parseInt(numberOfCavities),
                cavityProductArr: cavityProductArr,
                mouldWeight: mouldWeight,
                mouldHeight: mouldHeight,
                mouldWidth: mouldWidth,
                mouldLength: mouldLength,
            }
            let { data: res } = await UpdateMouldById(params.id, obj)
            console.log(res)
            if (res) {
                alert(res.message)
                getAllProducts()
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    useEffect(() => {
        // Format products for both checkbox and Select component
        if (productArr && productArr.length > 0) {
            // Format for checkboxes (existing logic)
            const tempProductsArr = productArr.map((el) => ({
                ...el,
                checked: false,
            }))
            setLocalProductsArr(tempProductsArr)

            // Format for React Select (new logic)
            const selectProducts = productArr
                .filter((el) => !el.assembly)
                .map((el) => ({
                    ...el,
                    label: el?.name,
                    value: el?._id,
                }))

            // Get existing mould data with formatted products
            getMouldingDataById(tempProductsArr, selectProducts)
        }
    }, [productArr])

    useEffect(() => {
        getAllProducts()
    }, [])

    // Update cavity products when number of cavities changes
    useEffect(() => {
        // Skip if numberOfCavities is not a valid number
        if (!parseInt(numberOfCavities)) return

        // Generate new cavity array based on current number
        const newCavityCount = parseInt(numberOfCavities)

        // Create a new array with the updated number of cavities
        const newCavityArr = [...Array(newCavityCount).keys()].map((_, index) => {
            // Try to preserve existing product selections if cavity exists
            if (index < cavityProductArr.length) {
                return {
                    ...cavityProductArr[index],
                    cavity: index + 1,
                }
            }
            // Add new empty cavities for any additional ones
            return { productId: '', cavity: index + 1 }
        })

        // Only update if this isn't the initial load or there's a change in number of cavities
        if (!isInitialLoad || newCavityCount !== cavityProductArr.length) {
            setCavityProductArr(newCavityArr)
        }

        // After first load, clear the initial load flag
        if (isInitialLoad && newCavityCount > 0) {
            setIsInitialLoad(false)
        }
    }, [numberOfCavities, isInitialLoad])

    const getAllProducts = async () => {
        try {
            dispatch(getAllProductData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleProductSelect = (index) => {
        let tempArr = localProductsArr.map((el, i) => {
            if (i == index) {
                el.checked = !el.checked
            }
            return el
        })
        console.log(tempArr)
        setLocalProductsArr([...tempArr])
    }

    const handleProductSelection = (index, obj) => {
        let tempArr = [...cavityProductArr]
        tempArr[index].productId = obj?.value || obj?._id
        setCavityProductArr([...tempArr])
    }

    const getMouldingDataById = async (productLocalarr, selectProducts) => {
        try {
            console.log(params.id)
            let { data: res } = await getMouldById(params.id)
            console.log(res)
            if (res.data) {
                console.log(productLocalarr, 'localProductsArr')
                setName(res?.data?.name)
                setMouldNumber(res?.data?.mouldNumber)
                setCycleTime(res?.data?.cycleTime)
                setMouldHeight(res?.data?.mouldHeight)
                setMouldWidth(res?.data?.mouldWidth)
                setMouldLength(res?.data?.mouldLength)
                setMouldWeight(res?.data?.mouldWeight)

                // Set numberOfCavities first - this will trigger the useEffect
                const cavityCount = res?.data?.numberOfCavities || 0
                setNumberOfCavities(cavityCount)

                // Set up cavity-product associations if they exist
                if (res?.data?.cavityProductArr && res?.data?.cavityProductArr.length > 0) {
                    setCavityProductArr(res.data.cavityProductArr)
                } else if (cavityCount > 0) {
                    // Create blank cavity-product array if only numberOfCavities exists
                    let tempArr = [...Array(cavityCount).keys()].map((el, i) => ({ productId: '', cavity: i + 1 }))

                    // Try to populate with productArr if available
                    if (res?.data?.productArr && res?.data?.productArr.length > 0) {
                        for (let i = 0; i < Math.min(tempArr.length, res.data.productArr.length); i++) {
                            tempArr[i].productId = res.data.productArr[i].productId
                        }
                    }
                    setCavityProductArr(tempArr)
                }

                // Maintain backward compatibility for checkbox selection
                if (productArr && productLocalarr.length > 0) {
                    let tempArr = productLocalarr.map((el) => {
                        if (res?.data?.productArr?.some((ele) => ele?.productId == el?._id)) {
                            el.checked = true
                        } else {
                            el.checked = false
                        }
                        return el
                    })
                    setLocalProductsArr(tempArr)
                }
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="Update Mould">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <div style={{ marginTop: 15 }}>Mould name</div>
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />
                    <TextField type="text" name="Mould Number" onChange={(e) => setMouldNumber(e.target.value)} value={mouldNumber || ''} label="Mould Number" sx={{ width: '100%', mt: 2 }} />

                    <div style={{ marginTop: 15 }}>Cycle Time</div>
                    <TextField type="number" name="Cycle Time" onChange={(e) => setCycleTime(e.target.value)} value={cycleTime || ''} label="Cycle Time" sx={{ width: '100%', mt: 2 }} />

                    <div style={{ marginTop: 15 }}>Number Of Cavities</div>
                    <TextField type="number" name="Number Of Cavities" onChange={(e) => setNumberOfCavities(e.target.value)} value={numberOfCavities || ''} label="Number Of Cavities" sx={{ width: '100%', mt: 2 }} />

                    {cavityProductArr &&
                        cavityProductArr.length > 0 &&
                        cavityProductArr.map((el, i) => {
                            return (
                                <Grid key={i} container gap={2} sx={{ margin: '15px 0px' }}>
                                    <Grid
                                        item
                                        key={i}
                                        sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cavity {el?.cavity}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Select
                                            options={
                                                productArr &&
                                                productArr
                                                    .filter((prod) => !prod.assembly)
                                                    .map((prod) => ({
                                                        ...prod,
                                                        label: prod?.name,
                                                        value: prod?._id,
                                                    }))
                                            }
                                            onChange={(e) => handleProductSelection(i, e)}
                                            value={productArr && el.productId ? productArr.filter((p) => p._id === el.productId).map((p) => ({ label: p.name, value: p._id }))[0] : null}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })}

                    <div style={{ marginTop: 15 }}>Mould Weight</div>
                    <TextField type="number" name="Mould Weight" onChange={(e) => setMouldWeight(e.target.value)} value={mouldWeight || ''} label="Mould Weight" sx={{ width: '100%', mt: 2 }} />

                    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%" sx={{ marginTop: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Mould Length
                                </Typography>
                                <TextField type="number" name="Mould Length" fullWidth value={mouldLength || ''} onChange={(e) => setMouldLength(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Mould Width
                                </Typography>
                                <TextField type="number" name="Mould Width" fullWidth value={mouldWidth || ''} onChange={(e) => setMouldWidth(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Mould Height
                                </Typography>
                                <TextField type="number" name="Mould Height" fullWidth value={mouldHeight || ''} onChange={(e) => setMouldHeight(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Update Mould
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
