import React from 'react'
import { Box, Typography, Paper, Grid, Button } from '@mui/material'
import { useLocation } from 'react-router-dom'

export default function BomView() {
    const location = useLocation()
    const mouldBOM = location.state?.bom?.[0] ?? {};
    const platingBOM = location.state?.bom?.[1] ?? {};

    console.log(mouldBOM, "mouldBOM")
    console.log(platingBOM, "platingBOM")


    return (
        <Box sx={{ p: 4, maxWidth: 1200, margin: '0 auto' }}>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={() => window.history.back()}
                sx={{ mb: 3 }}
            >
                Go Back
            </Button>

            <Typography variant="h4" component="h1" gutterBottom>
                Bill of Materials Details
            </Typography>

            <Box sx={{ p: 3, bgcolor: '#fff', borderRadius: 2, boxShadow: 3 }}>
                {mouldBOM &&
                    mouldBOM?.map((mouldBOM) => (
                        <Paper key={mouldBOM._id} elevation={3} sx={{ p: 3, mb: 3 }}>
                            <Typography variant="h5" component="h2" gutterBottom>
                              {mouldBOM?.category} BOM : {mouldBOM?.productName}
                            </Typography>

                            {/* Product Details */}
                            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Product Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Product Name:</strong> {mouldBOM?.productName}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Category:</strong> {mouldBOM?.category}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Packing Standard:</strong> {mouldBOM?.packingStandard}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Additional Info:</strong> {mouldBOM?.additionalInfo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Machine Details */}
                            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Machine Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Machine Name:</strong> {mouldBOM?.machineName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Cavity:</strong> {mouldBOM?.cavity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Weight Details */}
                            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Weight Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Gross Weight:</strong> {mouldBOM?.grossWeight} kg
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Part Weight:</strong> {mouldBOM?.partWeight} kg
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Runner Weight:</strong> {mouldBOM?.runnerWeight} kg
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Master Batch:</strong> {mouldBOM?.masterBatch}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Raw Materials */}
                            {mouldBOM?.mouldingRawMaterialArr && mouldBOM.mouldingRawMaterialArr.length > 0 && (
                                <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Raw Materials
                                    </Typography>
                                    {mouldBOM.mouldingRawMaterialArr.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                bgcolor: '#f3f4f6',
                                                p: 2,
                                                borderRadius: 1,
                                                mb: 2,
                                            }}
                                        >
                                            <Typography variant="body2">
                                                <strong>Material Name:</strong> {item.rawMaterialName}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Quantity:</strong> {item.rawMaterialQty} {item.unit}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Paper>
                            )}

                            {/* Subcategory Raw Materials */}
                            {mouldBOM?.mouldingRawMaterialSubCategoryArr && mouldBOM.mouldingRawMaterialSubCategoryArr.length > 0 && (
                                <Paper elevation={1} sx={{ p: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Subcategory Raw Materials
                                    </Typography>
                                    {mouldBOM.mouldingRawMaterialSubCategoryArr.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                bgcolor: '#f3f4f6',
                                                p: 2,
                                                borderRadius: 1,
                                                mb: 2,
                                            }}
                                        >
                                            <Typography variant="body2">
                                                <strong>Subcategory Name:</strong> {item.subCategoryName}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Material Name:</strong> {item.rawMaterialName}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Quantity:</strong> {item.subCategoryQty} {item.unit}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Paper>
                            )}
                        </Paper>
                    ))}
            </Box>

            <Box sx={{ p: 3, bgcolor: '#fff', borderRadius: 2, boxShadow: 3, mt: 4 }}>
                {platingBOM &&
                    platingBOM?.map((platingBOM) => (
                        <Paper key={platingBOM?._id} elevation={3} sx={{ p: 3, mb: 3 }}>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {platingBOM?.category} BOM : {platingBOM?.productName}
                            </Typography>

                            {/* Similar structure to Moulding BOM, replace mouldBOM with platingBOM */}
                            {/* Repeat the same sections as above */}

                            {/* Product Details */}
                            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Product Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Product Name:</strong> {platingBOM?.productName}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Category:</strong> {platingBOM?.category}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Packing Standard:</strong> {platingBOM?.packingStandard}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Additional Info:</strong> {platingBOM?.additionalInfo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Machine Details */}
                            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Machine Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Machine Name:</strong> {platingBOM?.machineName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Cavity:</strong> {platingBOM?.cavity}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Weight Details */}
                            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Weight Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Gross Weight:</strong> {platingBOM?.grossWeight} kg
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Part Weight:</strong> {platingBOM?.partWeight} kg
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2">
                                            <strong>Runner Weight:</strong> {platingBOM?.runnerWeight} kg
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>Master Batch:</strong> {platingBOM?.masterBatch}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>

                            {/* Raw Materials */}
                            {platingBOM?.mouldingRawMaterialArr && platingBOM.mouldingRawMaterialArr.length > 0 && (
                                <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Raw Materials
                                    </Typography>
                                    {platingBOM.mouldingRawMaterialArr.map((item, index) => (
                                        <Box key={index} sx={{ bgcolor: '#f3f4f6', p: 2, borderRadius: 1, mb: 2 }}>
                                            <Typography variant="body2">
                                                <strong>Material Name:</strong> {item.rawMaterialName}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Quantity:</strong> {item.rawMaterialQty} {item.unit}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Paper>
                            )}

                            {/* Subcategory Raw Materials */}
                            {platingBOM?.mouldingRawMaterialSubCategoryArr && platingBOM.mouldingRawMaterialSubCategoryArr.length > 0 && (
                                <Paper elevation={1} sx={{ p: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Subcategory Raw Materials
                                    </Typography>
                                    {platingBOM.mouldingRawMaterialSubCategoryArr.map((item, index) => (
                                        <Box key={index} sx={{ bgcolor: '#f3f4f6', p: 2, borderRadius: 1, mb: 2 }}>
                                            <Typography variant="body2">
                                                <strong>Subcategory Name:</strong> {item.subCategoryName}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Material Name:</strong> {item.rawMaterialName}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Quantity:</strong> {item.subCategoryQty} {item.unit}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Paper>
                            )}
                        </Paper>
                    ))}
            </Box>
        </Box>
    )
}
