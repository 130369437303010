import Loadable from 'app/components/Loadable/Loadable'
import { lazy } from 'react'
import AddAssemblyProduct from './AssemblyProduct/AddAssemblyProduct'
import ViewAssemblyProduct from './AssemblyProduct/ViewAssemblyProduct'
import AddDefects from './Defects/AddDefects'
import ViewDefects from './Defects/ViewDefects'
import UpdateMachine from './Machine/UpdateMachine'
import UpdateProduct from './Product/UpdateProduct'
import AddRawMaterialStock from './RawMaterial/AddRawMaterialStock'
import UpdateRawMaterial from './RawMaterial/UpdateRawMaterial'
import ViewReportById from './Report/ViewReportById'
import ViewReports from './Report/ViewReports'
import AddRequirementSheet from './RequirementSheet/AddRequirementSheet'
import AddSchedulingSheet from './SchedulingSheet/AddSchedulingSheet'
import AddStaffAllocation from './StaffAllocation/AddStaffAllocation'
import UpdateStaffAllocation from './StaffAllocation/UpdateStaffAllocation'
import ViewStaffAllocation from './StaffAllocation/ViewStaffAllocation'
import ViewStocks from './Stocks/ViewStocks'
import UpdateCategory from './category/UpdateCategory'
import UpdateClient from './client/UpdateClient'
import AddMould from './moulding/AddMould'
import UpdateMould from './moulding/UpdateMould'
import ViewMould from './moulding/ViewMould'
import UpdateUser from './user/UpdateUser'
//import ViewParameter from './viewprocessparameter/viewParameter'

import EmptyPage from '../EmptyPage/EmptyPage'
import Analytics from '../dashboard/Analytics'
import AddAssemblyRequirement from './AssemblyRequirements/AddAssemblyRequirements'
import AddBin from './Bin/AddBin'
import ViewBin from './Bin/ViewBin'
import AddBlock from './Block/AddBlock'
import ViewBlock from './Block/ViewBlock'
import AddITProducts from './ITProducts/AddITProducts'
import IssueItProduct from './ITProducts/IssueItProduct'
import ViewITProducts from './ITProducts/ViewITProducts'
import AddJigsMaster from './Jigs/AddJigMaster'
import AddJigs from './Jigs/AddJigs'
import ViewAllJigs from './Jigs/ViewAllJigs'
import ViewJigs from './Jigs/ViewJigs'
import MaterialReceiptNote from './MaterialReceiptNote/MaterialReceiptNoteAdd'
import MrnDownload from './MaterialReceiptNote/MrnDownload'
import ViewMrn from './MaterialReceiptNote/viewMrn'
import MrnQcDetails from './MrnQc/MrnQcDetails'
import ViewMrnQc from './MrnQc/ViewMrnQc'
import ViewPendingStockGroup from './PendingStockEntry/ViewPendingStockGroup'
import AddRack from './Rack/AddRack'
import ViewRack from './Rack/ViewRack'
import RawMaterialIssue from './RawMaterial/RawMaterialIssue'
import ViewRawmaterialSubCategory from './RawMaterial/ViewRawMaterialSubCategory'
import AddRawMaterialSubCategory from './RawMaterial/AddRawMaterialSubCategory'

import CurrentStockReport from './Report/CurrentStockReport'
import DailyStockReport from './Report/DailyStockReport'
import ViewStockLocation from './StockLocation/ViewStockLocation'
import StockCheck from './Stocks/StockCheck'
import AddStore from './Store/AddStore'
import StockIssueRequest from './Store/StockIssueRequest'
import ViewStore from './Store/ViewStore'
import AddSupplier from './Supplier/AddSupplier'
import ViewSupplier from './Supplier/ViewSupplier'
import ViewWaste from './WasteManagement/ViewWaste'
import MachineQr from './Machine/MachineQr'
import ProductionPlanning from './ProductionPlanning/ProductionPlanning'
import AddProductRecipe from './ProductRecipe/AddProductRecipe'
import ViewProductRecipe from './ProductRecipe/ViewProductRecipe'
import ViewProductionPlanning from './ProductionPlanning/ViewProductionPlanning'
import StocksReport from './Report/StocksReport'
import ItemWiseReport from './Report/ItemWiseReport'
import AllItemsReport from './Report/AllItemsReport'
import MonthlyReport from './Report/MonthlyReport'
import { element } from 'prop-types'
import AllMonthlyReport from './Report/AllMonthlyReport'
import AllRawItemsReport from './Report/AllRawItemsReport'
import BinStockEntry from './Bin/BinStockEntry'
import BinProductAssign from './Bin/BinProductAssign'
import ViewMouldingProduction from './Production/ViewMouldingProduction'
import ViewSpecificMouldingProduction from './Production/ViewSpecificMouldingProduction'
import Actualplanneddata from './Production/Actualplanneddata'
import ViewBinType from './Bin/ViewBinType'
import ViewBinSize from './Bin/ViewBinSize'
import BulkGenerateBin from './Bin/BulkGenerateBin'
import ViewBinProductMapping from './Bin/ViewBinProductMapping'
import BomView from './ProductRecipe/bomView'
import Overview from './ProductRecipe/Overview'
import AddChemical from './ProductRecipe/AddChemicalConsumption'
import Production_Planning from './ProductionPlanning/production_Planning'
import CustomFormatPlanning from './ProductionPlanning/customFormatPlanning'
import ProcessParameterChecksheet from './Production/checksheet/processParameterChecksheet/processParameterChecksheet'
import CustomFormatProcessParameterChecksheet from './Production/checksheet/processParameterChecksheet/CustomformatProcessPlanning.js'
import MouldSetupChecksheet from './Production/checksheet/mouldSetup/mouldSetupChecksheet'
import ViewQuestion from './Question/veiwQuestion'
import AddQuestion from './Question/addQuestion'
import MouldSetupAndUnloadingSheet from './Production/checksheet/mouldSetupAndUnloading/mouldSetupAndUnloadingSheet'
import HopperCleaningChecksheet from './Production/checksheet/hopperCleaningChecksheet/hopperCleaningChecksheet'
import PreHeatingRecord from './Production/checksheet/preHeatingRecord/preHeatingRecord'
import CustomFormatHopperCleaningChecksheet from './Production/checksheet/hopperCleaningChecksheet/CustomformatHopperCleaningChecksheet'
import AddAssemblyBOM from './ProductRecipe/addAssemblyBOM'
import ReworkPartMonitoring from "./Production/checksheet/reworkPartMonitoringChecksheet/reworkPartMonitoringChecksheet"
import ScrapDataNote from './Production/checksheet/scrapDataNote/scrapDataNote'
import FirstLastPartApproval from './Production/checksheet/firstLastPartApproval/firstLastPartApproval'
import HourlyInspectionChecksheet from './Production/checksheet/hourlyInspectionChecksheet/hourlyInspectionChecksheet'
import AbnormalConditionInspectionReport from './Production/checksheet/abnormalCondition/abnormalConditionInspectionReport'
import FourMmChangeInspectionReport from './Production/checksheet/4mmChangeInspectionReport/4mmChangeInspectionReport'
import FourMChangeAbnormalChecksheet from './Production/checksheet/4MChangeAbnormalChecksheet/fourMChangeAbnormalChecksheet'
import CustomFormatForChangeAbnormalSheet from './Production/checksheet/4MChangeAbnormalChecksheet/customFormatForChangeAbnormalSheet'
import SetupApprovalReport from './Production/checksheet/setupApprovalReport/setupApprovalReport'
import PendingProducts from './PendingProduct/viewPendingProduct'
import AddPendingProductsToSFG from './PendingProduct/addPendingStockForSfg_Fg'
import IssueProduct from './PendingProduct/issueProduct'

import DefectSubCategory from "./Defects/viewDefectSubCategory"
import AddDefectSubCategory from "./Defects/addDefectSubCategory"
import ViewPlatingCategory from "./ProductRecipe/viewPlatingCategory"
import AddPlatingCategory from "./ProductRecipe/addPlatingCategory"
import ViewItSubCategory from "./ITProducts/viewItSubCategory"
import AddItSubCategory from "./ITProducts/addItSubCategory"
import ViewSFGProduct from './PendingProduct/viewSFGProduct'
import ViewFGProduct from './PendingProduct/viewFGProduct'

const AddUser = Loadable(lazy(() => import('./user/AddUser')))
const ViewUser = Loadable(lazy(() => import('./user/ViewUser')))
const AddCategory = Loadable(lazy(() => import('./category/AddCategory')))
const ViewCategory = Loadable(lazy(() => import('./category/ViewCategory')))

const AddRawMaterial = Loadable(lazy(() => import('./RawMaterial/AddRawMaterial')))
const ViewRawMaterial = Loadable(lazy(() => import('./RawMaterial/ViewRawMaterial')))

const AddProduct = Loadable(lazy(() => import('./Product/AddProduct')))
const ViewProduct = Loadable(lazy(() => import('./Product/ViewProduct')))

const AddMachine = Loadable(lazy(() => import('./Machine/AddMachine')))
const ViewMachine = Loadable(lazy(() => import('./Machine/ViewMachine')))

const ViewParameter = Loadable(lazy(() => import('./viewprocessparameter/viewParameter')))
const AddParameter = Loadable(lazy(() => import('./viewprocessparameter/addParameter')))

const CustomFormat = Loadable(lazy(() => import('./viewprocessparameter/customFormat')))

const AddClient = Loadable(lazy(() => import('./client/AddClient')))
const ViewClient = Loadable(lazy(() => import('./client/ViewClient')))

const adminRoutes = [
    {
        path: '/dashboard/default',
        element: <Analytics />,
    },
    {
        path: '/admin/adduser',
        element: <AddUser />,
    },
    {
        path: '/admin/viewuser',
        element: <ViewUser />,
    },
    {
        path: '/admin/updateUser/:id',
        element: <UpdateUser />,
    },
    {
        path: '/admin/addcategory',
        element: <AddCategory />,
    },
    {
        path: '/admin/viewcategory',
        element: <ViewCategory />,
    },
    {
        path: '/admin/updatecategory/:id',
        element: <UpdateCategory />,
    },
    {
        path: '/admin/addrawmaterial',
        element: <AddRawMaterial />,
    },
    {
        path: '/admin/viewrawmaterial',
        element: <ViewRawMaterial />,
    },
    {
        path: '/admin/AddrawmaterialStock/:id',
        element: <AddRawMaterialStock />,
    },
    {
        path: '/admin/updaterawmaterial/:id',
        element: <UpdateRawMaterial />,
    },
    {
        path: '/admin/addproduct',
        element: <AddProduct />,
    },
    {
        path: '/admin/viewproduct',
        element: <ViewProduct />,
    },
    {
        path: '/admin/updateproduct/:id',
        element: <UpdateProduct />,
    },
    {
        path: '/admin/addmachine',
        element: <AddMachine />,
    },
    {
        path: '/admin/viewmachine',
        element: <ViewMachine />,
    },
    {
        path: '/admin/viewParameter',
        element: <ViewParameter />,
    },
    {
        path: '/admin/addParameter',
        element: <AddParameter />,
    },
    {
        path: '/admin/customFormat',
        element: <CustomFormat />,
    },
    {
        path: '/admin/updatemachine/:id',
        element: <UpdateMachine />,
    },
    {
        path: '/admin/addclient',
        element: <AddClient />,
    },
    {
        path: '/admin/viewclient',
        element: <ViewClient />,
    },
    {
        path: '/admin/updateclient/:id',
        element: <UpdateClient />,
    },
    {
        path: '/admin/viewmould',
        element: <ViewMould />,
    },
    {
        path: '/admin/addmould',
        element: <AddMould />,
    },
    {
        path: '/admin/updatemould/:id',
        element: <UpdateMould />,
    },
    {
        path: '/admin/addSchedule',
        element: <AddSchedulingSheet />,
    },

    {
        path: '/admin/addStaffAllocation',
        element: <AddStaffAllocation />,
    },
    {
        path: '/admin/viewStaffAllocation',
        element: <ViewStaffAllocation />,
    },
    {
        path: '/admin/updateStaffAllocation/:id',
        element: <UpdateStaffAllocation />,
    },
    {
        path: '/admin/addRequirement',
        element: <AddRequirementSheet />,
    },
    {
        path: '/admin/AddAssemblyRequirement',
        element: <AddAssemblyRequirement />,
    },
    {
        path: '/admin/reports',
        element: <ViewReports />,
    },
    {
        path: '/admin/items-monthly-report',
        element: <MonthlyReport />,
    },
    {
        path: '/admin/all-monthly-report',
        element: <AllMonthlyReport />,
    },
    {
        path: '/admin/all-rawitems-report',
        element: <AllRawItemsReport />,
    },
    {
        path: '/admin/dailystockreport',
        element: <DailyStockReport />,
    },
    {
        path: '/admin/currentstockreport',
        element: <CurrentStockReport />,
    },
    {
        path: '/admin/stocks-report',
        element: <StocksReport />,
    },
    {
        path: '/admin/item-report',
        element: <ItemWiseReport />,
    },
    {
        path: '/admin/all-items-report',
        element: <AllItemsReport />,
    },
    {
        path: '/ViewBinType',
        element: <ViewBinType />,
    },
    {
        path: '/ViewBinSize',
        element: <ViewBinSize />,
    },
    {
        path: '/bulk-generate-bins',
        element: <BulkGenerateBin />,
    },

    {
        path: '/product-bin-mapping/:id',
        element: <ViewBinProductMapping />,
    },

    {
        path: '/admin/viewrawmaterialsubcategory',
        element: <ViewRawmaterialSubCategory />,
    },
    {
        path: '/actualplanneddata',
        element: <Actualplanneddata />,
    },
    {
        path: '/admin/ProductRecipe/AddAssembly',
        element: <AddAssemblyBOM />,
    },
    {
        path: '/admin/addrawmaterialsubcategory',
        element: <AddRawMaterialSubCategory />,
    },
    {
        path: '/admin/machineqr',
        element: <MachineQr />,
    },

    {
        path: '/admin/planning',
        element: <ProductionPlanning />,
    },
    {
        path: '/admin/productionPlanning',
        element: <Production_Planning />
    },
    {
        path: '/admin/customFormatPlanning',
        element: <CustomFormatPlanning />
    },
    {
        path: '/admin/ProductRecipe/Add',
        element: <AddProductRecipe />,
    },
    {
        path: '/admin/ProductRecipe/View',
        element: <ViewProductRecipe />,
    },
    {
        path: '/admin/ProductRecipe/bomView',
        element: <BomView />,
    },
    {
        path: '/admin/ProductRecipe/Overview',
        element: <Overview />,
    },
    {
        path: '/admin/ProductRecipe/AddChemical',
        element: <AddChemical />,
    },
    {
        path: '/admin/ViewProductionPlanning',
        element: <ViewProductionPlanning />,
    },

    {
        path: '/admin/viewReport/:id',
        element: <ViewReportById />,
    },
    {
        path: '/admin/viewassemblyProduct',
        element: <ViewAssemblyProduct />,
    },
    {
        path: '/admin/addassemblyProduct',
        element: <AddAssemblyProduct />,
    },
    {
        path: '/admin/viewstocks',
        element: <ViewStocks />,
    },
    {
        path: '/admin/ViewDefects',
        element: <ViewDefects />,
    },
    {
        path: '/admin/addDefects',
        element: <AddDefects />,
    },
    {
        path: '/material-receipt-note',
        element: <MaterialReceiptNote />,
    },
    {
        path: '/view-mrn',
        element: <ViewMrn />,
    },
    {
        path: '/download-mrn/:id',
        element: <MrnDownload />,
    },
    {
        path: '/mrn-qc/:id',
        element: <MrnQcDetails />,
    },
    {
        path: '/mrn-qc',
        element: <ViewMrnQc />,
    },
    {
        path: '/add-store',
        element: <AddStore />,
    },
    {
        path: '/view-store',
        element: <ViewStore />,
    },
    {
        path: '/view-block',
        element: <ViewBlock />,
    },
    {
        path: '/add-block',
        element: <AddBlock />,
    },
    {
        path: '/view-it-product-sub-category',
        element: <ViewItSubCategory />
    },
    {
        path: '/add-it-product-sub-category',
        element: <AddItSubCategory />
    },
    {
        path: '/view-rack',
        element: <ViewRack />,
    },
    {
        path: '/add-rack',
        element: <AddRack />,
    },
    {
        path: '/view-bin',
        element: <ViewBin />,
    },
    {
        path: '/BinStockEntry',
        element: <BinStockEntry />,
    },
    {
        path: '/BinProductAssign',
        element: <BinProductAssign />,
    },
    {
        path: '/ViewMouldingProduction',
        element: <ViewMouldingProduction />,
    },
    {
        path: '/ViewSpecificMouldingProduction/:id',
        element: <ViewSpecificMouldingProduction />,
    },
    {
        path: '/add-bin',
        element: <AddBin />,
    },
    {
        path: '/admin/ViewDefectSubCategory',
        element: <DefectSubCategory />,
    },
    {
        path: '/admin/addDefectSubCategory',
        element: <AddDefectSubCategory />,
    },
    {
        path: '/stock-entry',
        element: <ViewPendingStockGroup />,
    },
    {
        path: '/raw-material-request',
        element: <StockIssueRequest />,
    },
    {
        path: '/stock-location',
        element: <ViewStockLocation />,
    },

    {
        path: '/stock-check',
        element: <StockCheck />,
    },

    {
        path: '/add-jigs',
        element: <AddJigs />,
    },

    {
        path: '/view-jigs',
        element: <ViewJigs />,
    },
    {
        path: '/view-waste',
        element: <ViewWaste />,
    },
    {
        path: '/AddJigMaster',
        element: <AddJigsMaster />,
    },
    {
        path: '/ViewAllJigs',
        element: <ViewAllJigs />,
    },
    {
        path: '/EmptyPage',
        element: <EmptyPage />,
    },

    {
        path: '/AddSupplier',
        element: <AddSupplier />,
    },

    {
        path: '/ViewSupplier',
        element: <ViewSupplier />,
    },

    {
        path: '/AddItProducts',
        element: <AddITProducts />,
    },

    {
        path: '/ViewItProducts',
        element: <ViewITProducts />,
    },
    {
        path: '/IssueItProduct',
        element: <IssueItProduct />,
    },
    {
        path: '/RawMaterialIssue',
        element: <RawMaterialIssue />,
    },
    {
        path: '/checksheet/processParameterChecksheet',
        element: <ProcessParameterChecksheet />,
    },
    {
        path: '/checksheet/processParameterChecksheet/customFormat',
        element: <CustomFormatProcessParameterChecksheet />,
    },
    {
        path: '/checksheet/mouldSetupChecksheet',
        element: <MouldSetupChecksheet />,
    },
    {
        path: '/checksheet/mouldSetupAndUnloadingChecksheet',
        element: <MouldSetupAndUnloadingSheet />,
    },
    {
        path: '/checksheet/hopperCleaningChecksheet',
        element: <HopperCleaningChecksheet />
    },
    {
        path: '/checksheet/hopperCleaningChecksheet/customFormat',
        element: <CustomFormatHopperCleaningChecksheet />,
    },
    {
        path: '/checksheet/preHeatingRecord',
        element: <PreHeatingRecord />
    },
    {
        path: '/checksheet/reworkPartMonitoring',
        element: <ReworkPartMonitoring />
    },
    {
        path: '/checksheet/scrapDataNote',
        element: <ScrapDataNote />
    },
    {
        path: '/checksheet/firstLastPartApproval',
        element: <FirstLastPartApproval />
    },
    {
        path: '/checksheet/hourlyInspectionReport',
        element: <HourlyInspectionChecksheet />
    },
    {
        path: '/checksheet/abnormalConditionInspectionReport',
        element: <AbnormalConditionInspectionReport />
    },
    {
        path: '/checksheet/4mmChangeInspectionReport',
        element: <FourMmChangeInspectionReport />
    },
    {
        path: '/checksheet/4mmChangeAbnormalChecksheet',
        element: <FourMChangeAbnormalChecksheet />
    },
    {
        path: '/checksheet/4mmChangeAbnormalChecksheet/customFormat',
        element: <CustomFormatForChangeAbnormalSheet />
    },
    {
        path: '/checksheet/setupApprovalReport',
        element: <SetupApprovalReport />
    },
    {
        path: '/admin/ProductRecipe/viewPlatingCategory',
        element: <ViewPlatingCategory />,
    },
    {
        path: '/admin/ProductRecipe/addPlatingCategory',
        element: <AddPlatingCategory />,
    },
    {
        path: '/view-question',
        element: <ViewQuestion />,
    },
    {
        path: '/add-question',
        element: <AddQuestion />,
    },
    {
        path: '/PendingProducts',
        element: <PendingProducts />,
    },
    {
        path: '/PendingProducts/add',
        element: <AddPendingProductsToSFG />,
    },
    {
        path: '/SFGStock',
        element: <ViewSFGProduct />
    },
    {
        path: '/FGStock',
        element: <ViewFGProduct />
    },
    {
        path: '/issueProducts',
        element: <IssueProduct />,
    }
    // {
    //     path: '/view-waste',
    //     element: <ViewWaste />,
    // },
]

export default adminRoutes
