import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addBin } from 'app/services/Bin.service'
import { getAllBinType } from 'app/services/BinType.service'
import { getAllBinSize } from 'app/services/BinSizes.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddBin() {
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [binTypes, setBinTypes] = useState([])
    const [binSizes, setBinSizes] = useState([])
    const [selectedBinType, setSelectedBinType] = useState('')
    const [selectedBinSize, setSelectedBinSize] = useState('')

    useEffect(() => {
        fetchInitialData()
    }, [])

    const fetchInitialData = async () => {
        try {
            const [binTypeRes, binSizeRes] = await Promise.all([
                getAllBinType(),
                getAllBinSize()
            ])
            setBinTypes(binTypeRes.data.data || [])
            setBinSizes(binSizeRes.data.data || [])
        } catch (error) {
            toastError('Error fetching data')
        }
    }

    const handleSubmit = async () => {
        if (!name.trim() || !selectedBinType || !selectedBinSize) {
            toastError('Please fill all required fields')
            return
        }

        try {
            let obj = {
                name,
                description,
                binTypeId: selectedBinType,
                binSizeId: selectedBinSize
            }
            let { data: res } = await addBin(obj)
            if (res) {
                toastSuccess(res.message)
                navigate(-1)
            }
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="Add Bin">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField 
                        type="text" 
                        name="name" 
                        onChange={(e) => setName(e.target.value)} 
                        value={name || ''} 
                        label="Name" 
                        sx={{ width: '100%', mt: 2 }} 
                    />

                    <TextField 
                        type="text" 
                        name="Description" 
                        onChange={(e) => setDescription(e.target.value)} 
                        value={description || ''} 
                        label="Description" 
                        sx={{ width: '100%', mt: 2 }} 
                    />

                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Bin Type</InputLabel>
                        <Select
                            value={selectedBinType}
                            label="Bin Type"
                            onChange={(e) => setSelectedBinType(e.target.value)}
                        >
                            {binTypes.map((type) => (
                                <MenuItem key={type._id} value={type._id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Bin Size</InputLabel>
                        <Select
                            value={selectedBinSize}
                            label="Bin Size"
                            onChange={(e) => setSelectedBinSize(e.target.value)}
                        >
                            {binSizes.map((size) => (
                                <MenuItem key={size._id} value={size._id}>
                                    {size.name} ({size.length}x{size.width}x{size.height})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ mt: 2 }} 
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
