import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { deleteMachineData, getAllMachineData } from 'app/redux/actions/Machines/machine.action'
import { exportMachineData } from 'app/services/machine.service'
import { getAllShigtData } from 'app/services/shiftData.service'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ViewMouldingProduction() {
  
    const navigate = useNavigate()

    const [shiftData,setShiftData]=useState([]);


    const getShiftData=async()=>{
        try {
            let {data:res}=await getAllShigtData();
            if(res){
                console.log(res.data,"@@")
                setShiftData(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(()=>{
        getShiftData();
    },[])
  

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Moudling Productions'}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                    {/* <Button variant="contained" color="primary" onClick={handleDownloadExcel}>
                        Download Excel
                    </Button> */}
                </Box>
                <ContentTable
                    data={shiftData}
                    columns={[
                        {
                            name: '#',
                            selector: (row, index) => index + 1,
                            sortable: true,
                        },
                        {
                            name: 'Date',
                            selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Shift',
                            selector: (row) => row?.shift,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Mould Name',
                            selector: (row) => row?.mouldName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Mould No',
                            selector: (row) => row?.mouldNumber,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Operator',
                            selector: (row) => row?.operatorName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Total Production',
                            selector: (row) => row?.totalProduction,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Status',
                            selector: (row) => row?.status,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Action',
                            cell: (row) => (
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Button variant='outlined' color='primary' onClick={() => navigate(`/ViewSpecificMouldingProduction/${row._id}`)}>View</Button>
                                    {/* <Button variant='outlined' color='primary' onClick={() => handleUpdate(row._id)}>Update</Button> */}
                                    {/* <Button variant='outlined' color='error' onClick={() => handleDelete(row._id)}>Delete</Button> */}
                                </Box>
                            ),
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
