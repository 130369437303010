import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'

import { getAllHrlyInspectionReport } from 'app/services/hrlyInspectionReport.service'
import toast from 'react-hot-toast'
import hr from 'date-fns/locale/hr'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

const DateCell = styled(TableCell)(() => ({
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: 0,
    width: '10px',
    fontSize: '0.7rem',
    textAlign: 'center',
}))

export default function HourlyInspectionChecksheet() {
    const [hrlyInspectionReport, setHrlyInspectionReport] = useState([])

    const dayShift = ['9:00 - 11:00', '11:00 - 13:00', '13:00 - 15:00', '15:00 - 17:00', '17:00 - 19:00', '19:00 - 21:00']

    const nightShift = ['21:00 - 23:00', '23:00 - 01:00', '01:00 - 03:00', '03:00 - 05:00', '05:00 - 07:00', '07:00 - 09:00']

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllHrlyInspectionReport()
                setHrlyInspectionReport(res.data.data)
            } catch (error) {
                toast.error('not found')
            }
        }

        fetchData()
    }, [])

    console.log(hrlyInspectionReport, 'hrlyInspectionReport')
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" width="50px" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1rem' }}>
                                        TITLE : 2 HRLY INSPECTION REPORT - MOULDING STAGE
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                        Date:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        {hrlyInspectionReport[0]?.dateCompareString}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        A
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Short Moulding
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        F
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Silver Streaks
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        K
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Flow Mark
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        P
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Oil Mark
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        B
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Thread NG
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        G
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Scratches
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        L
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Bubble Mark
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Q
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Pitting
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Part Name:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        {hrlyInspectionReport[0]?.productName}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Machine Name:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        {hrlyInspectionReport[0]?.machineName}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                        Material/Grade:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        C
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Color Mixing
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        H
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Weld Line
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        M
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Burn Mark
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        R
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Dent
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        D
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Sink Mark
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        I
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Damage
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        N
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Water Mark
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        S
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Flash
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Part No.:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        {hrlyInspectionReport[0]?.productNo}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Mould No.:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                        Mould Cavity No:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        E
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Gas Mark
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        J
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Wrapage
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        O
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Black Spot
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} sx={{ backgroundColor: '#FDFAF6' }}>
                                        T
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} sx={{ backgroundColor: '#FDFAF6' }}>
                                        Shades
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} colSpan={2} align="center">
                                        Parameter
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} colSpan={2} align="center">
                                        Specification
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} colSpan={2} align="center">
                                        Insp. Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} align="center">
                                        Day Shift
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} align="center">
                                        Night Shift
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Remarks
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    {dayShift?.map((dayShift, index) => (
                                        <DateCell key={index} align="center">
                                            {dayShift}
                                        </DateCell>
                                    ))}

                                    {nightShift?.map((nightShift, index) => (
                                        <DateCell key={index} align="center">
                                            {nightShift}
                                        </DateCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {hrlyInspectionReport?.map((hrlyInspectionReport, index) => (
                                    <TableRow key={index}>
                                        <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center">
                                            {hrlyInspectionReport?.parameter}
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center">
                                            {hrlyInspectionReport?.specification}
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center">
                                            {hrlyInspectionReport?.inspectionMethod}
                                        </StyledTableCell>
                                        {dayShift.map((slot, index) => {
                                            const normalizedSlot = slot.replace(/\s*-\s*/g, '-').replace(/\b(\d:)/g, '0$1')
                                            const shiftData = hrlyInspectionReport?.dayShift?.find((s) => s.timeSlot === normalizedSlot)

                                            return (
                                                <React.Fragment key={slot}>
                                                    <DateCell key={`${slot}-A`} align="center">
                                                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', padding: 0, width: '50px', height: '38px', fontSize: '0.7rem' }}>
                                                            {shiftData?.partA ? (shiftData.partA.status ? '✔' : '✘') : ''}
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', padding: 0, width: '50px', height: '38px', fontSize: '0.7rem' }}>
                                                            {shiftData?.partB ? (shiftData.partB.status ? '✔' : '✘') : ''}
                                                        </TableCell>
                                                    </DateCell>
                                                </React.Fragment>
                                            )
                                        })}

                                        {nightShift.map((slot, index) => {
                                            const normalizedSlot = slot.replace(/\s*-\s*/g, '-').replace(/\b(\d:)/g, '0$1')

                                            const shiftData = hrlyInspectionReport?.nightShift?.find((s) => s.timeSlot === normalizedSlot)

                                            return (
                                                <React.Fragment key={slot}>
                                                    <DateCell key={`${slot}-A`} align="center">
                                                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', padding: 0, width: '50px', height: '38px', fontSize: '0.7rem' }}>
                                                            {shiftData?.partA ? (shiftData.partA.status ? '✔' : '✘') : ''}
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)', padding: 0, width: '50px', height: '38px', fontSize: '0.7rem' }}>
                                                            {shiftData?.partB ? (shiftData.partB.status ? '✔' : '✘') : ''}
                                                        </TableCell>
                                                    </DateCell>
                                                </React.Fragment>
                                            )
                                        })}

                                        <StyledTableCell colSpan={2} align="center">{hrlyInspectionReport?.remarks}</StyledTableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        CONTENT
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        STANDARD
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        METHOD
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        OBSERVATION-TIME
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        OBSERVATION-TIME
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        OBSERVATION-TIME
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        OBSERVATION-TIME
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                </TableRow>

                                {
                                    hrlyInspectionReport.map((hrlyInspectionReport, index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell colSpan={3} align="center">
                                        {hrlyInspectionReport?.content}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">{hrlyInspectionReport?.standard}</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">{hrlyInspectionReport?.method}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">{hrlyInspectionReport?.observationTime1}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">{hrlyInspectionReport?.observationTime2}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">{hrlyInspectionReport?.observationTime3}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">{hrlyInspectionReport?.observationTime4}</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        Total OK Parts
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        NG Parts
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        M/C Shot Count:
                                    </StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={4} align="center">
                                        O-OK <br /> X-NG <br /> △-UD
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} rowSpan={2} align="center">{hrlyInspectionReport[0]?.totalOKParts}</StyledTableCell>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">{hrlyInspectionReport[0]?.ngParts}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        Total NG Part:
                                    </StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        Signature:
                                    </StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={7} align="right" sx={{ fontWeight: '700' }}>
                                        FINAL JUDGEMENT-(Tick 1) {'-->'}
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} align="center">
                                        [ ] - O-OK , [ ] - X - NG , [ ] - Δ -UNDER DEVIATION
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} align="center">
                                        [ ] - O-OK , [ ] - X - NG , [ ] - Δ -UNDER DEVIATION
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}
