import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/rework-part-monitoring`

export const addReworkPartMonitoring = (obj) => {
     return axios.post(`${url}/`, obj)
}

export const getAllReworkPartMonitoring = () => {
     return axios.get(`${url}/`)
}