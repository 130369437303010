import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { getAllabnormalConditionInspectionReport } from 'app/services/abnormalConditionInspectionReport.service'
import { styled } from '@mui/system'
import toast from 'react-hot-toast'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function AbnormalConditionInspectionReport() {
    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllabnormalConditionInspectionReport()
                setData(res.data.data)
            } catch (error) {
                toast.error(error)
            }
        }

        fetchData()
    }, [])
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={3} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} rowSpan={1} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                        DEPT.: PRODUCTION
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>PRD-F-008</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                        TITLE : ABNORMAL CONDITION INSPECTION REPORT
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>REV.</StyledTableCell>
                                    <StyledTableCell colSpan={2}>00/10.10.2021</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>EFF. DATE</StyledTableCell>
                                    <StyledTableCell colSpan={2}>12-03-25</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell colSpan={2}>CUSTOMER NAME:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>MACHINE NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.machineNo}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.dateCompareString}</StyledTableCell>
                                    <StyledTableCell colSpan={2}>From to Time</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>PART NAME:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.productName}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>OPERATION / OPERATION No.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>SAMPLE SIZE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>10 pcs</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1}>PART No.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.productNo}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>MODEL:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>LOT No./QTY</StyledTableCell>
                                    <StyledTableCell colSpan={1}>{data[0]?.lotNo}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>CHANGE TYPE:</StyledTableCell>
                                    <StyledTableCell colSpan={1}>{data[0]?.changeType}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        Description
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={10} align="center">
                                        Observation
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Status
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2} align="center">
                                        Parameter
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Specification
                                    </StyledTableCell>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                                        <StyledTableCell key={index} align="center">
                                            {item}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow>
                                            <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.parameter}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.specification}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.method}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type1}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type2}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type3}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type4}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type5}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type6}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type7}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type8}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type9}</StyledTableCell>
                                            <StyledTableCell align="center">{item.observation.type10}</StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.status}
                                            </StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell colSpan={5} align="right">
                                                Lot Status:
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="left">
                                                {item?.lotStatus?.OK ? '✔' : ''}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">OK</StyledTableCell>
                                            <StyledTableCell align="center">{item?.lotStatus?.NG ? '✔' : ''}</StyledTableCell>
                                            <StyledTableCell align="center">NG</StyledTableCell>

                                            <StyledTableCell colSpan={9} align="center">
                                                If Lot found NG, Take another 10 samples & follow till Lot found OK
                                            </StyledTableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}

                                <TableRow>
                                    <StyledTableCell colSpan={8} align="right">
                                        RETROACTIVE CHECK / SUSPECTED :- (Result of 100% inspection):
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={11} align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        TOTAL PARTS:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        OK PARTS:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        OK REWORKED PARTS:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        NG PARTS:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        REJECTION DETAIL:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={5} align="center">
                                        Inv. No. on Tag Applied:
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">{data[0]?.totalParts}</StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">{data[0]?.okParts}</StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">{data[0]?.okReworkedParts}</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">{data[0]?.ngParts}</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">{data[0]?.rejectionDetail}</StyledTableCell>
                                    <StyledTableCell colSpan={5} align="center">
                                        4M Change tag Attached (Y/N)
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="left">
                                        Checked by:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} align="left">{data[0]?.checkedBy}</StyledTableCell>
                                    <StyledTableCell colSpan={4} align="left">
                                        Approved by:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={5} align="left">{data[0]?.approvedBy}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}
