import { Button, TextField, Typography, Box, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addBin } from 'app/services/Bin.service'
import { addSupplier, getSupplierById, updateSupplierById } from 'app/services/Supplier.service'
import { getAllRawMaterial } from 'app/services/rawMaterial.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddSupplier() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [contact, setContact] = useState('')
    const [gstIn, setGstIn] = useState('')
    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([])
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [supplierType, setSupplierType] = useState('')
    const [previusObj, setPreviousObj] = useState(null)
    useEffect(() => {
        getRawMaterial()
    }, [])

    const getRawMaterial = async () => {
        try {
            let { data: res } = await getAllRawMaterial()
            if (res) {
                let tempArr = res.data
                tempArr = tempArr.map((el) => ({ ...el, label: el.name, value: el._id }))
                setRawMaterialArr([...tempArr])
            }
        } catch (error) {
            toastError(error)
        }
    }
    const [searchParams, setSearchParams] = useSearchParams()
    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                address,
                contact,
                gstIn,
                supplierType,
                rawMaterialArr: selectedRawMaterialArr.map((el) => ({ rawMaterialId: el._id, rawMaterialName: el.name })),
            }

            if (previusObj && previusObj?._id) {
                let { data: res } = await updateSupplierById(obj, previusObj?._id)
                if (res) {
                    toastSuccess(res.message)
                    navigate('/ViewSupplier')
                }
            } else {
                let { data: res } = await addSupplier(obj)
                if (res) {
                    toastSuccess(res.message)
                    navigate('/ViewSupplier')
                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        if (searchParams.get('id')) {
            getPreviosData()
        }
    }, [])

    const getPreviosData = async () => {
        try {
            const { data: res } = await getSupplierById(searchParams.get('id'))
            if (res) {
                setName(res?.data?.name)
                setAddress(res?.data?.address)
                setContact(res?.data?.contact)
                setGstIn(res?.data?.gstIn)
                setPreviousObj(res?.data)
                setSupplierType(res?.data?.supplierType)
                setSelectedRawMaterialArr(res?.data?.rawMaterialArr.map((el) => ({ _id: el.rawMaterialId, name: el.rawMaterialName, label: el.rawMaterialName, value: el.rawMaterialId })))
            }
        } catch (error) {
            toastError(error)
        }
    }

    return (
        // <Container>
        <SimpleCard title="Add Supplier">
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit()
                }}
            >
                <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />

                <TextField type="text" name="Contact" onChange={(e) => setContact(e.target.value)} value={contact || ''} label="Contact" sx={{ width: '100%', mt: 2 }} />
                <TextField type="text" name="Address" onChange={(e) => setAddress(e.target.value)} value={address || ''} label="Address" sx={{ width: '100%', mt: 2 }} />
                <TextField type="text" name="GSTIN" onChange={(e) => setGstIn(e.target.value)} value={gstIn || ''} label="GST IN" sx={{ width: '100%', mt: 2, mb: 2 }} />

                <br />
                <RadioGroup aria-label="supplier-type" name="supplier-type" value={supplierType} onChange={(e) => setSupplierType(e.target.value)} row>
                    <FormControlLabel value="Manufacturer" control={<Radio />} label="Manufacturer" />
                    <FormControlLabel value="Trader" control={<Radio />} label="Trader" />
                </RadioGroup>

                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    Raw Materials
                </Typography>
                <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={rawMaterialArr} value={selectedRawMaterialArr} isMulti placeholder="Select Raw Material" onChange={(e) => setSelectedRawMaterialArr(e)} />

                <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                    Submit
                </Button>
            </form>
        </SimpleCard>
        // </Container>
    )
}
