import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tab, TableFooter } from '@mui/material'
import { styled } from '@mui/system'

import { getProcessParamereByID } from 'app/services/processParameterChecksheet.service'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function CustomformatProcessPlanning() {
    const id = new URLSearchParams(useLocation().search).get('id') || ''
    const [processParameter, setProcessParameter] = useState({})

    const getParameterData = async () => {
        try {
            if (id !== '') {
                const res = await getProcessParamereByID(id)

                if (res) {
                    setProcessParameter(res?.data?.injectionMoulding)
                }
            }
        } catch (error) {
            if (error?.response?.data?.message) alert(error?.response?.data?.message)
            else alert(error?.message)
        }
    }

    useEffect(() => {
        getParameterData()
    }, [])
    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={2} align='center'>
                                    <img src={'/assets/images/moulding-sheet.png'} alt="logo" style={{paddingY: '5px'}} />
                                </StyledTableCell>
                                <StyledTableCell colSpan={7} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                    DEPT.: MOULDING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{fontSize: '1rem'}}>DOC. NO.:</StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{fontSize: '1rem'}}>MLD-F-001</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={7} align="center" sx={{ fontSize: '1rem' }}>
                                    TITLE : PROCESS PARAMETER SHEET (INJECTION MOULDING)
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{fontSize: '1rem'}}>REV. NO./ DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{fontSize: '1rem'}}>00/10.10.2021</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                         <TableRow>
                              <StyledTableCell colSpan={4} sx={{fontSize: '1rem', color: "#27667B"}}>M/C NO.:</StyledTableCell>
                              <StyledTableCell colSpan={4} sx={{fontSize: '1rem', color: "#27667B"}}>PCS. CONTROL NO.:</StyledTableCell>
                              <StyledTableCell colSpan={2} sx={{fontSize: '1rem'}}>EFFT.:</StyledTableCell>
                              <StyledTableCell colSpan={2} sx={{fontSize: '1rem'}}>12.10.21</StyledTableCell>
                         </TableRow>

                         <TableRow>
                              <StyledTableCell colSpan={2}>MOULD NO.</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>CUSTOMER</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>SHOW WT(GMS)</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                         </TableRow>

                         <TableRow>
                              <StyledTableCell colSpan={2}>MOULD WT.(KG)</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>RAW MATERIAL</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>RUNNER WT.(GMS)</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                         </TableRow>

                         <TableRow>
                              <StyledTableCell colSpan={2}>MOULD SIZE (LxWxH)</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>MAT. GRADE</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>PART WT.(GMS)</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                         </TableRow>

                         <TableRow>
                              <StyledTableCell colSpan={2}>PART NAME</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>PREHEATING TEMP./TIME</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>NO. OF CAVITY</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                         </TableRow>

                         <TableRow>
                              <StyledTableCell colSpan={2}>PART NO.</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}>MB% & COLOUR</StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                              <StyledTableCell colSpan={2}></StyledTableCell>
                         </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell header colSpan={11}>
                                    TEMPERATURE
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell subheader align="left">
                                    OC
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    NH2
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    NH1
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    Z1
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    Z2
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    Z3
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    Z4
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    Z5
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    FZ
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    OIL
                                </StyledTableCell>
                                <StyledTableCell subheader align="left">
                                    HRTC
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell>SET ºC</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempNH2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempNH1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempZ1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempZ2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempZ3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempZ4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempZ5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempFZ[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempOil[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.tempHrtc[0]?.std}</StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="injection parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell header colSpan={11}>
                                    INJECTION
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Row 1 - Step Numbers */}
                            <TableRow>
                                <StyledTableCell subheader>STEP</StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    6
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    5
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    4
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    3
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    2
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    1
                                </StyledTableCell>
                                <StyledTableCell subheader align="center" colSpan={4}>
                                    REFILL
                                </StyledTableCell>
                            </TableRow>

                            {/* Row 2 - Position (Injection) / Pressure (Refill) */}
                            <TableRow>
                                <StyledTableCell>POSITION (MM)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPosition_MM_6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPosition_MM_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPosition_MM_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPosition_MM_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPosition_MM_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPosition_MM_1[0]?.std}</StyledTableCell>
                                <StyledTableCell subheader>STEP</StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    1
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    2
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    3
                                </StyledTableCell>
                            </TableRow>

                            {/* Row 3 - Speed (Injection) / Speed (Refill) */}
                            <TableRow>
                                <StyledTableCell>SPEED (MM/SEC)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionSpeed_MM_SEC_6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionSpeed_MM_SEC_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionSpeed_MM_SEC_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionSpeed_MM_SEC_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionSpeed_MM_SEC_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionSpeed_MM_SEC_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>PRESSURE (BAR)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillPressure_BAR_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillPressure_BAR_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillPressure_BAR_3[0]?.std}</StyledTableCell>
                            </TableRow>

                            {/* Row 4 - Pressure (Injection) / Back Pressure (Refill) */}
                            <TableRow>
                                <StyledTableCell>PRESSURE (BAR)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPressure_BAR_6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPressure_BAR_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPressure_BAR_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPressure_BAR_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPressure_BAR_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPressure_BAR_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>SPEED (MM/SEC) / RPM</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillSpeed_MM_SEC_RPM_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillSpeed_MM_SEC_RPM_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillSpeed_MM_SEC_RPM_3[0]?.std}</StyledTableCell>
                            </TableRow>

                            {/* Row 5 - Pack/Hold Pressure (Injection) / Position (Refill) */}
                            <TableRow>
                                <StyledTableCell>PACK / HOLD PRS.(BAR)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Prs_BAR_6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Prs_BAR_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Prs_BAR_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Prs_BAR_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Prs_BAR_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Prs_BAR_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>BACK PRESSURE (BAR)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillBackPressure_BAR_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillBackPressure_BAR_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillBackPressure_BAR_3[0]?.std}</StyledTableCell>
                            </TableRow>

                            {/* Row 6 - Pack/Hold Speed */}
                            <TableRow>
                                <StyledTableCell>PACK / HOLD SPD.(MM/SEC)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Spd_MM_SEC_6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Spd_MM_SEC_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Spd_MM_SEC_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Spd_MM_SEC_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Spd_MM_SEC_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Spd_MM_SEC_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>POSITION (mm)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillPosition_MM_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillPosition_MM_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.refillPosition_MM_3[0]?.std}</StyledTableCell>
                            </TableRow>

                            {/* Row 7 - Pack/Hold Time */}
                            <TableRow>
                                <StyledTableCell>PACK / HOLD TIME(Sec)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Time_SEC_6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Time_SEC_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Time_SEC_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Time_SEC_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Time_SEC_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.injectionPack_Hold_Time_SEC_1[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={4}></StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="mould parameter table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell header align="left" colSpan={1}>
                                    MOULD
                                </StyledTableCell>
                                <StyledTableCell header align="right" colSpan={6}>
                                    OPEN
                                </StyledTableCell>
                                <StyledTableCell header align="left" colSpan={6}>
                                    CLOSE
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell subheader>STEP</StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    MO END
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    4
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    3
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    2
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    1
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    BR AW
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    1
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    2
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    3
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    4
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    5
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    MLD TCH
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell>PRESSURE (BAR)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Open_MO_END[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Open_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Open_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Open_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Open_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Open_BR_AW[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Close_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Close_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Close_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Close_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Close_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPressure_BAR_Close_MLD_TCH[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell>SPEED (MM/SEC)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Open_MO_END[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Open_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Open_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Open_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Open_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Open_BR_AW[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Close_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Close_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Close_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Close_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Close_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldSpeed_MM_SEC_Close_MLD_TCH[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell>POSITION (MM)</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Open_MO_END[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Open_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Open_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Open_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Open_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Open_BR_AW[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Close_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Close_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Close_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Close_4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_CLose_5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.mouldPosition_MM_Close_MLD_TCH[0]?.std}</StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="ejector parameter table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell header colSpan={1}>
                                    EJECTOR
                                </StyledTableCell>
                                <StyledTableCell header colSpan={3} align="left">
                                    FORWARD
                                </StyledTableCell>
                                <StyledTableCell header colSpan={3} align="right">
                                    RETRACT
                                </StyledTableCell>
                                <StyledTableCell header rowSpan={2} colSpan={2} align="center">
                                    CORE
                                </StyledTableCell>
                                <StyledTableCell header colSpan={2} align="center">
                                    CORE 1
                                </StyledTableCell>
                                <StyledTableCell header colSpan={2} align="center">
                                    CORE 2
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell subheader>STEP</StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    1
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    2
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    LIMIT
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    3
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    2
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    1
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    SET
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    PULL
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    IN
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    OUT
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell>SPEED</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorSpeed_MM_SEC_Forward_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorSpeed_MM_SEC_Forward_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorSpeed_MM_SEC_Forward_Limit[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorSpeed_MM_SEC_Retract_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorSpeed_MM_SEC_Retract_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorSpeed_MM_SEC_Retract_1[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>CONF.</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.conf_core1_set[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.conf_core1_pull[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.conf_core2_in[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.conf_core2_out[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell>PRESSURE</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPressure_BAR_Forward_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPressure_BAR_Forward_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPressure_BAR_Forward_Limit[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPressure_BAR_Retract_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPressure_BAR_Retract_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPressure_BAR_Retract_1[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>SPEED</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.speed_core1_set[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.speed_core1_pull[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.speed_core2_in[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.speed_core2_out[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell>POSITION</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPosition_MM_Forward_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPosition_MM_Forward_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPosition_MM_Forward_Limit[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPosition_MM_Retract_3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPosition_MM_Retract_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.ejectorPosition_MM_Retract_1[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>POSITION</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.position_core1_set[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.position_core1_pull[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.position_core2_in[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.position_core2_out[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell subheader colSpan={7} align="center">
                                    GENERAL
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>TIME</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_core1_set[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_core1_pull[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_core2_in[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_core2_out[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>INJECTION TIME (SEC)</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalInjectionTime_SEC[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>TRANSFER POSITION (MM)</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalTransferPosition_MM[0]?.std}</StyledTableCell>
                                <StyledTableCell subheader colSpan={6} align="center">
                                    TIMER
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>ROT. TIME DELAY (SEC)</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalRotTimeDelay_SEC[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>SHOT SIZE (MM)</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalShotSize_MM[0]?.std}</StyledTableCell>
                                <StyledTableCell align="center">TIMER</StyledTableCell>
                                <StyledTableCell align="center">TIME</StyledTableCell>
                                <StyledTableCell align="center">DELAY</StyledTableCell>
                                <StyledTableCell align="center">TIMER</StyledTableCell>
                                <StyledTableCell align="center">TIME</StyledTableCell>
                                <StyledTableCell align="center">DELAY</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>COOLING TIME (SEC)</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalCoolingTime_SEC[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>SUCK BACK (MM)</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalSuckBack_MM[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.timer_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delay_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.timer_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delay_2[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>CYCLE TIME (SEC)</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalCycleTime_SEC[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>MOULD OPEN END (MM)</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalMouldOpenEnd_MM[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.timer_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delay_1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.timer_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.time_2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delay_2[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>TARGET / HOUR</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalTarget_Hour[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>EJECT COUNT (NOS)</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalEjectCount_NOS[0]?.std}</StyledTableCell>
                                <StyledTableCell subheader colSpan={4}>
                                    POSITIVE EJECTOR REQUIRED
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.positiveEjectorRequired[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>TEMP. CTRL FLX HALF</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalTemp_Ctrl_Flx_Half[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>EJECTOR FOR TIME</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalEjectorForTime[0]?.std}</StyledTableCell>
                                <StyledTableCell subheader colSpan={4}>
                                    SPC REQUIRED
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.SPCRequired[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>TEMP. CTRL MOV HALF</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalTemp_Ctrl_Mov_Half[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>CLAMPING FORCE</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalClampingForce[0]?.std}</StyledTableCell>
                                <StyledTableCell subheader colSpan={2}>
                                    PACKING STD.
                                </StyledTableCell>
                                <StyledTableCell colSpan={4}>{processParameter[0]?.packingStd[0]?.std}</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>MTC / MOULD TEMP.</StyledTableCell>
                                <StyledTableCell colSpan={1}>{processParameter[0]?.generalMTC_MouldTemp[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={2}>CUSHION (MM)</StyledTableCell>
                                <StyledTableCell colSpan={2}>{processParameter[0]?.generalCusion_MM[0]?.std}</StyledTableCell>
                                <StyledTableCell colSpan={6}></StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="valve parameter table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={13} header align="center">
                                    SEQUENCER VALVE GATE
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell>VALVE GATE NO.</StyledTableCell>
                                <StyledTableCell align="center">1</StyledTableCell>
                                <StyledTableCell align="center">2</StyledTableCell>
                                <StyledTableCell align="center">3</StyledTableCell>
                                <StyledTableCell align="center">4</StyledTableCell>
                                <StyledTableCell align="center">5</StyledTableCell>
                                <StyledTableCell align="center">6</StyledTableCell>
                                <StyledTableCell align="center">7</StyledTableCell>
                                <StyledTableCell align="center">8</StyledTableCell>
                                <StyledTableCell align="center">9</StyledTableCell>
                                <StyledTableCell align="center">10</StyledTableCell>
                                <StyledTableCell align="center">11</StyledTableCell>
                                <StyledTableCell align="center">12</StyledTableCell>
                            </TableRow>
                        </TableBody>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell>VALVE GATE SIGNAL</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate7[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate8[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate9[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate10[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate11[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.valveGateSignal.gate2[0]?.std}</StyledTableCell>
                            </TableRow>
                        </TableBody>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell>SEQUENCE</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate7[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate8[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate9[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate10[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate11[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.sequence.gate2[0]?.std}</StyledTableCell>
                            </TableRow>
                        </TableBody>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell>OPEN & CLOSE DELAY TIME</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate1[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate2[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate3[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate4[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate5[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate6[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate7[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate8[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate9[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate10[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate11[0]?.std}</StyledTableCell>
                                <StyledTableCell>{processParameter[0]?.delayTime.gate2[0]?.std}</StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="visual inspection">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell subheader colSpan={2}>
                                    VISUAL INSPECTION
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={3}>
                                    STACKING INSTRUCTION
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={4}>
                                    SPECIAL INSTRUCTION
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={3}>
                                    GENERAL TOLERANCE
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell rowSpan={6} colSpan={2}>
                                    AS PER MASTER SAMPLE NO VISUAL DEFECTS ALLOWED,
                                    <br />
                                    <br />
                                    FLOW MARK, SILVER STREACKS, GAS MARK, SCRATCHES, MOISTURE, OIL MARK, FLASH, WELD LINE, SHORT SHOT, WARPAGE
                                </StyledTableCell>
                                <StyledTableCell rowSpan={6} colSpan={3}></StyledTableCell>
                                <StyledTableCell rowSpan={6} colSpan={4}></StyledTableCell>
                                <StyledTableCell colSpan={2}>PRESSURE (BAR)</StyledTableCell>
                                <StyledTableCell> ±10</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>TEMPERATURE (°C)</StyledTableCell>
                                <StyledTableCell> ±10</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>SPEED (MM/SEC.)</StyledTableCell>
                                <StyledTableCell> ±10</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>POSITION (MM)</StyledTableCell>
                                <StyledTableCell> ±10</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>WEIGHT (GRMS)</StyledTableCell>
                                <StyledTableCell> ±10</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2}>TIME (SEC.)</StyledTableCell>
                                <StyledTableCell> ±5</StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="visual inspection">
                        <TableBody>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell colSpan={3}></StyledTableCell>
                                <StyledTableCell colSpan={8} subheader align="center">
                                    APPROVALS
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell sx={{ paddingY: 2 }}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={3}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell sx={{ paddingY: 2 }}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={3}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                                <StyledTableCell sx={{ paddingY: 2 }} colSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell colSpan={3}></StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    {' '}
                                    / /{' '}
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    {' '}
                                    / /{' '}
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    {' '}
                                    / /{' '}
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    {' '}
                                    / /{' '}
                                </StyledTableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <StyledTableCell subheader align="center" color="#000000">
                                    REV.
                                </StyledTableCell>
                                <StyledTableCell subheader align="center">
                                    DATE
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={3} align="center">
                                    CHANGE DESCRIPTION
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={2} align="center">
                                    PREP. BY
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={2} align="center">
                                    MLD. INCH
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={2} align="center">
                                    Q.A. INCH
                                </StyledTableCell>
                                <StyledTableCell subheader colSpan={2} align="center">
                                    ISSUED BY
                                </StyledTableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}
