import React, { useEffect, useState } from 'react'
import { SimpleCard } from 'app/components'
import { Box, styled } from '@mui/system'
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Icon,
    TableRow,
    Button,
} from '@mui/material'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { deleteRawMaterial, getAllRawMaterial } from 'app/services/rawMaterial.service'
import { getAllProduct, deleteProduct } from 'app/services/product.service'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProductData, getAllProductData } from 'app/redux/actions/Product/product.action'
import { deleteUserData } from 'app/redux/actions/User/user.actions'
import { deletestaffAllocationData, getAllstaffAllocationData } from 'app/redux/actions/StaffAllocation/StaffAllocation.action'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'

export default function ViewStaffAllocation() {
    let counter = 1
    const productArr = useSelector(state => state.staffAllocation.staffAllocationArr);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            dispatch(getAllstaffAllocationData())
        } catch (error) {
            toastError(error)
        }
    }

    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }




    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Staff allocation?')) {
                dispatch(deletestaffAllocationData(id))
            }
        } catch (error) {
            toastError(error)
        }
        getUsers()
    }
    const handleUpdate = async (id) => {
        try {
            navigate(`/admin/updateStaffAllocation/${id}`)

        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Staff Allocations'}>
                <ContentTable
                    data={productArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            sortable: true,
                            button: true,
                        },
                        {
                            name: 'Shift',
                            selector: (row) => row?.shift,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Machine',
                            selector: (row) => row?.machineObj?.name ? row?.machineObj?.name : "NA",
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Supervisor',
                            selector: (row) => row?.supervisorObj?.name ? row?.supervisorObj?.name : "NA",
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Inspector',  
                            selector: (row) => row?.inspectorObj?.name ? row?.inspectorObj?.name : "NA",
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Update',
                            selector: (row) => (
                                <Button variant="contained" color="primary" onClick={() => handleUpdate(row._id)}>
                                    Update
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
