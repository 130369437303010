import React from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { getAllMouldSetup } from 'app/services/mouldSetupChecksheet.service'
import { TYPE_OF_CHECKSHEET } from 'app/utils/constant'

import { styled } from '@mui/system'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

const MouldSetupChecksheet = () => {
    const [mouldSetup, setMouldSetup] = React.useState([])

    React.useEffect(() => {
        const fetchAllMouldCheckSheet = async () => {
            const response = await getAllMouldSetup(TYPE_OF_CHECKSHEET.MOULD_SETUP_CHECK_SHEET)
            setMouldSetup(response.data)
        }
        fetchAllMouldCheckSheet()
    }, [])

    console.log(mouldSetup, 'mouldSetup')
    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={3} align="center">
                                    <img src={'/assets/images/moulding-sheet.png'} alt="logo" style={{ padding: '5px 0' }} />
                                </StyledTableCell>
                                <StyledTableCell colSpan={7} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                    DEPT.: MOULDING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                    DOC. NO.:
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                    MLD-F-008
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontSize: '1rem' }}>
                                    TITLE: MOULD SET-UP CHECK SHEET
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                    REV. NO./ DATE:
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                    00/10.10.2021
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                    EFFT DATE:
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                    15.06.25
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell colSpan={12} align="left">
                                    <strong>Date set up:</strong>
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={4} align="left">
                                    <strong>Part Name:</strong>
                                </StyledTableCell>
                                <StyledTableCell colSpan={4} align="left">
                                    <strong>Part No:</strong>
                                </StyledTableCell>
                                <StyledTableCell colSpan={4} align="left">
                                    <strong>M/C No / Ton:</strong>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={2} align="left">
                                    After Repair
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="left">
                                    After Product Modification
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="left">
                                    Mould Maintenance
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="left">
                                    Internal Process Change
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={3} align="left">
                                    Normal Set up
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} align="left">
                                    After long period idling {'>'} 1 year
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} align="left">
                                    After M/C maintenance
                                </StyledTableCell>
                                <StyledTableCell colSpan={1}>
                                    <Box
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            border: '2px solid rgb(43, 45, 43)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: '#000000',
                                            fontWeight: 'bold',
                                        }}
                                    ></Box>
                                </StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={3} align="center">
                                    5M + 1P TYPE
                                </StyledTableCell>

                                <StyledTableCell colSpan={6} rowSpan={1} align="center">
                                    ITEM
                                    <br /> MOULDING PROCESS
                                </StyledTableCell>

                                <StyledTableCell colSpan={3} rowSpan={1} align="center">
                                    STATUS <br /> TICK APPLICABLE BOX
                                </StyledTableCell>

                                <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                    VALIDATED BY
                                    <br /> MOULDING ENGINEER
                                </StyledTableCell>

                                <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                    REMARKS (IF ANY)
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={3} align="center">
                                    QUESTION
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} align="center">
                                    SOP/STANDARD
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} align="center">
                                    RECORD
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} align="center">
                                    OK
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} align="center">
                                    NG
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} align="center">
                                    NA
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'MACHINE' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}

                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'MOULD' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}

                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'MATERIAL' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}

                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'METHOD' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}

                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'MAN_POWER' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}

                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'PRODUCT' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}

                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        {item.questions.type === 'OTHERS' && (
                                            <>
                                                <StyledTableCell align="center">{item.questions.type}</StyledTableCell>
                                                <StyledTableCell colSpan={3}>{item.questions.question}</StyledTableCell>
                                                <StyledTableCell colSpan={2}>{item.standard}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.record}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.status_NA && '✔'}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.validation}</StyledTableCell>
                                                <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}

export default MouldSetupChecksheet
