import axios from '../../axios';
import { url as API_URL } from './url.service';
const url = `${API_URL}/semi-finished-good`


export const addSemiFinishedGood = (data) => {
  return axios.post(`${url}/`, data)
}

export const getRawMaterialStockById = async (id) => {
  return axios.get(`${url}/getProductStockByPartId/${id}`)
}

export const issueRawMaterialById = async (id) => {
  return await axios.get(`${url}/issueProductlById/${id}`)
}