import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addBin, getAllBin } from 'app/services/Bin.service'
import { getAllProduct } from 'app/services/product.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Select from 'react-select';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function BinStockEntry() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [capacity, setCapacity] = useState(0);
    const [isMouldingBin, setIsMoudlingBin] = useState(false)
    const [unit, setUnit] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();

    const [quantity, setQuantity] = useState(0);

    let [binArr, setBinArr] = useState([]);


    let [productArr, setProductArr] = useState([]);


    const getBinsData = async () => {
        try {
            let { data: res } = await getAllBin();
            if (res) {
                setBinArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const getProductData = async () => {
        try {
            let { data: res } = await getAllProduct();
            if (res) {
                setProductArr(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getBinsData();
        getProductData();
    }, [])


    const handleSubmit = async () => {
        try {
            toastSuccess("Stock In SuccessFull")
            // if (searchParams.get('storeId') && searchParams.get('blockId') && searchParams.get('rackId')) {
            //     let obj = {
            //         name,
            //         capacity,
            //         description,
            //         isMouldingBin,
            //         storeId: searchParams.get('storeId'),
            //         blockId: searchParams.get('blockId'),
            //         rackId: searchParams.get('rackId'),
            //     }
            //     let { data: res } = await addBin(obj)
            //     if (res) {
            //         toastSuccess(res.message)
            //         navigate(-1)
            //     }
            // } else {
            //     navigate(-1)
            // }
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="FG/SFG Store Entry">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >

                    <TextField type="text" name="Quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity || ''} label="Scan Bin Bar Code" sx={{ width: '100%', mt: 2 }} />

                    {/* <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={binArr.map(el => ({ ...el, label: el.name, value: el._id }))} placeholder="Select Bin" /> */}
                    <br />
                    {/* <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={productArr.map(el => ({ ...el, label: el.name, value: el._id }))} placeholder="Select Product" /> */}

                    {/* <TextField type="text" name="Quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity || ''} label="Quantity" sx={{ width: '100%', mt: 2 }} /> */}
                    {/* <TextField type="text" name="Description" onChange={(e) => setDescription(e.target.value)} value={description || ''} label="Description" sx={{ width: '100%', mt: 2 }} /> */}
                    {/* <TextField type="number" name="Capacity" onChange={(e) => setCapacity(e.target.value)} value={capacity || ''} label="Capacity" sx={{ width: '100%', mt: 2 }} /> */}
                    {/* <FormGroup sx={{ mt: 3 }}>
                        <FormLabel>Is Moulding Bin ?</FormLabel>
                        <FormControlLabel label={'Is Moudling Bin'} control={<Checkbox checked={isMouldingBin ? true : false} onChange={(e) => setIsMoudlingBin(!isMouldingBin)} />} />
                    </FormGroup> */}
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
