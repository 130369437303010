import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'

import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'

import moment from 'moment'
import { toastError } from 'app/utils/toastUtils'
import { generateExcelForDailyStockReport, getAllRawMaterial, getDailyStockReportFromDate, getDailyStockReportFromDates, getSingleRawMaterialReport } from 'app/services/rawMaterial.service'

import Select from 'react-select';
export default function DailyStockReport() {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'));

    const [stockReportArr, setStockReportArr] = useState([]);

    const [rawMaterialArr, setRawMaterialArr] = useState([])

    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([]);

    const handleGetStockReport = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`;
            console.log(selectedRawMaterialArr, "@@@@")
            if (selectedRawMaterialArr && selectedRawMaterialArr.every(el => el.value != "")) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map(el => el.value))}`
            }
            let { data: res } = await getDailyStockReportFromDates(query);
            if (res) {
                console.log(res);
                setStockReportArr(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetStockReportExcel = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`;
            console.log(selectedRawMaterialArr, "@@@@")
            if (selectedRawMaterialArr && selectedRawMaterialArr.every(el => el.value != "")) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map(el => el.value))}`
            }
            getDailyStockReportFromDates(query);

        } catch (error) {
            toastError(error)
        }
    }

    const handleGetStockReportExcelSinglePage = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`;
            console.log(selectedRawMaterialArr, "@@@@")
            if (selectedRawMaterialArr && selectedRawMaterialArr.every(el => el.value != "")) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map(el => el.value))}`
            }
            getSingleRawMaterialReport(query);

        } catch (error) {
            toastError(error)
        }
    }




    const handleGetRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial();
            if (res) {
                let tempArr = [...res.data.map(el => ({ label: el.name, value: el._id }))];
                setRawMaterialArr(tempArr);
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetStockReport();
        handleGetRawMaterials();
    }, [])






    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Daily Stock Report'}>
                <Typography sx={{ mt: 3 }}>Select Dates</Typography>

                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={2}>
                        <TextField
                            type="date"
                            onChange={e => setStartDate(e.target.value)}
                            value={startDate}
                            label="Start Date"
                            sx={{ width: 250, mt: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            onChange={e => setEndDate(e.target.value)}
                            value={endDate}
                            label="End Date"
                            sx={{ width: 250, mt: 2, ml: 2 }}
                        />

                    </Grid>
                    <Grid item md={3}>
                        <label>Raw Material</label>
                        <Select isMulti value={selectedRawMaterialArr} options={rawMaterialArr} placeholder="Raw Materials" onChange={(e) => setSelectedRawMaterialArr(e)} />
                    </Grid>
                </Grid>




                <Button onClick={() => handleGetStockReport()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Search
                </Button>
                <Button onClick={() => handleGetStockReportExcel()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Daily Report
                </Button>
                <Button onClick={() => handleGetStockReportExcelSinglePage()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Monthly Report
                </Button>

            </SimpleCard>
            {stockReportArr && stockReportArr.length > 0 && stockReportArr.map((el, index) => {
                return (
                    <div style={{ marginTop: 10 }}>

                        <SimpleCard title={`Report Date: ${el.reportDate}`}>
                            <Box sx={{ height: '450px', overflow: 'auto' }}>
                                <Table key={index} sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>Part Name</TableCell>

                                            <TableCell>Part No</TableCell>

                                            <TableCell>Sub Category</TableCell>
                                            <TableCell>Opening Stock</TableCell>
                                            <TableCell>Stock In</TableCell>
                                            <TableCell>Stock Out</TableCell>
                                            <TableCell>Closing Stock</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {el.dataArr && el.dataArr.length > 0 && el.dataArr.map((row, indexX) => (
                                            <TableRow
                                                key={`${index}_${indexX}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {indexX + 1}
                                                </TableCell>
                                                <TableCell>{row?.partName}</TableCell>
                                                <TableCell>{row?.partNo}</TableCell>
                                                <TableCell>{row?.subcategoryName}</TableCell>


                                                <TableCell>{row?.openingStock}</TableCell>
                                                <TableCell>{row?.stockIn}</TableCell>
                                                <TableCell>{row?.stockOut}</TableCell>
                                                <TableCell>{row?.closingStock}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </SimpleCard>
                    </div>

                )
            })}

        </Container>
    )
}
