import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteQuestion, getAllQuestion } from 'app/services/Question.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'
import { url as API_URL } from '../../../services/url.service'

export const generateFilePath = (fileName) => {
     if (typeof fileName == "undefined" || fileName == null) {
       // return logo
     }
   
     if (typeof fileName != "string") {
       return fileName;
     }
   
     if (fileName.startsWith("http")) {
       return fileName;
     }
   
     return `${API_URL}/uploads/${fileName}`;
   };

export default function ViewStore() {
    let counter = 1
    const [questionArr, setQuestionArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getQuestion()
    }, [])

    const getQuestion = async () => {
        try {
            let { data: res } = await getAllQuestion()
            if (res) {
                setQuestionArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    console.log(questionArr, 'questionArr')

    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }

    //     const handleUpdate = async (id) => {
    //         try {
    //             navigate(`/admin/updateproduct/${id}`)
    //         } catch (error) {
    //             toastError(error)
    //         }
    //     }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Store?')) {
                let { data: res } = await deleteQuestion(id)
                if (res) {
                    getQuestion()
                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    console.log(questionArr, 'questionArr')

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Question'}>
                <Button variant="contained" color="primary" onClick={() => navigate('/add-question')}>
                    Add Question
                </Button>

                <ContentTable
                    data={questionArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            sortable: true,
                            button: true,
                        },
                        {
                            name: 'Question',
                            selector: (row) => row?.question,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Type',
                            selector: (row) => row?.type,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'CheckSheet Type',
                            selector: (row) => row?.checksheetType,
                            searchable: true,
                            sortable: true,
                        },
                        {
                         name: 'Image',
                         selector: (row) => {
                             console.log('Image Path:', generateFilePath(row?.image)) 
                             return row?.image ? (
                                 <img 
                                     src={generateFilePath(row?.image)} 
                                     alt="Uploaded Image"
                                     style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }}
                                 />
                             ) : 'No Image';
                         },
                         ignoreRowClick: true,
                         allowOverflow: true,
                     },

                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
