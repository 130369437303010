import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/change-abnormal-information`

export const add4MChangeAbnorlInformation = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAll4MChangeAbnorlInformation = () => {
    return axios.get(`${url}`)
}

export const get4MChangeAbnorlInformationById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const update4MChangeAbnorlInformation = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const delete4MChangeAbnorlInformation = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getAllMachine = () => {
    return axios.get(`${url}/getAllMachine`)
}