// export const API_URL = "http://192.168.0.4:4000/api"
import { url } from 'app/services/url.service'

export const API_URL = url

export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const USERS = {
    ADMIN: 'ADMIN',
    SUB_ADMIN: 'SUB_ADMIN',
    MOULDING: 'MOULDING',
    ASSEMBLY: 'ASSEMBLY',
    PLATING: 'PLATING',
    DISPATCH: 'DISPATCH',
    STORE: 'STORE',
    QC_PDI: 'QC_PDI',
    STORE_EXE: 'STORE_EXE',
    PPC: 'PPC',
    SUPERVISOR: 'SUPERVISOR',
    INSPECTOR: 'INSPECTOR',
    MANAGER: 'MANAGER',
}

export const CATEGORY = {
    ASSEMBLY: 'ASSEMBLY',
    MOULDING: 'MOULDING',
    PLATING: 'PLATING',
    LAB: 'LAB',
    MAINTAINANCE: 'MAINTAINANCE',
    STAIONARY: 'STATIONARY',
    MISC: 'MISC',
    TOOLS: 'TOOLS',
    BOP: 'BOP',
    Packaging: 'Packaging',
    Jigs: 'Jigs',
    Consumable:"Consumable"

}

export const UNITS = {
    KG_BAG: 'KG_BAG',
    KG_CAN: 'KG_CAN',
    LITERS_CAN: 'LITERS_CAN',
    UNIT: 'UNIT',
    PC_PER_PACKET: 'Pc/Packet',
}

export const WEIGHT_UNITS = {
    kg: 'kg',
    gm: 'gm',
    l: 'L',
    ml: 'ml',
}

export const PRODUCT_TYPES = {
    IMPORTED: 'IMPORTED',
    IN_HOUSE: 'IN_HOUSE',
}

export const MaterialTestCertificateArr = [
    {
        label: 'Yes',
        value: 'Yes',
    },
    {
        label: 'No',
        value: 'No',
    },
]

export const USER_ACCESS_ARR = [
    { label: 'Users', value: 'Users' },
    { label: 'Moulding Categories', value: 'Moulding Categories' },
    { label: 'Product', value: 'Product' },
    { label: 'Machine', value: 'Machine' },
    { label: 'Staff Allocation', value: 'Staff Allocation' },
    { label: 'Raw Materials', value: 'Raw Materials' },
    { label: 'Client', value: 'Client' },
    { label: 'Mould', value: 'Mould' },
    { label: 'Assembly Product', value: 'Assembly Product' },
    { label: 'Scheduling', value: 'Scheduling' },
]

export const MrnReasons = {
    DAMAGED_PACKING: 'Damaged Packing',
    SHORT_QUANTITY: 'Short Quantity',
    EXPIRY_MATERIAL: 'Expiry Material',
    TESTING_NG: 'Testing Ng',
    OTHERS: 'Others',
}

export const DEFECT_CATEGORY = {
    Moulding: 'Moulding',
    Plating: 'Plating',
    Assembly: 'Assembly',
    QC: 'QC',
    MRN: 'MRN',
}

export const JIG_STATUES = {
    OK: 'ok',
    REPAIR: 'repair',
    NG: 'ng',
}

export const TYPE_OF_MACHINES = {
    PRODUCTION: 'PRODUCTION',
    NON_PRODUCTION: 'NON_PRODUCTION',
}

export const SHIFT1_TIME = {
    shiftStartTime: 480,  // 8:00 AM
    shiftEndTime: 1200,   // 8:00 PM
};

export const SHIFT2_TIME = {
    shiftStartTime: 1200, // 8:00 PM
    shiftEndTime: 480,    // 8:00 AM (next day)
};

export const QUESTION_TYPE = {
    MACHINE: 'MACHINE',
    MATERIAL: 'MATERIAL',
    MOULD: 'MOULD',
    METHOD: 'METHOD',
    PRODUCT: 'PRODUCT',
    OTHERS: 'OTHERS',
}

export const TYPE_OF_CHECKSHEET = {
    MOULD_SETUP_CHECK_SHEET: "MOULD_SETUP_CHECK_SHEET",
    MOULD_SETUP_AND_UNLOADING_SHEET: "MOULD_SETUP_AND_UNLOADING_SHEET",
    HOPPER_CLEANING_CHECK_SHEET: "HOPPER_CLEANING_CHECK_SHEET",
  }