import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'
import { getAllScrapData } from 'app/services/scrapDataNote.service'

import toast from 'react-hot-toast'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function ScrapDataNote() {
    const [scrapDataNote, setScrapDataNote] = useState({})
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getAllScrapData()
                setScrapDataNote(res.data.data)
            } catch (error) {
                toast.error('not found')
            }
        }

        fetchData()
    }, [])

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={3} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} rowSpan={1} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                        DEPT.: MOULDING
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1}>DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>DOC. NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={1}>MLD-F-007</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={4} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                        TITLE : SCRAP DATA NOTE
                                    </StyledTableCell>

                                    {/* Alignment Fix: Using flex with justifyContent */}
                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                            Plating
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                            ASSY.
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                            Customer Return
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={1}>REV NO./ DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={1}>00/10.10.2021</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                            Moulding
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                            LAB
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={1}>EFFT DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={1}>10.10.2021</StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} align="center">
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Part Name
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Part No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        Reject Qty.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        Weight/ Combo.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        Totl Weight of
                                        <br /> Rejection
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Reason of Rejection
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Remarks
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {Array.isArray(scrapDataNote) &&
                                    scrapDataNote?.map((item, index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell colSpan={1} align="center">
                                                {index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.productName}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.productNo}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} align="center">
                                                {item.rejectQty}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} align="center">
                                                {item.weight_compo}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} align="center">
                                                {item.totalWeight}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.reason}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} align="center">
                                                {item.remarks}
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}

                                <TableRow>
                                    <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Total Weight (KGS)
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        Requested By
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        Prod. Head Sign
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        QA Engg. Sign & Date
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        QA Head Sign & Date
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        Store Incharge Sign & Date
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}
