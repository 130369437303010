import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/abnormal-condition-inspection-report`

export const addabnormalConditionInspectionReport = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllabnormalConditionInspectionReport = () => {
    return axios.get(`${url}`)
}

export const getabnormalConditionInspectionReportById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const updateabnormalConditionInspectionReport = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const deleteabnormalConditionInspectionReport = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}