import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'

import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material'

import { generateExcelForCurrentStockReport, getAllRawMaterial, getCurrentStockReportFromDates } from 'app/services/rawMaterial.service'
import { toastError } from 'app/utils/toastUtils'

import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
export default function CurrentReport() {


    const navigate=useNavigate();
    const [stockReportArr, setStockReportArr] = useState([]);

    const [rawMaterialArr, setRawMaterialArr] = useState([])

    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([]);

    const handleGetStockReport = async () => {
        try {
            let query = ``;
            if (selectedRawMaterialArr && selectedRawMaterialArr.every(el => el.value != "")) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map(el => el.value))}`
            }
            let { data: res } = await getCurrentStockReportFromDates(query);
            if (res) {
                console.log(res);
                setStockReportArr(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetStockReportExcel = async () => {
        try {
            let query = ``;
            console.log(selectedRawMaterialArr, "@@@@")
            if (selectedRawMaterialArr && selectedRawMaterialArr.every(el => el.value != "")) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map(el => el.value))}`
            }
            generateExcelForCurrentStockReport(query);

        } catch (error) {
            toastError(error)
        }
    }


    const handleGetRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial();
            if (res) {
                let tempArr = [...res.data.map(el => ({ label: el.name, value: el._id }))];
                setRawMaterialArr(tempArr);
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetStockReport();
        handleGetRawMaterials();
    }, [])

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Current Stock Report'}>
                <Typography sx={{ mt: 3 }}>Select Dates</Typography>

                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={3}>
                        <label>Raw Material</label>
                        <Select isMulti value={selectedRawMaterialArr} options={rawMaterialArr} placeholder="Raw Materials" onChange={(e) => setSelectedRawMaterialArr(e)} />
                    </Grid>
                </Grid>

                <Button onClick={() => handleGetStockReport()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Search
                </Button>
                <Button onClick={() => handleGetStockReportExcel()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Export
                </Button>

            </SimpleCard>
            <Box sx={{ mt: 1 }}>
                <SimpleCard>
                    <Box sx={{ height: '400px', overflow: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ bgcolor: 'background.paper', fontWeight: 'bold' }}>#</TableCell>
                                    <TableCell sx={{ bgcolor: 'background.paper', fontWeight: 'bold' }}>Part No</TableCell>
                                    <TableCell sx={{ bgcolor: 'background.paper', fontWeight: 'bold' }}>Current Stock</TableCell>
                                    <TableCell sx={{ bgcolor: 'background.paper', fontWeight: 'bold' }}>Unit</TableCell>
                                    <TableCell sx={{ bgcolor: 'background.paper', fontWeight: 'bold' }}>Quantity Per</TableCell>
                                    <TableCell sx={{ bgcolor: 'background.paper', fontWeight: 'bold' }}>Location</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stockReportArr && stockReportArr.length && stockReportArr.map((row, indexX) => (
                                    <TableRow
                                        key={`${indexX}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {indexX + 1}
                                        </TableCell>
                                        <TableCell  component="th" scope="row">
                                            {row?.partNo}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.currentStock}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            {row?.unit}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row?.quantityPer}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Button variant='outlined' color='primary' onClick={() => navigate(`/stock-location?partNo=${row.partNo}`)}>View</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        
                    </Box>
                </SimpleCard>
            </Box>
        </Container>
    )
}
