import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'

import toast from 'react-hot-toast'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))
export default function SetupApprovalReport() {
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={2} rowSpan={4} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} rowSpan={2} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                        DEPT.: NPD
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>NPD-F-028</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>REV NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>00</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={6} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                        TITLE : SETUP APPROVAL REPORT-(Initial Supply control)
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2}>REV DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>10.10.2021</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>EFF. DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>10.10.2021</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell colSpan={2}>PART NAME:</StyledTableCell>
                                    <StyledTableCell colSpan={3}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>EVENT:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        MOULDING SHOP
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>PART No.:</StyledTableCell>
                                    <StyledTableCell colSpan={3}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>LOT SIZE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        ( ) SHOP
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>CUSTOMER NAME:</StyledTableCell>
                                    <StyledTableCell colSpan={3}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>PROCESS NAME:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        OTHER
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1}>
                                        <Box sx={{ width: 16, height: 16, border: '2px solid black' }} />
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>PROCESS NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={3}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>MOLD/ MC NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        🗹 FPA
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center">
                                        🗹 LPA
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={12}>PICTORIAL DETAIL</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={12}></StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} rowSpan={2} align="center">
                                        Specification
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1} rowSpan={2} align="center">
                                        Inspection
                                        <br /> Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={10} align="center">
                                        Observation
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Status
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    {
                                        Array.from({ length: 10 }, (_, index) => (
                                            <StyledTableCell key={index + 1} align="center">
                                                {index + 1}
                                            </StyledTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={1} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={10} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>  
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={8} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={10} align="right">* STATUS LEAGEND :- O- OK , X - NG , Δ - IMPROVEMENT REQUIRED</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                  <StyledTableCell colSpan={2}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={7}>Note:</StyledTableCell>
                                  <StyledTableCell colSpan={3} rowSpan={2} align='center'>Final Judgement <br /> (Tick One)</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                  <StyledTableCell colSpan={2}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={7} rowSpan={4}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                  <StyledTableCell colSpan={2}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={1} align='center'></StyledTableCell>
                                  <StyledTableCell colSpan={2}>OK</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                  <StyledTableCell colSpan={2}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={3}></StyledTableCell>
                                  <StyledTableCell colSpan={1} align='center'></StyledTableCell>
                                  <StyledTableCell colSpan={2}>NG</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                  <StyledTableCell colSpan={2}>REV.</StyledTableCell>
                                  <StyledTableCell colSpan={3}>REV. DATE</StyledTableCell>
                                  <StyledTableCell colSpan={3}>REV. DETAIL</StyledTableCell>
                                  <StyledTableCell colSpan={1} align='center'></StyledTableCell>
                                  <StyledTableCell colSpan={2}>DEVIATION</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}
