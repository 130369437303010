import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/hopper-cleaning-checksheet`

export const addHopperCleaning = (obj) => {
     return axios.post(`${url}/`, obj)
}

export const getAllHopperCleaning = (id) => {
     return axios.get(`${url}/`, { params: { machineId: id }} );
 }

export const getProcessParamereByID = (id) => {
     return axios.get(`${url}/getInjectionMouldingById/${id}`)
}


export const deleteHopperCleaning = (id) => {
     return axios.delete(`${url}/deleteInjectionMouldingById/${id}`)
}

export const getAllMachine = () => {
     return axios.get(`${url}/getAllMachine`)
}