import { Box, Button, Grid, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getClientProducts } from 'app/services/client.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { addClientProductPlanning, getClientProductPlanning } from 'app/services/clientProductPlanning.service' // You'll need to create this service

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const ScrollableTable = styled(Box)({
    overflowX: 'auto',
    width: '100%',
    '& .MuiTable-root': {
        borderCollapse: 'separate',
        borderSpacing: 0,
        width: 'max-content', // This ensures table takes full width of content
    },
    '& .MuiTableCell-root': {
        minWidth: '150px',
        padding: '12px',
        textAlign: 'center',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        whiteSpace: 'nowrap', // Prevents text wrapping
    },
    '& .MuiTableCell-head': {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    '& .product-name-cell': {
        position: 'sticky',
        left: 0,
        backgroundColor: '#f5f5f5',
        zIndex: 2,
        minWidth: '200px', // Wider column for product names
    },
})

const selectStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
}

export default function AddRequirementSheet() {
    const [clientData, setClientData] = useState([])
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'))
    const [selectedProducts, setSelectedProducts] = useState([])
    const [requirementData, setRequirementData] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)
    const [dateError, setDateError] = useState('')

    useEffect(() => {
        getClientProductData()
    }, [])

    const getClientProductData = async () => {
        try {
            let { data: res } = await getClientProducts()
            if (res) {
                let formattedData = res.data.map(client => ({
                    label: client.name,
                    value: client._id,
                    productIdArr: client.productIdArr.map(product => ({
                        label: product.productName,
                        value: product.productId,
                        productId: product.productId,
                        productName: product.productName
                    }))
                }))
                setClientData(formattedData)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const validateAndSetDate = (value, type) => {
        const today = moment().startOf('day')
        const selectedDate = moment(value)

        if (selectedDate.isBefore(today)) {
            setDateError('Cannot select previous dates')
            return
        }

        if (type === 'start') {
            // Check if end date exists and if range is more than a month
            if (endDate) {
                const maxDate = moment(value).add(1, 'month')
                if (moment(endDate).isAfter(maxDate)) {
                    setDateError('Date range cannot exceed one month')
                    return
                }
            }
            
            if (endDate && moment(value).isAfter(endDate)) {
                setDateError('Start date cannot be after end date')
                return
            }
            setStartDate(value)
        } else {
            // Check if range is more than a month
            const maxDate = moment(startDate).add(1, 'month')
            if (selectedDate.isAfter(maxDate)) {
                setDateError('Date range cannot exceed one month')
                return
            }

            if (startDate && moment(value).isBefore(startDate)) {
                setDateError('End date cannot be before start date')
                return
            }
            setEndDate(value)
        }
        setDateError('')
    }

    // Add helper text to display remaining days
    const getRemainingDays = () => {
        if (startDate && endDate) {
            const maxDate = moment(startDate).add(1, 'month')
            const currentEndDate = moment(endDate)
            const daysRemaining = maxDate.diff(currentEndDate, 'days')
            return `${daysRemaining} days remaining in allowed range`
        }
        return ''
    }

    const fetchExistingRequirements = async () => {
        try {
            const query = `startDate=${startDate}&endDate=${endDate}&clientId=${selectedClient?.value}`
            const { data: res } = await getClientProductPlanning(query)
            
            if (res?.data?.length > 0) {
                // Group requirements by product
                const requirementsByProduct = {}
                res.data.forEach(req => {
                    if (!requirementsByProduct[req.productId]) {
                        requirementsByProduct[req.productId] = {
                            product: {
                                productId: req.productId,
                                productName: req.productName,
                            },
                            dates: []
                        }
                    }
                });

                // Fill in requirements for each date
                const dates = []
                let currentDate = moment(startDate)
                while (currentDate <= moment(endDate)) {
                    const dateStr = currentDate.format('YYYY-MM-DD')
                    dates.push(dateStr)
                    
                    // For each product, find requirement for this date or set empty
                    Object.keys(requirementsByProduct).forEach(productId => {
                        const requirement = res.data.find(r => 
                            r.productId === productId && 
                            r.dateCompareString === dateStr
                        )
                        
                        requirementsByProduct[productId].dates.push({
                            date: dateStr,
                            requirementQty: requirement ? requirement.requirementQty.toString() : ''
                        })
                    })
                    
                    currentDate = currentDate.add(1, 'days')
                }

                setRequirementData(Object.values(requirementsByProduct))
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleSubmit = async () => {
        if (!selectedClient || selectedProducts.length === 0) {
            toastError('Please select client and products')
            return
        }

        // Generate dates array outside try-catch block
        const dates = []
        let currentDate = moment(startDate)
        while (currentDate <= moment(endDate)) {
            dates.push(currentDate.format('YYYY-MM-DD'))
            currentDate = currentDate.add(1, 'days')
        }
        
        // First get existing requirements
        const query = `startDate=${startDate}&endDate=${endDate}&clientId=${selectedClient?.value}`
        try {
            const { data: res } = await getClientProductPlanning(query)
            
            // Create requirements map from existing data
            const requirementsByProduct = {}
            if (res?.data?.length > 0) {
                res.data.forEach(req => {
                    if (!requirementsByProduct[req.productId]) {
                        requirementsByProduct[req.productId] = {
                            product: {
                                productId: req.productId,
                                productName: req.productName,
                            },
                            dates: dates.map(date => ({
                                date,
                                requirementQty: ''
                            }))
                        }
                    }
                    // Fill in existing requirements
                    const dateIndex = dates.indexOf(req.dateCompareString)
                    if (dateIndex !== -1) {
                        requirementsByProduct[req.productId].dates[dateIndex].requirementQty = req.requirementQty.toString()
                    }
                })
            }

            // Add new selected products that don't exist in requirements
            selectedProducts.forEach(product => {
                if (!requirementsByProduct[product.value]) {
                    requirementsByProduct[product.value] = {
                        product: {
                            productId: product.value,
                            productName: product.label,
                        },
                        dates: dates.map(date => ({
                            date,
                            requirementQty: ''
                        }))
                    }
                }
            })

            setRequirementData(Object.values(requirementsByProduct))
            
        } catch (error) {
            toastError(error)
            
            // If API call fails, create new structure for all selected products
            const data = selectedProducts.map(product => ({
                product: {
                    productId: product.value,
                    productName: product.label,
                },
                dates: dates.map(date => ({
                    date,
                    requirementQty: ''
                }))
            }))
            setRequirementData(data)
        }
    }

    const handleRequirementChange = (productIndex, dateIndex, value) => {
        const updatedData = [...requirementData]
        updatedData[productIndex].dates[dateIndex].requirementQty = value
        setRequirementData(updatedData)
    }

    const handleFinalSubmit = async () => {
        try {
            // Transform the data to match the schema
            const planningData = requirementData.flatMap(product => 
                product.dates.map(date => ({
                    productId: product.product.productId,
                    productName: product.product.productName,
                    clientId: selectedClient.value,
                    plannedDate: new Date(date.date),
                    dateCompareString: date.date,
                    requirementQty: Number(date.requirementQty) || 0
                }))
            ).filter(item => item.requirementQty > 0); // Only include entries with requirements

            if (planningData.length === 0) {
                toastError('Please enter at least one requirement');
                return;
            }

            console.log(planningData,"CHECK THIS")
            const response = await addClientProductPlanning(planningData);
            if (response.data?.message) {
                toastSuccess(response.data.message);
                // Reset form or redirect as needed
                setRequirementData([]);
                setSelectedProducts([]);
            }
        } catch (error) {
            toastError(error);
        }
    }

    const handleSelectAllProducts = () => {
        if (selectedProducts.length === selectedClient?.productIdArr?.length) {
            // If all products are selected, deselect all
            setSelectedProducts([])
        } else {
            // Select all products
            setSelectedProducts(selectedClient?.productIdArr || [])
        }
    }

    return (
        <Container>
            <SimpleCard title="Add Client Requirements">
                <Grid container spacing={3}>
                    <Grid item md={4}>
                        <TextField
                            type="date"
                            label="Start Date"
                            value={startDate}
                            onChange={(e) => validateAndSetDate(e.target.value, 'start')}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: moment().format('YYYY-MM-DD')
                            }}
                            error={!!dateError}
                            helperText={dateError || getRemainingDays()}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            type="date"
                            label="End Date"
                            value={endDate}
                            onChange={(e) => validateAndSetDate(e.target.value, 'end')}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: moment().format('YYYY-MM-DD')
                            }}
                            error={!!dateError}
                            helperText={dateError || getRemainingDays()}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Select
                            options={clientData}
                            value={selectedClient}
                            onChange={(selected) => {
                                setSelectedClient(selected)
                                setSelectedProducts([]) // Reset selected products
                            }}
                            placeholder="Select Client"
                            styles={selectStyles}
                            menuPortalTarget={document.body}
                        />
                    </Grid>
                    {selectedClient && (
                        <Grid item md={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleSelectAllProducts}
                                >
                                    {selectedProducts.length === selectedClient?.productIdArr?.length
                                        ? 'Deselect All'
                                        : 'Select All'}
                                </Button>
                                <Typography variant="body2">
                                    {selectedProducts.length} of {selectedClient?.productIdArr?.length} selected
                                </Typography>
                            </Box>
                            <Select
                                isMulti
                                options={selectedClient?.productIdArr || []}
                                value={selectedProducts}
                                onChange={setSelectedProducts}
                                placeholder="Select Products"
                                getOptionLabel={option => option.label}
                                getOptionValue={option => option.value}
                                styles={selectStyles}
                                menuPortalTarget={document.body}
                            />
                        </Grid>
                    )}
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mt: 3 }}
                    disabled={!!dateError}
                >
                    Generate Table
                </Button>

                {requirementData.length > 0 && (
                    <Box sx={{ mt: 4 }}>
                        <Box sx={{ 
                            width: '100%',
                            overflow: 'hidden',
                            border: '1px solid rgba(224, 224, 224, 1)',
                            borderRadius: 1,
                        }}>
                            <ScrollableTable>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="product-name-cell">Product Name</TableCell>
                                            {requirementData[0].dates.map((dateData) => (
                                                <TableCell key={dateData.date}>
                                                    {moment(dateData.date).format('DD/MM/YYYY')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {requirementData.map((productData, productIndex) => (
                                            <TableRow key={productData.product.productId}>
                                                <TableCell className="product-name-cell">
                                                    {productData.product.productName}
                                                </TableCell>
                                                {productData.dates.map((dateData, dateIndex) => (
                                                    <TableCell key={dateData.date}>
                                                        <TextField
                                                            type="number"
                                                            value={dateData.requirementQty}
                                                            onChange={(e) =>
                                                                handleRequirementChange(
                                                                    productIndex,
                                                                    dateIndex,
                                                                    e.target.value
                                                                )
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                width: '100%',
                                                                '& .MuiOutlinedInput-input': {
                                                                    padding: '8px',
                                                                    textAlign: 'center',
                                                                },
                                                            }}
                                                        />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </ScrollableTable>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFinalSubmit}
                            sx={{ mt: 2 }}
                        >
                            Submit Requirements
                        </Button>
                    </Box>
                )}
            </SimpleCard>
        </Container>
    )
}