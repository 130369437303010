import React, { useState } from 'react'
import { TextField, Button, Typography, Input, CircularProgress } from '@mui/material'
import Select from 'react-select'
import { addQuestion } from 'app/services/Question.service'
import { toastSuccess, toastError } from 'app/utils/toastUtils'
import { QUESTION_TYPE, TYPE_OF_CHECKSHEET } from 'app/utils/constant'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddQuestion() {
    const navigate = useNavigate()
    const [question, setQuestion] = useState('')
    const [type, setType] = useState(null)
    const [checkSheetType, setCheckSheetType] = useState(null)
    const [image, setImage] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    // Convert file to Base64
    const convertToBase64 = (file) => {
     return new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file); // Use full Base64 data
         reader.onload = () => {
             console.log("Base64 Image Data:", reader.result);
             resolve(reader.result);  // Send full Base64 string
         };
         reader.onerror = (error) => reject(error);
     });
 };
 
 const handleImageChange = async (e) => {
     const file = e.target.files[0];
     if (file) {
         try {
             const base64Image = await convertToBase64(file);
             setImage(base64Image);
         } catch (error) {
             toastError('Error converting image to Base64');
         }
     }
 };
 
 const handleSubmit = async (e) => {
     e.preventDefault();
 
     setIsSubmitting(true);
 
     try {
         const obj = {
             question,
             type,
             checkSheetType,
             image: image,  // Send full Base64 string
         };
 
         console.log("Submitted Data:", obj);
 
         const { data: res } = await addQuestion(obj);
         if (res) {
             toastSuccess(res.message);
             setQuestion('');
             setType(null);
             setCheckSheetType(null);
             setImage(null);
         }
     } catch (error) {
         toastError(error);
     } finally {
         setIsSubmitting(false);
     }
 
     navigate('/view-question');
 };
 

    const typeOptions = Object.entries(QUESTION_TYPE).map(([key, value]) => ({
        label: key,
        value: value,
    }))

    const checkSheetTypeOptions = Object.entries(TYPE_OF_CHECKSHEET).map(([key, value]) => ({
        label: key,
        value: value,
    }))

    return (
        <Container>
            <Typography variant="h5" gutterBottom>
                Add Question
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField type="text" name="question" onChange={(e) => setQuestion(e.target.value)} value={question || ''} label="Question" sx={{ width: '100%', mb: 3, mt: 2 }} required />

                <Select
                    options={typeOptions}
                    value={typeOptions.find((option) => option.value === type)}
                    onChange={(selected) => setType(selected?.value || '')}
                    placeholder="Select Type"
                    isClearable
                    styles={{
                        control: (base) => ({
                            ...base,
                            marginBottom: '24px',
                        }),
                    }}
                />

                <Select
                    options={checkSheetTypeOptions}
                    value={checkSheetTypeOptions.find((option) => option.value === checkSheetType)}
                    onChange={(selected) => setCheckSheetType(selected?.value || '')}
                    placeholder="Select Check Sheet Type"
                    isClearable
                    styles={{
                        control: (base) => ({
                            ...base,
                            marginBottom: '24px',
                        }),
                    }}
                />

                <Input type="file" name="image" onChange={handleImageChange} inputProps={{ accept: 'image/*' }} sx={{ width: '100%', mb: 3, mt: 2 }} />

                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} sx={{ mt: 2 }}>
                    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </Button>
            </form>
        </Container>
    )
}
