import React from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { getAllMouldSetupAndUnloading } from 'app/services/mouldSetupAndUnloadingChecksheet'
import { TYPE_OF_CHECKSHEET } from 'app/utils/constant'

import { styled } from '@mui/system'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

const MouldSetupAndUnloadingSheet = () => {
    const [mouldSetup, setMouldSetup] = React.useState([])

    React.useEffect(() => {
        const fetchAllMouldCheckSheet = async () => {
            const response = await getAllMouldSetupAndUnloading(TYPE_OF_CHECKSHEET.MOULD_SETUP_AND_UNLOADING_SHEET)
            setMouldSetup(response.data)
        }
        fetchAllMouldCheckSheet()
    }, [])

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={4} align="center">
                                    <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                </StyledTableCell>
                                <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                    DEPT.: MOULDING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                <StyledTableCell colSpan={2}>MLD-F-001</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={2}>REV NO./ DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2}>00/10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                    TITLE : MOULD SETUP AND UNLOADING SHEET
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>EFFT DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2}>10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={4}>Page - 1 of 2</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    Mould ID No.:
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    Moulding Machine No.:
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}>Mould Readness</StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    Time:
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1}>Date: </StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    Part Name:
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    Model Code:
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}>Mould Unloading</StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    Shift:
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1}>Date: </StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1}>S. No.</StyledTableCell>
                                <StyledTableCell colSpan={4}>Check point for Mould Readness</StyledTableCell>
                                <StyledTableCell colSpan={1}>Image</StyledTableCell>
                                <StyledTableCell colSpan={3}>Standard</StyledTableCell>
                                <StyledTableCell colSpan={2}>Method</StyledTableCell>
                                <StyledTableCell colSpan={1}>OK</StyledTableCell>
                                <StyledTableCell colSpan={1}>NG</StyledTableCell>
                                <StyledTableCell colSpan={1}>Remarks</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Array.isArray(mouldSetup.data) &&
                                mouldSetup.data?.map((item, index) => (
                                    <TableRow key={index}>
                                        <>
                                            <StyledTableCell colSpan={1} align="center">{index + 1}</StyledTableCell>
                                            <StyledTableCell colSpan={4}>{item.questions.question}</StyledTableCell>
                                            <StyledTableCell colSpan={1}>{item.image}</StyledTableCell>
                                            <StyledTableCell colSpan={3}>{item.standard}</StyledTableCell>
                                            <StyledTableCell colSpan={2}>{item.method}</StyledTableCell>
                                            <StyledTableCell colSpan={1}>{item.status_ok && '✔'}</StyledTableCell>
                                            <StyledTableCell colSpan={1}>{item.status_NG && '✔'}</StyledTableCell>
                                            <StyledTableCell colSpan={1}>{item.Remarks}</StyledTableCell>
                                        </>
                                    </TableRow>
                                ))}

                                <TableRow>
                                   <StyledTableCell colSpan={14}>Major Observation during mould loading :</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                   <StyledTableCell colSpan={4}>Checked by Mould Setter :</StyledTableCell>
                                   <StyledTableCell colSpan={3}></StyledTableCell>
                                   <StyledTableCell colSpan={4}>Approved by Production Supervisor :</StyledTableCell>
                                   <StyledTableCell colSpan={3}></StyledTableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}

export default MouldSetupAndUnloadingSheet
