import axios from '../../axios'
import { url as apiUrl } from './url.service'
import { getJwt } from './user.service'
const url = `${apiUrl}/rawMaterialPlatingConsumption`
let token = getJwt()
const authorisation = `Bearer ${token}`


export const addPlatingConsumption = (data) => {
     return axios.post(`${url}/`, data)
}

export const getPlatingConsumption = () => {
    return axios.get(`${url}/`)
}

export const getPlatingConsumptionById = (id) => {
    return axios.get(`${url}/${id}`)
}