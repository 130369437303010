import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/BinSize`

export const addBinSize = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllBinSize = () => {
    return axios.get(`${url}`)
}

export const deleteBinSize = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}


export const getBinSizeById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}


export const updateBinSize = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
