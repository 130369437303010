import {
    Box,
    Typography,
    Grid,
    Paper,
    Divider,
    Card,
    CardContent,
    Stack,
    Button,
    Modal,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getShiftDataByIdFromDB } from 'app/services/shiftData.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
}))

const InfoLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: 500,
    marginBottom: theme.spacing(0.5)
}))

const InfoValue = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: theme.spacing(2)
}))

const TimeSlotCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        boxShadow: theme.shadows[4]
    }
}))

const ModalStyle = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    maxHeight: '90vh',
    overflow: 'auto'
}))

// Add new styled component for frequency card
const FrequencyCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        boxShadow: theme.shadows[2]
    }
}))

// Add new styled component for summary card
const SummaryCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderRadius: '12px',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
}))

export default function ViewSpecificMouldingProduction() {
    const navigate = useNavigate()
    const params = useParams()
    const [shiftData, setShiftData] = useState({})
    const [openDefectsModal, setOpenDefectsModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const getShiftData = async () => {
        try {
            let { data: res } = await getShiftDataByIdFromDB(params.id)
            if (res) {
                setShiftData(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getShiftData()
    }, [])

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        }
    }))

    const handleOpenDefectsModal = (product) => {
        setSelectedProduct(product)
        setOpenDefectsModal(true)
    }

    const handleCloseDefectsModal = () => {
        setOpenDefectsModal(false)
        setSelectedProduct(null)
    }

    // Add this function to calculate total breakdown time
    const calculateTotalBreakdown = () => {
        if (!shiftData?.reasonAndBreakDownTime?.length) return 0;
        return shiftData.reasonAndBreakDownTime.reduce((total, breakdown) => {
            return total + (breakdown.timeInMinutes || 0);
        }, 0);
    };

    return (
        <Container>
            <SimpleCard title="Moulding Production Details">
                <StyledPaper elevation={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <InfoLabel variant="body2">Mould Name</InfoLabel>
                            <InfoValue variant="h6">{shiftData.mouldName}</InfoValue>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InfoLabel variant="body2">Mould Number</InfoLabel>
                            <InfoValue variant="h6">{shiftData.mouldNumber}</InfoValue>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InfoLabel variant="body2">Operator</InfoLabel>
                            <InfoValue variant="h6">{shiftData.operatorName}</InfoValue>
                        </Grid>
                    </Grid>
                </StyledPaper>

                {/* Add Frequencies Section */}
                {shiftData?.frequencyArr?.length > 0 && (
                    <Box sx={{ mt: 3, mb: 3 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Frequency Checks
                        </Typography>
                        <Grid container spacing={2}>
                            {shiftData.frequencyArr.map((freq, index) => (
                                <Grid item xs={12} md={6} lg={4} key={index}>
                                    <FrequencyCard>
                                        <Typography 
                                            variant="subtitle1" 
                                            color="primary" 
                                            sx={{ mb: 2, fontWeight: 'bold' }}
                                        >
                                            {freq.name}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Standard
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {freq.std}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary">
                                                    First Check
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {freq.firsttime}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Second Check
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {freq.secondTime}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Third Check
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                                    {freq.thirdtTime}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </FrequencyCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}

                <Box sx={{ mt: 3 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Production Timeline</Typography>
                    {shiftData?.okayAndRejectedArr?.map((timeSlot, index) => (
                        <TimeSlotCard key={index}>
                            <CardContent>
                                <Typography variant="subtitle1" color="primary" sx={{ mb: 2 }}>
                                    {timeSlot.startTime} - {timeSlot.endTime}
                                </Typography>
                                <Divider sx={{ mb: 2 }} />
                                <Grid container spacing={2}>
                                    {timeSlot.productsArr?.map((product, productIndex) => (
                                        <Grid item xs={12} md={6} key={productIndex}>
                                            <Paper 
                                                elevation={0} 
                                                sx={{ 
                                                    p: 2, 
                                                    bgcolor: 'background.default',
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <Stack spacing={1}>
                                                    <Typography variant="subtitle2" color="text.secondary">
                                                        Product: {product.Product_Name}
                                                    </Typography>
                                                    <Typography variant="body2" color="info.main">
                                                        Hourly Production: {product.Hourly_Production}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Box>
                                                            <Typography color="success.main">
                                                                OK: {product.Okay_Quantity}
                                                            </Typography>
                                                            <Typography color="error.main">
                                                                Rejected: {product.Rejected_Quantity}
                                                            </Typography>
                                                        </Box>
                                                        {product.defectArr?.length > 0 && (
                                                            <Button 
                                                                variant="outlined" 
                                                                size="small"
                                                                onClick={() => handleOpenDefectsModal(product)}
                                                            >
                                                                View Defects
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Stack>
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </TimeSlotCard>
                    ))}
                </Box>

                {/* Production Summary Section */}
                <SummaryCard>
                    <Typography variant="h6" sx={{ mb: 3 }}>
                        Production Summary
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Total Production
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 1, color: 'primary.main' }}>
                                    {shiftData.totalProduction || 0}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    OK Quantity
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 1, color: 'success.main' }}>
                                    {shiftData.okQuantity || 0}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Rejected Percentage
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 1, color: 'error.main' }}>
                                    {shiftData.rejectedPercentage || 0}%
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box>
                                <Typography variant="body2" color="text.secondary">
                                    Lumps
                                </Typography>
                                <Typography variant="h5" sx={{ mt: 1, color: 'warning.main' }}>
                                    {shiftData.lumps || 0}
                                </Typography>
                            </Box>
                        </Grid>
                        {shiftData?.reasonAndBreakDownTime?.length > 0 && (
                            <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                    <Typography variant="body2" color="text.secondary">
                                        Total Breakdown Time
                                    </Typography>
                                    <Typography variant="h5" sx={{ mt: 1, color: 'error.main' }}>
                                        {calculateTotalBreakdown()} mins
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </SummaryCard>
            </SimpleCard>

            {/* Defects Modal */}
            <Modal
                open={openDefectsModal}
                onClose={handleCloseDefectsModal}
                aria-labelledby="defects-modal-title"
            >
                <ModalStyle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Typography id="defects-modal-title" variant="h6" component="h2">
                            Defects Details - {selectedProduct?.Product_Name}
                        </Typography>
                        <IconButton onClick={handleCloseDefectsModal} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Defect Name</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedProduct?.defectArr?.map((defect, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{defect.name}</TableCell>
                                        <TableCell align="right">{defect.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ModalStyle>
            </Modal>
        </Container>
    )
}
