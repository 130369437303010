import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'

const url = `${API_URL}/mould`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addMould = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllMould = () => {
    return axios.get(`${url}/`)
}

export const getMouldById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const UpdateMouldById = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const deleteMould = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getMouldByProduct = (productArr) => {
    console.log(productArr, "productArr");
    
    const productIds = productArr.map((el) => el.value);  

    return axios.get(`${url}/getDataByProduct/`, { 
        params: { productArr: JSON.stringify(productIds) } // Send only IDs
    });
};
