import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { styled, width } from '@mui/system'
import { SimpleCard } from 'app/components'
import {
    deleteMachineData,
    getAllMachineData,
} from 'app/redux/actions/Machines/machine.action'
import {
    deleteMouldData,
    getAllMouldData,
} from 'app/redux/actions/Mould/mould.action'
import { getAllAssemblyProduct } from 'app/services/AssemblyProducts.service'
import { deleteMachine, getAllMachine } from 'app/services/machine.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'
import { deleteAssemblyProduct } from 'app/services/AssemblyProducts.service'

export default function ViewAssemblyProduct() {
    let counter = 1
    const [productArr, setProductArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // console.log(mouldArr, "asd")
    // const [machineArr, setMachineArr] = useState([])

    useEffect(() => {
        getAllMoulding()
    }, [])

    const getAllMoulding = async () => {
        try {
            let { data: res } = await getAllAssemblyProduct()
            if (res) {
                console.log(res.data)
                setProductArr(res?.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleUpdate = async (id) => {
        try {
            // navigate(`/admin/updatemould/${id}`)
            navigate(`/admin/addassemblyProduct?id=${id}`)
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (
                window.confirm('Are you sure you want to delete this machine?')
            ) {
                // dispatch(deleteMouldData(id))
                let { data: res } = await deleteAssemblyProduct(id)
                if (res) {
                    toastSuccess(res.message)
                }
            }
        } catch (error) {
            toastError(error)
        }
        getAllMoulding()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Assembly Products'}>
                <ContentTable
                    data={productArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            sortable: true,
                            button: true,
                        },
                        {
                            name: 'Product Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                            width: '500px',
                        },
                        {
                            name: 'Assembly Number',
                            selector: (row) => row?.assemblyNo ? row?.assemblyNo : 'NA',
                            searchable: true,
                            sortable: true,
                            width: '500px',
                        },
                        {
                            name: 'Update',
                            selector: (row) => (
                                <Button variant="contained" color="primary" onClick={() => handleUpdate(row._id)}>
                                    Update
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
