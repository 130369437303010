import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { acceptRawMaterial, getRawMaterialRequest, rejectRawMaterial } from 'app/services/rawMaterial.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import moment from 'moment'
import ContentTable from 'app/components/Table/ContentTable'

export default function StockIssueRequest() {
    let counter = 1
    const [rawMaterialRequestArr, setRawMaterialRequestArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [scanInput, setScanInput] = useState('')
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const [selectedRequestObj, setSelectedRequestObj] = useState(null)
    const handleClose = () => setOpen(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid rgba(0,0,0,0.1)',
        boxShadow: 24,
        p: 4,
        width: '50%',
    }

    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = async () => {
        try {
            let { data: res } = await getRawMaterialRequest()
            if (res) {
                console.log(res.data)
                setRawMaterialRequestArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Store?')) {
            }
        } catch (error) {
            toastError(error)
        }
        getRequests()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const handleSubmit = async (id) => {
        try {
            navigate(`/RawMaterialIssue?id=${id}`)
            // setOpen(false)
            // let obj = {
            //     id: selectedRequestObj._id
            // }
            // const { data: res } = await acceptRawMaterial(obj);
            // if (res) {
            //     toastSuccess(res.message)
            //     getRequests()
            // }
        } catch (error) {
            toastError(error)
        }
    }

    const handleReject = async (row) => {
        try {
            let obj = {
                id: row._id,
            }
            const { data: res } = await rejectRawMaterial(obj)
            if (res) {
                toastSuccess(res.message)
                getRequests()
            }
        } catch (error) {
            toastError(error)
        }
    }
    const handleInputScan = (e) => {
        if (selectedRequestObj?.rawMaterialId == e) {
            setScanInput(e)
        } else {
            alert('Invalid Raw Material')
        }
    }

    return (
        <Container>
            <SimpleCard title={'View Requests'}>
                <ContentTable
                    data={rawMaterialRequestArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            sortable: true,
                            button: true,
                        },
                        {
                            name: 'Raw Material',
                            selector: (row) => row?.rawMaterialName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Quantity',
                            selector: (row) => row?.requestQuantity,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Unit',
                            selector: (row) => `${row?.quantityPer} ${row?.units}`,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Shift',
                            selector: (row) => row?.shiftData?.shift,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Operator',
                            selector: (row) => row?.shiftData?.operatorName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Status',
                            selector: (row) => row?.status,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Requested At',
                            selector: (row) => row?.createdAt,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Approved At',
                            selector: (row) => 
                                row?.status === 'Approved'
                                    ? moment(row?.updatedAt).format('DD-MM-YYYY h:mm')
                                    : 'Not Approved',
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Actions',
                            selector: (row) => (
                                <div>
                                    <Button
                                        disabled={row?.status != 'Requested'}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            handleSubmit(row?.rawMaterialId)
                                        }}
                                    >
                                        Action
                                    </Button>
                                </div>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        }
                    ]}
                />
            </SimpleCard>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Scan
                    </Typography>
                    <TextField type="text" name="name" onChange={(e) => handleInputScan(e.target.value)} value={scanInput} label={`Scan`} sx={{ width: '100%', mt: 2 }} />
                    <Button variant="outlined" color="error" sx={{ mt: 2 }} onClick={() => handleSubmit()}>
                        Issue
                    </Button>
                </Box>
            </Modal>
        </Container>
    )
}
