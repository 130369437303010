import { Button, FormControlLabel, Radio, RadioGroup, TextField, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addDefects, getDefectsById, updateDefectById } from 'app/services/Defect.service'
import { DEFECT_CATEGORY } from 'app/utils/constant'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAllDefectSubCategory } from 'app/services/defectSubCategory.service'


const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddDefects() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [defectCategory, setDefectCategory] = useState('')
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('')
    const [subCategoryArr, setSubCategoryArr] = useState([])

    useEffect(() => {
        if (id) {
            //if there is an id in the url then fetch the defect details and update
            getDefectData(id)
        }
    }, [id])

    //getting the defect data based on id and pre-fill the form for update
    const getDefectData = async (id) => {
        try {
            const { data: res } = await getDefectsById(id)
            if (res) {
                setName(res.data.name || '')
                setDescription(res.data.description || '')
                setSelectedSubCategoryId(res.data.defectSubCategory || '')
                setDefectCategory(res.data.defectCategory || '')
            }
        } catch (err) {
            console.error('Error fetching defect:', err)
            toastError(err)
        }
    }

    const getAllDefectSubCategoryArr = async () => {
        try {
            const { data: res } = await getAllDefectSubCategory()
            if (res) {
                setSubCategoryArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getAllDefectSubCategoryArr()
    }, [])


    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
                defectCategory,
                defectSubCategory: selectedSubCategoryId,
            }
            if (id) {
                //if there is an id update the defect
                console.log('Updating defect with ID:', id, 'Payload:', obj)
                const { data: res } = await updateDefectById(id, obj)
                toastSuccess(res.message)
            } else {
                const { data: res } = await addDefects(obj)
                if (res) {
                    toastSuccess(res.message)
                }
            }
        } catch (error) {
            console.error('Error submitting defect:', error)
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title={id ? 'Update Defect' : 'Add Defects'}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name} label="Name" sx={{ width: '100%', mt: 2 }} />

                    <TextField type="text" name="Description" onChange={(e) => setDescription(e.target.value)} value={description} label="Description" sx={{ width: '100%', mt: 2 }} />
                    <RadioGroup sx={{ mb: 2 }} value={defectCategory} name="Role" onChange={(e) => setDefectCategory(e.target.value)} row>
                        <FormControlLabel value={DEFECT_CATEGORY.Moulding} control={<Radio color="primary" />} label={DEFECT_CATEGORY.Moulding} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.Plating} control={<Radio color="primary" />} label={DEFECT_CATEGORY.Plating} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.Assembly} control={<Radio color="primary" />} label={DEFECT_CATEGORY.Assembly} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.QC} control={<Radio color="primary" />} label={DEFECT_CATEGORY.QC} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.MRN} control={<Radio color="primary" />} label={DEFECT_CATEGORY.MRN} labelPlacement="end" />
                    </RadioGroup>

                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedSubCategoryId} label="Moulding Category" onChange={(e) => setSelectedSubCategoryId(e.target.value)}>
                                {subCategoryArr?.map((el) => (
                                    <MenuItem value={el._id} key={el._id}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
