import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/scrap-data-note`

export const addScrapData = (obj) => {
     return axios.post(`${url}/`, obj)
}

export const getAllScrapData = () => {
     return axios.get(`${url}/`)
}