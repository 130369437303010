import { Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
// import AutocompleteCombo from './AutocompleteCombo'
// import AsyncAutocomplete from './AsyncAutocomplete'
// import BadgeAutocomplete from './BadgeAutocomplete'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllCategoryData } from 'app/redux/actions/MouldingCategories/mouldingCategory.action'
import { getRawMaterialById, updateRawMaterial } from 'app/services/rawMaterial.service'
import { getAllRawMaterialSubCategory } from 'app/services/RawMaterialSubCategory.service'
import { CATEGORY, UNITS } from 'app/utils/constant'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function UpdateRawMaterial() {
    const dispatch = useDispatch()
    const params = useParams()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [mouldingCategoryId, setMouldingCategoryId] = useState('')
    const [category, setCategory] = useState(CATEGORY.ASSEMBLY)
    const [units, setUnits] = useState(UNITS.KG_BAG)
    const [quantityPer, setQuantityPer] = useState(1)
    const [partNo, setPartNo] = useState('')
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('')
    const [preHeatingTemp, setPreHeatingTemp] = useState('')

    const [minimumQuantity, setMinimumQuantity] = useState('0')
    const [maxQuantity, setMaxQuantity] = useState('0')
    const [reorderQuantity, setReorderQuantity] = useState('0')
    const [rate, setRate] = useState('0')
    const categoryArr = useSelector((state) => state.mouldingCategories.categoryArr)

    const [subCategoryArr, setSubCategoryArr] = useState([])

    const getAllRawMaterialSubCategoryArr = async () => {
        try {
            const { data: res } = await getAllRawMaterialSubCategory()
            if (res) {
                setSubCategoryArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getCategories()
        getAllRawMaterialSubCategoryArr()
        handleGetRawMaterialById()
    }, [])

    const getCategories = async () => {
        try {
            dispatch(getAllCategoryData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetRawMaterialById = async () => {
        try {
            let { data: res } = await getRawMaterialById(params.id)
            console.log(res, 'raw material Data')
            if (res.data) {
                setName(res.data.name)
                setDescription(res.data.description)
                setCategory(res.data.category)
                setPartNo(res?.data?.partNo)
                setRate(res?.data?.rate)
                setMaxQuantity(res?.data?.maxQuantity)
                setPreHeatingTemp(res?.data?.preHeatingTemp)
                setMinimumQuantity(res?.data?.minimumQuantity)
                setReorderQuantity(res?.data?.reorderQuantity)
                if (res.data.category == CATEGORY.MOULDING) {
                    setMouldingCategoryId(res.data.mouldingCategoryId)
                }
                setSelectedSubCategoryId(res?.data?.subcategoryId)
                setUnits(res.data.units)
                if (res.data.quantityPer) setQuantityPer(res.data.quantityPer)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
                category,
                units,
                partNo,
                subCategoryId: selectedSubCategoryId,
                mouldingCategoryId,
                preHeatingTemp,
                quantityPer,
                rate,
                reorderQuantity,
                maxQuantity,
                minimumQuantity,
            }
            let { data: res } = await updateRawMaterial(params.id, obj)
            if (res.success) {
                alert(res.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const getQuantityPer = () => {
        if (units == UNITS.KG_BAG) {
            return 'KG Per Bag'
        } else if (units == UNITS.KG_CAN) {
            return 'KG Per Can'
        } else if (units == UNITS.LITERS_CAN) {
            return 'Lt Per Can'
        } else if (units == UNITS.UNIT) {
            return 'Quanity Per Unit'
        }
    }

    return (
        <Container>
            <SimpleCard title="Update Raw Material">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />

                    <TextField type="text" name="name" onChange={(e) => setDescription(e.target.value)} value={description || ''} label="Description" sx={{ width: '100%', mt: 2 }} />
                    <TextField type="text" name="name" onChange={(e) => setPartNo(e.target.value)} value={partNo || ''} label="Part No" sx={{ width: '100%', mt: 2 }} />

                    <Box>
                        <FormControl sx={{ mt: 3 }}>
                            <FormLabel id="demo-radio-buttons-group-label">Category</FormLabel>
                            <RadioGroup sx={{ mb: 2 }} value={category || ''} name="Category" onChange={(e) => setCategory(e.target.value)} row>
                                <FormControlLabel value={CATEGORY.ASSEMBLY} control={<Radio color="primary" />} label="Assembly" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.MOULDING} control={<Radio color="primary" />} label="Moulding" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.PLATING} control={<Radio color="primary" />} label="Plating" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.LAB} control={<Radio color="primary" />} label="LAB" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.MAINTAINANCE} control={<Radio color="primary" />} label="Maintainance" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.STAIONARY} control={<Radio color="primary" />} label="Stationary" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.MISC} control={<Radio color="primary" />} label="Misc." labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.TOOLS} control={<Radio color="primary" />} label="Tools." labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.BOP} control={<Radio color="primary" />} label="BOP" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.Packaging} control={<Radio color="primary" />} label="Packaging" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.Jigs} control={<Radio color="primary" />} label="Jigs" labelPlacement="end" />
                                <FormControlLabel value={CATEGORY.Consumable} control={<Radio color="primary" />} label="Consumable" labelPlacement="end" />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {category == CATEGORY.MOULDING && (
                        <Box sx={{ mt: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Moulding Category</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={mouldingCategoryId} label="Moulding Category" onChange={(e) => setMouldingCategoryId(e.target.value)}>
                                    {categoryArr?.map((el) => (
                                        <MenuItem value={el._id} key={el._id}>
                                            {el.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}

                    <Box>
                        <FormControl sx={{ mt: 3 }}>
                            <FormLabel id="demo-radio-buttons-group-label">Units</FormLabel>
                            <RadioGroup sx={{ mb: 2 }} value={units || ''} name="Category" onChange={(e) => setUnits(e.target.value)} row>
                                <FormControlLabel value={UNITS.KG_BAG} control={<Radio color="primary" />} label="Kg/Bag" labelPlacement="end" />
                                <FormControlLabel value={UNITS.KG_CAN} control={<Radio color="primary" />} label="Kg/Can" labelPlacement="end" />
                                <FormControlLabel value={UNITS.LITERS_CAN} control={<Radio color="primary" />} label="Lt/Can" labelPlacement="end" />
                                <FormControlLabel value={UNITS.UNIT} control={<Radio color="primary" />} label="Unit" labelPlacement="end" />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {units != UNITS.UNIT && <TextField type="number" name="quantityPer" onChange={(e) => setQuantityPer(e.target.value)} value={quantityPer} label={`${getQuantityPer()}`} sx={{ width: '100%', mt: 2 }} />}

                    <TextField type="number" name="minimum Quantity" onChange={(e) => setMinimumQuantity(e.target.value)} value={minimumQuantity} label={'Minimum Quantity'} sx={{ width: '100%', mt: 2 }} />
                    <TextField type="number" name="maximum Qty" onChange={(e) => setMaxQuantity(e.target.value)} value={maxQuantity} label={'Maximum Quantity'} sx={{ width: '100%', mt: 2 }} />
                    <TextField type="number" name="Rate" onChange={(e) => setRate(e.target.value)} value={rate} label={'Rate'} sx={{ width: '100%', mt: 2 }} />
                    <TextField type="number" name="Reorder Qty" onChange={(e) => setReorderQuantity(e.target.value)} value={reorderQuantity} label={'Reorder Quantity'} sx={{ width: '100%', mt: 2 }} />
                    <TextField type="number" name="Pre Heating Temp" onChange={(e) => setPreHeatingTemp(e.target.value)} value={preHeatingTemp} label={'Pre Heating Temperature'} sx={{ width: '100%', mt: 2 }} />

                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedSubCategoryId} label="Moulding Category" onChange={(e) => setSelectedSubCategoryId(e.target.value)}>
                                {subCategoryArr?.map((el) => (
                                    <MenuItem value={el._id} key={el._id}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
