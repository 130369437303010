import React, { useEffect, useState } from 'react'
import { Breadcrumb, SimpleCard } from 'app/components'
// import AutocompleteCombo from './AutocompleteCombo'
// import AsyncAutocomplete from './AsyncAutocomplete'
// import BadgeAutocomplete from './BadgeAutocomplete'
import { Box, styled } from '@mui/system'
import { Grid, TextField, Button, Select as MuiSelect, FormControl, MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, FormLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { USERS, TYPE_OF_MACHINES } from 'app/utils/constant'
import { addUser } from 'app/services/user.service'
import { toast } from 'react-toastify'
import { addCategory } from 'app/services/category.service'
import { addMachine, getMachineById, updateMachine } from 'app/services/machine.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { getAllProduct } from 'app/services/product.service'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import { addMachineData } from 'app/redux/actions/Machines/machine.action'
import { useParams } from 'react-router-dom'
import { getAllMouldData } from 'app/redux/actions/Mould/mould.action'
import Select from 'react-select'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const UpdateMachine = () => {
    const mainproductArr = useSelector(state => state.product.productArr);
    const mainMouldArr = useSelector((state) => state.mould.mouldArr)
    const dispatch = useDispatch()
    const params = useParams()
    
    const [displayMouldArr, setDisplayMouldArr] = useState([])
    const [selectedMouldArr, setSelectedMouldArr] = useState([])
    const [name, setName] = useState('')
    const [loadingCapacity, setLoadingCapacity] = useState(1)
    const [productArr, setProductArr] = useState([])
    const [selectedProductArr, setSelectedProductArr] = useState([])
    const [assemblyBool, setAssemblyBool] = useState(false)
    const [machineType, setMachineType] = useState(TYPE_OF_MACHINES.PRODUCTION);

    // Added fields from AddMachine.js
    const [description, setDescription] = useState("");
    const [capacityOrSpec, setCapacityOrSpec] = useState('');
    const [identificationNo, setIdentificationNo] = useState('');
    const [make, setMake] = useState('');
    const [location, setLocation] = useState('');
    const [installationOn, setInstallationOn] = useState('');
    const [remarks, setRemarks] = useState("")
    
    // Loading state for mould data
    const [isLoading, setIsLoading] = useState(false)
    const [machineData, setMachineData] = useState(null)

    useEffect(() => {
        handleGet()
    }, [])
    
    useEffect(() => {
        if (mainproductArr && mainproductArr.length > 0)
            setProductArr(
                mainproductArr?.map((el) => ({
                    ...el,
                    label: el.name,
                    value: el._id,
                }))
            )
    }, [mainproductArr])

    useEffect(() => {
        if (mainMouldArr && mainMouldArr.length > 0) {
            const formattedMoulds = mainMouldArr.map((el) => ({
                ...el,
                label: el.name,
                value: el._id,
            }))
            setDisplayMouldArr(formattedMoulds)
            
            // If we already have machine data, match the moulds
            if (machineData && machineData.mouldArr && machineData.mouldArr.length > 0) {
                matchMouldsWithMachineData(formattedMoulds, machineData);
            }
        }
    }, [mainMouldArr, machineData])

    const matchMouldsWithMachineData = (formattedMoulds, machineData) => {
        // Find the moulds from the mainMouldArr that match the IDs in the machine's mouldArr
        const selectedMoulds = machineData.mouldArr.map(machineMould => {
            // Find the matching mould from the formatted moulds
            const matchingMould = formattedMoulds.find(mould => 
                mould._id === (machineMould.mouldId || machineMould._id) || 
                mould.value === (machineMould.mouldId || machineMould._id)
            );
            
            if (matchingMould) {
                return {
                    ...matchingMould,
                    productArr: machineMould.productArr || [],
                    mouldId: machineMould.mouldId || machineMould._id || matchingMould._id
                };
            }
            
            // If no match is found in the formatted moulds, return the original with label/value
            return {
                ...machineMould,
                label: machineMould.name,
                value: machineMould.mouldId || machineMould._id
            };
        }).filter(mould => mould); // Filter out any undefined values
        
        console.log("Selected moulds:", selectedMoulds);
        setSelectedMouldArr(selectedMoulds);
    }

    const handleGet = () => {
        setIsLoading(true)
        dispatch(getAllMouldData())
        dispatch(getAllProductData())
        handleGetMachineData()
    }

    const handleSelection = (selected) => {
        if (!selected) {
            setSelectedMouldArr([])
            return
        }
        
        // Map the selected values from react-select to our expected format
        const tempArr = selected.map((el) => ({
            ...el,
            mouldId: el.value,
            label: el.label,
            value: el.value,
            productArr: el.productArr || []
        }))
        
        setSelectedMouldArr(tempArr)
    }

    const handleProductSelection = (selected) => {
        if (!selected) {
            setSelectedProductArr([])
            return
        }
        
        const tempArr = selected.map((el) => ({
            ...el,
            productId: el.value,
            label: el.label,
            value: el.value,
        }))
        
        setSelectedProductArr(tempArr)
    }

    const handleGetMachineData = async () => {
        try {
            setIsLoading(true)
            let { data: res } = await getMachineById(params.id)
            if (res.data) {
                console.log(res.data, "machine Ress")
                setMachineData(res.data); // Store the machine data for use in other effects
                
                setName(res.data.name)
                setLoadingCapacity(res.data.loadingCapacity)
                
                // Set values for additional fields
                setDescription(res.data.description || "")
                setCapacityOrSpec(res.data.capacityOrSpec || "")
                setIdentificationNo(res.data.identificationNo || "")
                setMake(res.data.make || "")
                setLocation(res.data.location || "")
                setInstallationOn(res.data.InstallationOn || "")
                setRemarks(res.data.remarks || "")
                setMachineType(res.data.machineType || TYPE_OF_MACHINES.PRODUCTION)
                
                // Handle assembly bool
                if (res.data.mouldArr && res.data.mouldArr.length > 0) {
                    setAssemblyBool(false)
                    
                    // If we already have mainMouldArr, match the moulds now
                    if (mainMouldArr && mainMouldArr.length > 0) {
                        const formattedMoulds = mainMouldArr.map((el) => ({
                            ...el,
                            label: el.name,
                            value: el._id,
                        }));
                        matchMouldsWithMachineData(formattedMoulds, res.data);
                    }
                    // Otherwise, it will be handled in the useEffect
                } else if (res.data.productIdArr && res.data.productIdArr.length > 0) {
                    setAssemblyBool(true)
                    
                    // If we have products but no selected products yet, try to match them
                    if (productArr.length > 0) {
                        const selectedProducts = res.data.productIdArr
                            .map(product => {
                                const matchedProduct = productArr.find(p => p._id === product.productId || p.value === product.productId)
                                return matchedProduct ? {
                                    ...matchedProduct,
                                    label: matchedProduct.name || matchedProduct.label,
                                    value: matchedProduct._id || matchedProduct.value
                                } : null
                            })
                            .filter(p => p !== null)
                        
                        setSelectedProductArr(selectedProducts)
                    }
                }
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message)
                alert(error?.response?.data?.message)
            else
                alert(error?.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async () => {
        try {
            let uniqueProductArr = []
            if (assemblyBool) {
                // Handle product array if in assembly mode
                for (let product of selectedProductArr) {
                    uniqueProductArr.push({ productId: product._id || product.value })
                }
            } else {
                // Handle mould array
                for (let el of selectedMouldArr) {
                    for (let elx of el.productArr || []) {
                        if (!uniqueProductArr.some((elz) => elz.productId == elx.productId)) {
                            uniqueProductArr.push({ productId: elx.productId })
                        }
                    }
                }
            }
            
            let obj = {
                name,
                description,
                capacityOrSpec,
                location,
                make,
                InstallationOn: installationOn,
                remarks,
                identificationNo,
                loadingCapacity: loadingCapacity,
                productIdArr: uniqueProductArr,
                mouldArr: selectedMouldArr.map(mould => ({
                    ...mould,
                    mouldId: mould.mouldId || mould._id || mould.value
                })),
                machineType
            }

            let { data: res } = await updateMachine(params.id, obj)
            if (res.success) {
                alert(res.message)
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message)
                alert(error?.response?.data?.message)
            else
                alert(error?.message)
        }
    }

    return (
        <Container>
            <SimpleCard title="Update Machines">
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <TextField
                        type="text"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    <TextField
                        type="number"
                        name="capacity"
                        onChange={e => setLoadingCapacity(e.target.valueAsNumber)}
                        value={loadingCapacity || ''}
                        label="Loading Capacity"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    
                    <TextField
                        type="text"
                        name="Description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description || ''}
                        label="M/C Description"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <TextField
                        type="text"
                        name="capacityOrSpec"
                        onChange={(e) => setCapacityOrSpec(e.target.value)}
                        value={capacityOrSpec || ''}
                        label="CAPACITY/ SPEC."
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <TextField
                        type="text"
                        name="identificationNo"
                        onChange={(e) => setIdentificationNo(e.target.value)}
                        value={identificationNo || ''}
                        label="M/C IDENTIFICAT ION NO."
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <TextField
                        type="text"
                        name="make"
                        onChange={(e) => setMake(e.target.value)}
                        value={make || ''}
                        label="Make"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <TextField
                        type="text"
                        name="location"
                        onChange={(e) => setLocation(e.target.value)}
                        value={location || ''}
                        label="Location"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <TextField
                        type="text"
                        name="installationOn"
                        onChange={(e) => setInstallationOn(e.target.value)}
                        value={installationOn || ''}
                        label="INSTALLATION MONTH/ MODEL"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    
                    <TextField
                        type="text"
                        name="Remarks"
                        onChange={(e) => setRemarks(e.target.value)}
                        value={remarks || ''}
                        label="Remarks"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <FormGroup sx={{ mt: 3 }}>
                        <FormLabel>Machine Type</FormLabel>
                        <RadioGroup row value={machineType} onChange={(e) => setMachineType(e.target.value)}>
                            <FormControlLabel value={TYPE_OF_MACHINES.PRODUCTION} control={<Radio />} label={TYPE_OF_MACHINES.PRODUCTION} />
                            <FormControlLabel value={TYPE_OF_MACHINES.NON_PRODUCTION} control={<Radio />} label={TYPE_OF_MACHINES.NON_PRODUCTION} />
                        </RadioGroup>
                    </FormGroup>

                    {machineType == TYPE_OF_MACHINES.PRODUCTION ?
                        <>
                            <FormGroup sx={{ mt: 3 }}>
                                <FormLabel>Assembly ?</FormLabel>
                                <FormControlLabel label={'Assembly'} control={<Checkbox checked={assemblyBool ? true : false} onChange={(e) => setAssemblyBool(!assemblyBool)} />} />
                            </FormGroup>
                            {assemblyBool ? (
                                <>
                                    {productArr && productArr?.length > 0 && (
                                        <FormGroup sx={{ mt: 3 }}>
                                            <FormLabel>Products</FormLabel>
                                            <Select
                                                options={productArr}
                                                isMulti
                                                value={selectedProductArr}
                                                onChange={handleProductSelection}
                                                isLoading={isLoading}
                                                placeholder="Select products..."
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </FormGroup>
                                    )}
                                </>
                            ) : (
                                <>
                                    {displayMouldArr && displayMouldArr?.length > 0 && (
                                        <FormGroup sx={{ mt: 3 }}>
                                            <FormLabel>Moulds</FormLabel>
                                            <Select
                                                options={displayMouldArr}
                                                isMulti
                                                value={selectedMouldArr}
                                                onChange={handleSelection}
                                                isLoading={isLoading}
                                                placeholder="Select moulds..."
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </FormGroup>
                                    )}
                                </>
                            )}
                        </>
                        :
                        <>
                        </>
                    }

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Update
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}

export default UpdateMachine