import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/assembly-bom`

export const addAssemblyBOM = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllAssemblyBOM = () => {
    return axios.get(`${url}`)
}

export const getAssemblyBOMById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const updateAssemblyBOM = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const deleteAssemblyBOM = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}