import React, { useState, useEffect } from "react";
import { getSemiFinishedGood } from "app/services/sfgStock.service";
import { Button } from "@mui/material";
import ContentTable  from "app/components/Table/ContentTable";
import { Box, Typography } from "@mui/material";

export default function ViewSFGProduct() {

  const [semiFinishedGood, setSemiFinishedGood] = useState([])

  useEffect(() => { 
    const fetchData = async () => {
      try {
        const res = await getSemiFinishedGood("SFG")
        setSemiFinishedGood(res.data.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  console.log(semiFinishedGood, "semiFinishedGood")
  
  return (
    <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Semi Finished Goods
                </Typography>
            </Box>

            <ContentTable
                data={semiFinishedGood}
                columns={[
                    {
                        name: 'Product Name',
                        selector: (row) => row?.product.productName,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Stock',
                        selector: (row) => row?.currentStock,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Bin',
                        selector: (row) => row?.binName,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'rack',
                        selector: (row) => row?.rackName,
                        searchable: true,
                        sortable: true,
                    },
                    {
                        name: 'Store',
                        selector: (row) => row?.storeName,
                        searchable: true,
                        sortable: true,
                    },
                ]}
            />
        </>
  )
}