import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Card, CardContent, Divider, TableContainer, Paper } from '@mui/material'
import { bgcolor, styled } from '@mui/system'
import { getAllRawMaterialSubCategory } from 'app/services/RawMaterialSubCategory.service'
import { CATEGORY, UNITS } from 'app/utils/constant'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { getAllRawMaterial } from '../../../services/rawMaterial.service'
import { addAssemblyBOM, getAssemblyBOMById, updateAssemblyBOM } from 'app/services/AssemblyBOM.service'
import { getAllAssemblyProduct, getAssemblyProductBOM } from 'app/services/AssemblyProducts.service'
import { sub } from 'date-fns'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const FormContainer = styled('div')({
    '& .MuiTextField-root, & .MuiSelect-root': {
        marginBottom: '8px',
    },
    '& .form-label': {
        fontWeight: 'bold',
        marginBottom: '4px',
        color: '#000',
    },
    '& .form-row': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px',
        borderBottom: '1px solid #ccc',
    },
    '& .form-label-cell': {
        width: '200px',
        padding: '8px',
        backgroundColor: '#e6e6e6',
        borderRight: '1px solid #ccc',
    },
    '& .form-value-cell': {
        flex: 1,
        padding: '8px',
    },
    '& .table-container': {
        marginTop: '16px',
        border: '1px solid #ccc',
    },
    '& .table-header': {
        backgroundColor: '#e6e6e6',
        borderBottom: '1px solid #ccc',
        padding: '8px',
    },
})

export default function AddProductRecipe() {
    const navigate = useNavigate()

    const id = new URLSearchParams(useLocation().search).get('id') || ''

    const [product, setProduct] = useState([])
    const [productArr, setProductArr] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [mainRawMaterialArr, setMainRawMaterialArr] = useState([])
    const [selectedRawMaterials, setSelectedRawMaterials] = useState([])
    const [subCategoryRawMaterials, setSubCategoryRawMaterials] = useState([])
    const [productError, setProductError] = useState(false)

    const [selectGeneralRawMaterials, setSelectGeneralRawMaterials] = useState([])
    const [selectGeneralSubCategoryRawMaterials, setSelectGeneralSubCategoryRawMaterials] = useState([])

    const [assemblyProduct, setAssemblyProduct] = useState({})

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const recipeId = query.get('id')
    const [selectedProductDetails, setSelectedProductDetails] = useState(null)

    const [selectedStage, setSelectedStage] = useState({ label: CATEGORY.ASSEMBLY, value: CATEGORY.ASSEMBLY })
    const [subCategoryArr, setSubCategoryArr] = useState([])
    const unitArr = [
        { label: 'Kg', value: 'kg' },
        { label: 'gm', value: 'gm' },
        { label: 'Pcs', value: 'pcs' },
        { label: 'ML', value: 'ml' },
        { label: 'L', value: 'l' },
        { label: 'MM', value: 'mm' },
        { label: 'CM', value: 'cm' },
        { label: 'M', value: 'm' },
        { label: 'FT', value: 'ft' },
    ]


    console.log(subCategoryArr, 'subCategoryArr')

    

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                let products = []

                const { data: res } = await getAllAssemblyProduct()
                products = res?.data || []

                setProduct(products)

                if (products.length) {
                    const tempArr = products.map((el) => ({
                        label: el.name,
                        value: el._id,
                        description: el.description,
                        assemblyNo: el.assemblyNo,
                    }))
                    setProductArr(tempArr)
                }
            } catch (error) {
                toastError(error)
            }
        }

        fetchProducts()
    }, [selectedStage?.value])

    useEffect(() => {
        const fetchRawMaterials = async () => {
            try {
                let rawMaterials = []
                
                    let { data: res } = await getAllRawMaterial()
                    rawMaterials = res.data

                if (rawMaterials) {
                    let tempArr = rawMaterials?.map((el) => ({
                        label: el.name,
                        value: el._id,
                        unit: el.units,
                        quantityPer: el.quantityPer,
                        subCategoryId: el.subCategoryId,
                    }))
                    setRawMaterialArr(tempArr)
                    setMainRawMaterialArr(tempArr)
                }
            } catch (error) {
                toastError(error)
            }
        }
        fetchRawMaterials()
    }, [selectedStage])

    

    useEffect(() => {
        getAllRawMaterialSubCategoryArr()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (id !== '') {
                try {
                    const { data: res } = await getAssemblyBOMById(id)
                    console.log('Raw API Response:', res)

                    // Check if res is an array and has data
                    if (Array.isArray(res.data) && res.data.length > 0) {
                        setSelectedProduct({ label: res.data[0].productName, value: id })
                        setSelectedProductDetails({
                            label: res.data[0].productName,
                            value: res.data[0].productId,
                            assemblyNo: res.data[0].assemblyNo,
                        })
                        setSelectedRawMaterials(
                            res.data[0].productsArr.map((product) =>
                                (product.rawMaterialArr || []).map((item) => ({
                                    material: { label: item.rawMaterialName, value: item.rawMaterialId },
                                    quantity: item.rawMaterialQty.toString(), // Convert to string for TextField
                                    unit: { label: item.unit, value: item.unit },
                                }))
                            )
                        )
                        setSubCategoryRawMaterials(
                            res.data[0].productsArr.map((product) =>
                                (product.rawMaterialSubCategoryArr || []).map((item) => ({
                                    subCategory: { label: item.subCategoryName, value: item.subCategoryId },
                                    material: { label: item.rawMaterialName, value: item.rawMaterialId },
                                    quantity: item.subCategoryQty.toString(), // Convert to string for TextField
                                    unit: { label: item.unit, value: item.unit },
                                }))
                            )
                        )
                        //setSubCategoryRawMaterials(res.data[0].productsArr.map((product) => product.rawMaterialSubCategoryArr || []))
                        setAssemblyProduct(res.data)
                        setSelectGeneralRawMaterials(
                            res.data[0].generalRawMaterialArr.map((item) => ({
                                ...item,
                                material: { label: item.rawMaterialName, value: item.rawMaterialId },
                                quantity: item.rawMaterialQty.toString(), // Convert to string if needed
                                unit: { label: item.unit, value: item.unit },
                            }))
                        )
                        setSelectGeneralSubCategoryRawMaterials(
                            res.data[0].generalRawMaterialSubCategoryArr.map((item) => ({
                                ...item,
                                subCategory: { label: item.subCategoryName, value: item.subCategoryId },
                                material: { label: item.rawMaterialName, value: item.rawMaterialId },
                                quantity: item.subCategoryQty.toString(), // Convert to string if needed
                                unit: { label: item.unit, value: item.unit },
                            })) || []
                        )
                    } else {
                        console.warn('No data received or invalid structure:', res)
                        setAssemblyProduct([])
                    }
                } catch (error) {
                    toastError(error)
                }
            } else {
                try {
                    if (selectedProduct?.value) {
                        const { data: res } = await getAssemblyProductBOM(selectedProduct?.value)
                        if (res) {
                            setAssemblyProduct(res.data)
                        }
                    }
                } catch (error) {
                    toastError(error)
                }
            }
        }

        fetchData()
    }, [selectedProduct?.value, id])

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption)

        let tempProductIndex = productArr.findIndex((elx) => elx.value == selectedOption?.value)
        if (tempProductIndex != -1) {
            setSelectedProductDetails(productArr[tempProductIndex])
        }
        setProductError(false)
    }

    const getAllRawMaterialSubCategoryArr = async () => {
        try {
            const { data: res } = await getAllRawMaterialSubCategory()
            if (res) {
                setSubCategoryArr(res.data)
                // console.log(res.data, 'res.data')
            }
        } catch (error) {
            toastError(error)
        }
    }

    const addRawMaterial = (bomIndex, productId) => {
        setSelectedRawMaterials((prev) => ({
            ...prev,
            [bomIndex]: [...(prev[bomIndex] || []), { productId, material: null, quantity: '', unit: null }],
        }))
    }

    const addSubCategoryMaterial = (bomIndex, productId) => {
        setSubCategoryRawMaterials((prev) => ({
            ...prev,
            [bomIndex]: [...(prev[bomIndex] || []), { productId, subCategory: null, material: null, quantity: '', unit: null }],
        }))
    }

    const updateRawMaterial = (bomIndex, index, field, value) => {
        setSelectedRawMaterials((prev) => {
            const newMaterials = [...(prev[bomIndex] || [])]
            newMaterials[index] = { ...newMaterials[index], [field]: value }
            return { ...prev, [bomIndex]: newMaterials }
        })
    }

    const updateSubCategoryMaterial = (bomIndex, index, field, value) => {
        setSubCategoryRawMaterials((prev) => {
            const newMaterials = [...(prev[bomIndex] || [])]
            newMaterials[index] = { ...newMaterials[index], [field]: value }
            return { ...prev, [bomIndex]: newMaterials }
        })
    }

    const handleSubmit = async () => {
        try {
            // Construct the object according to the schema
            let obj = {
                productId: assemblyProduct[0]?._id || '',
                productName: assemblyProduct[0]?.name || '',
                assemblyNo: assemblyProduct[0]?.assemblyNo || '',
                category: 'ASSEMBLY',
                inputProductArr:
                    assemblyProduct[0]?.productsArr.map((bomItem, bomIndex) => ({
                        inputProductId: bomItem.productId,
                        inputProductName: bomItem.productName,
                        mouldingBOMId: bomItem.mouldingBOMId || null,
                        platingBOMId: bomItem.platingBOMId || null,
                        rawMaterialArr: (selectedRawMaterials[bomIndex] || []).map((item) => ({
                            rawMaterialId: item.material?.value,
                            rawMaterialName: item.material?.label,
                            rawMaterialQty: parseFloat(item.quantity) || 0,
                            unit: item.unit?.value,
                        })),
                        rawMaterialSubCategoryArr: (subCategoryRawMaterials[bomIndex] || []).map((item) => ({
                            subCategoryId: item.subCategory?.value,
                            subCategoryName: item.subCategory?.label,
                            rawMaterialId: item.material?.value,
                            rawMaterialName: item.material?.label,
                            subCategoryQty: parseFloat(item.quantity) || 0,
                            unit: item.unit?.value,
                        })),
                    })) || [],
                generalRawMaterialArr: (selectGeneralRawMaterials || []).map((item) => ({
                    rawMaterialId: item.material?.value,
                    rawMaterialName: item.material?.label,
                    rawMaterialQty: parseFloat(item.quantity) || 0,
                    unit: item.unit?.value,
                })),
                generalRawMaterialSubCategoryArr: (selectGeneralSubCategoryRawMaterials || []).map((item) => ({
                    subCategoryId: item.subCategory?.value,
                    subCategoryName: item.subCategory?.label,
                    rawMaterialId: item.material?.value,
                    rawMaterialName: item.material?.label,
                    subCategoryQty: parseFloat(item.quantity) || 0,
                    unit: item.unit?.value,
                })),
            }

            console.log(obj, 'id')

            if (id) {
                const response = await updateAssemblyBOM(id, obj)
                toastSuccess('Assembly product updated successfully!')
            } else {
                const response = await addAssemblyBOM(obj)
                toastSuccess('Assembly product submitted successfully!')
            }

            navigate('/admin/ProductRecipe/View')
        } catch (error) {
            toastError(error)
        }
    }

    const getFilteredRawMaterials = (subCategoryId) => {
        if (!subCategoryId) return []

        return mainRawMaterialArr.filter((material) => material.subCategoryId === subCategoryId)
    }


    return (
        <>
            <Container>
                <Box
                    sx={{
                        bgcolor: '#f3f4f6',
                        p: 1,
                        textAlign: 'center',
                        mb: 1,
                        borderRadius: '4px 4px 0 0',
                    }}
                >
                    <Typography variant="h5" component="h1" fontWeight="bold">
                        BILL OF MATERIAL
                    </Typography>
                </Box>
                <FormContainer>
                    <Card className="max-w-md mx-auto">
                        <CardContent className="pt-6">
                            <div className="space-y-6">
                                <div className="form-row border border-none">
                                    <div className="form-label-cell">CATEGORY:</div>
                                    <div className="form-value-cell">
                                        <Select
                                            value={selectedStage}
                                            options={[{ label: CATEGORY.ASSEMBLY, value: CATEGORY.ASSEMBLY }]}
                                            placeholder="Select Category"
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: productError ? 'red' : base.borderColor,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Part Info */}
                                <div className="form-row">
                                    <div className="form-label-cell">PART NAME:</div>
                                    <div className="form-value-cell">
                                        <Select
                                            value={selectedProduct}
                                            options={productArr}
                                            placeholder="Select Product"
                                            onChange={handleProductChange}
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    borderColor: productError ? 'red' : base.borderColor,
                                                }),
                                                container: (base) => ({
                                                    ...base,
                                                    width: '100%',
                                                }),
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                            menuPortalTarget={document.body}
                                        />

                                        {productError && <Typography color="error">Please select a product</Typography>}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-label-cell">ASSEMBLY NO.</div>
                                    <div className="form-value-cell">
                                        <TextField fullWidth variant="outlined" size="small" value={selectedProductDetails?.assemblyNo || ''} disabled />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    <Divider sx={{ my: 2 }} />

                    <Box sx={{ marginBottom: 2 }}>
                        <Box sx={{ marginY: 4, width: '100%', display: 'grid', placeItems: 'start', gridTemplateColumns: 'repeat(1, 1fr)', gap: 2 }}>
                            {assemblyProduct[0]?.productsArr?.map((bomItem, bomIndex) => (
                                <>
                                    {bomItem.boms.length > 0 && (
                                        <Box key={bomIndex} sx={{ bgcolor: '#FAF1E6', p: 1, borderRadius: 4, width: '100%', display: 'grid', placeItems: 'start', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, padding: 2 }}>
                                            {bomItem?.boms?.map((mouldBOM, index) => (
                                                <>
                                                    {mouldBOM.productName && index === 0 && (
                                                        <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center', gridColumn: '1 / -1', textTransform: 'uppercase' }} gutterBottom>
                                                            {mouldBOM?.productName}
                                                        </Typography>
                                                    )}

                                                    <TableContainer component={Paper} elevation={3} sx={{ mb: 4, width: '100%' }}>
                                                        <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                                                            <TableHead>
                                                                <TableRow sx={{ '& th': { border: '1px solid #e0e0e0' } }}>
                                                                    <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#ffcdb2' }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                            {mouldBOM?.category} : {mouldBOM?.productName}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {/* Part Name and Number */}
                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                            PART NAME:
                                                                        </Typography>
                                                                        <Typography>{mouldBOM?.productName || 'YTA CHROME BSM ASSEMBLED SET'}</Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                            PART NO.
                                                                        </Typography>
                                                                        <Typography>{mouldBOM?.partNo || 'BPA003288'}</Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                    <TableCell colSpan={4} sx={{ p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                            ADDITIONAL DESCRIPTION:
                                                                        </Typography>
                                                                        <Typography>{mouldBOM?.additionalInfo || '-------'}</Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                    <TableCell colSpan={2} sx={{ p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                            CATEGORY:
                                                                        </Typography>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Typography sx={{ flexGrow: 1 }}>{mouldBOM?.category || 'FINISHED GOOD'}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    {mouldBOM?.category === 'PLATING' && (
                                                                        <TableCell colSpan={2} sx={{ p: 1 }}>
                                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                                PLATING
                                                                            </Typography>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                <Typography
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        flexWrap: 'wrap',
                                                                                        gap: '4px',
                                                                                    }}
                                                                                >
                                                                                    {mouldBOM?.platingCategory?.map((el, index, arr) => (
                                                                                        <span key={index}>
                                                                                            {el}
                                                                                            {index < arr.length - 1 && ','}
                                                                                        </span>
                                                                                    ))}
                                                                                </Typography>
                                                                            </Box>
                                                                        </TableCell>
                                                                    )}
                                                                </TableRow>

                                                                {/* HSN Code and GST Rate */}
                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            HSN CODE:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.hsnCode || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            UNIT:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.unit || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            PACKING STANDARD:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.packingStandard || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            ULTERNET UNIT:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.ulternetUnit || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            MACHINE:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.machineName || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                {/* <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                        PART CAVAITY:
                                                    </Typography>
                                                </TableCell>
                                                <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                    <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                        {mouldBOM?.cavity || ''}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow> */}

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            MASTER BATCH:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.masterBatch || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            GROSS WEIGHT:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.grossWeight || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            PART WEIGHT:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.partWeight || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                            RUNNER WEIGHT:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                            {mouldBOM?.runnerWeight || ''}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>

                                                                {mouldBOM?.mouldingRawMaterialArr && mouldBOM.mouldingRawMaterialArr.length > 0 && (
                                                                    <>
                                                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                            <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                    RAW MATERIALS
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        {mouldBOM.mouldingRawMaterialArr.map((item, index) => (
                                                                            <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                        {item.rawMaterialName}
                                                                                    </Typography>
                                                                                </TableCell>

                                                                                <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                    <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                        {mouldBOM?.runnerWeight || ''}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </>
                                                                )}

                                                                {Array.isArray(mouldBOM?.mouldingRawMaterialSubCategoryArr) && mouldBOM.mouldingRawMaterialSubCategoryArr.length > 0 && (
                                                                    <>
                                                                        {/* <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                <TableCell colSpan={6} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                        SUBCATEGORY RAW MATERIALS
                                                    </Typography>
                                                </TableCell>
                                            </TableRow> */}
                                                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                            <TableCell colSpan={1} sx={{ p: 1 }}>
                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}></Typography>
                                                                            </TableCell>

                                                                            <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                    NAME & SIZE
                                                                                </Typography>
                                                                            </TableCell>

                                                                            <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                    QTY
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>

                                                                        {Array.isArray(mouldBOM?.mouldingRawMaterialSubCategoryArr) &&
                                                                            mouldBOM.mouldingRawMaterialSubCategoryArr.map((item, index) => (
                                                                                <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                    <TableCell colSpan={1} sx={{ width: '50%', p: 1, bgcolor: '#f5f5f5' }}>
                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                            {item.subCategoryName}
                                                                                        </Typography>
                                                                                    </TableCell>

                                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                            {item.rawMaterialName}
                                                                                        </Typography>
                                                                                    </TableCell>

                                                                                    <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                            {item.subCategoryQty} {item.unit}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                    </>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            ))}

                                            <Box sx={{ gridColumn: '1 / -1', textAlign: 'center', border: '1px solid #e0e0e0', padding: 2, borderRadius: 4, bgcolor: 'white' }}>
                                                <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span>Raw Materials</span>
                                                    <Button variant="contained" size="small" onClick={() => addRawMaterial(bomIndex, bomItem.productId)}>
                                                        + Add More
                                                    </Button>
                                                </div>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Raw Material</TableCell>
                                                            <TableCell>Quantity</TableCell>
                                                            <TableCell>Unit</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {selectedRawMaterials[bomIndex]?.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ width: '50%' }}>
                                                                    <Select
                                                                        value={item.material}
                                                                        options={rawMaterialArr} // Ensure this is defined
                                                                        placeholder="Select Raw Material"
                                                                        onChange={(selected) => updateRawMaterial(bomIndex, index, 'material', selected)}
                                                                        styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField type="number" size="small" fullWidth value={item.quantity} onChange={(e) => updateRawMaterial(bomIndex, index, 'quantity', e.target.value)} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={item.unit}
                                                                        options={unitArr} // Ensure this is defined
                                                                        placeholder="Select Unit"
                                                                        onChange={(selected) => updateRawMaterial(bomIndex, index, 'unit', selected)}
                                                                        styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        color="error"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setSelectedRawMaterials((prev) => ({
                                                                                ...prev,
                                                                                [bomIndex]: prev[bomIndex].filter((_, i) => i !== index),
                                                                            }))
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>

                                            {/* Sub Category Raw Materials Table */}
                                            <Box sx={{ gridColumn: '1 / -1', textAlign: 'center', border: '1px solid #e0e0e0', padding: 2, borderRadius: 4, bgcolor: 'white' }}>
                                                <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <span>Sub Category Raw Materials</span>
                                                    <Button variant="contained" size="small" onClick={() => addSubCategoryMaterial(bomIndex, bomItem.productId)}>
                                                        + Add More
                                                    </Button>
                                                </div>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Sub Category</TableCell>
                                                            <TableCell>Raw Material</TableCell>
                                                            <TableCell>Quantity</TableCell>
                                                            <TableCell>Unit</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {subCategoryRawMaterials[bomIndex]?.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Select
                                                                        value={item.subCategory}
                                                                        options={subCategoryArr.map((el) => ({ label: el.name, value: el._id }))} // Ensure this is defined
                                                                        placeholder="Select Sub Category"
                                                                        onChange={(selected) => updateSubCategoryMaterial(bomIndex, index, 'subCategory', selected)}
                                                                        styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: '30%' }}>
                                                                    <Select
                                                                        value={item.material}
                                                                        options={item.subCategory ? getFilteredRawMaterials(item.subCategory.value) : []} // Ensure this is defined
                                                                        placeholder="Select Raw Material"
                                                                        isDisabled={!item.subCategory}
                                                                        onChange={(selected) => updateSubCategoryMaterial(bomIndex, index, 'material', selected)}
                                                                        styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <TextField type="number" size="small" fullWidth value={item.quantity} onChange={(e) => updateSubCategoryMaterial(bomIndex, index, 'quantity', e.target.value)} />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={item.unit}
                                                                        options={unitArr} // Ensure this is defined
                                                                        placeholder="Select Unit"
                                                                        onChange={(selected) => updateSubCategoryMaterial(bomIndex, index, 'unit', selected)}
                                                                        styles={{ container: (base) => ({ ...base, width: '100%' }) }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        color="error"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setSubCategoryRawMaterials((prev) => ({
                                                                                ...prev,
                                                                                [bomIndex]: prev[bomIndex].filter((_, i) => i !== index),
                                                                            }))
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                    )}
                                </>
                            ))}
                        </Box>
                    </Box>

                    {/* Raw Material Selection Section */}
                    <Box sx={{ textAlign: 'center', border: '1px solid #e0e0e0', padding: 2, borderRadius: 4, bgcolor: 'white' }}>
                        <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>Raw Materials</span>
                            <Button variant="contained" size="small" onClick={() => setSelectGeneralRawMaterials([...selectGeneralRawMaterials, { material: null, quantity: '' }])}>
                                + Add More
                            </Button>
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Raw Material</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectGeneralRawMaterials.map((item, index) => (
                                    <TableRow key={index}>
                                        {/* Select Raw Material */}
                                        <TableCell style={{ width: '50%' }}>
                                            <Select
                                                value={rawMaterialArr.find((mat) => mat.value === item.rawMaterialId) || null} // Ensure prefilled value
                                                options={rawMaterialArr} // Ensure options are in correct format
                                                placeholder="Select Raw Material"
                                                onChange={(selected) => {
                                                    const newMaterials = [...selectGeneralRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        material: selected,
                                                        rawMaterialId: selected?.value,
                                                        rawMaterialName: selected?.label,
                                                    }
                                                    setSelectGeneralRawMaterials(newMaterials)
                                                }}
                                                styles={{
                                                    container: (base) => ({
                                                        ...base,
                                                        width: '100%',
                                                    }),
                                                }}
                                            />
                                        </TableCell>

                                        {/* Quantity Input */}
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                size="small"
                                                fullWidth
                                                value={item.quantity || item.rawMaterialQty || ''}
                                                onChange={(e) => {
                                                    const newMaterials = [...selectGeneralRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        quantity: e.target.value,
                                                    }
                                                    setSelectGeneralRawMaterials(newMaterials)
                                                }}
                                            />
                                        </TableCell>

                                        {/* Select Unit */}
                                        <TableCell>
                                            <Select
                                                value={item.unit || null}
                                                options={unitArr}
                                                placeholder="Select Unit"
                                                onChange={(selected) => {
                                                    const newMaterials = [...selectGeneralRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        unit: selected,
                                                    }
                                                    setSelectGeneralRawMaterials(newMaterials)
                                                }}
                                                styles={{
                                                    container: (base) => ({
                                                        ...base,
                                                        width: '100%',
                                                    }),
                                                }}
                                            />
                                        </TableCell>

                                        {/* Remove Button */}
                                        <TableCell>
                                            <Button
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    const newMaterials = selectGeneralRawMaterials.filter((_, i) => i !== index)
                                                    setSelectGeneralRawMaterials(newMaterials)
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>

                    {/* Sub Category Raw Material Selection Section */}
                    <Box sx={{ gridColumn: '1 / -1', textAlign: 'center', border: '1px solid #e0e0e0', padding: 2, borderRadius: 4, bgcolor: 'white' }}>
                        <div className="table-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>General Sub Category Raw Materials</span>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    const newMaterials = [...selectGeneralSubCategoryRawMaterials, { subCategory: null, material: null, quantity: '', unit: null }]
                                    setSelectGeneralSubCategoryRawMaterials(newMaterials)
                                }}
                            >
                                + Add More
                            </Button>
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sub Category</TableCell>
                                    <TableCell>Raw Material</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectGeneralSubCategoryRawMaterials.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ width: '30%' }}>
                                            <Select
                                                value={item.subCategory || null}
                                                options={subCategoryArr.map((el) => ({
                                                    label: el.name,
                                                    value: el._id,
                                                }))}
                                                placeholder="Select Sub Category"
                                                onChange={(selected) => {
                                                    const newMaterials = [...selectGeneralSubCategoryRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        subCategory: selected,
                                                    }
                                                    setSelectGeneralSubCategoryRawMaterials(newMaterials)
                                                }}
                                                styles={{
                                                    container: (base) => ({
                                                        ...base,
                                                        width: '100%',
                                                    }),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: '30%' }}>
                                            <Select
                                                value={item.material}
                                                options={item.subCategory ? getFilteredRawMaterials(item.subCategory.value) : []}
                                                placeholder="Select Raw Material"
                                                isDisabled={!item.subCategory}
                                                onChange={(selected) => {
                                                    const newMaterials = [...selectGeneralSubCategoryRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        material: selected,
                                                    }
                                                    setSelectGeneralSubCategoryRawMaterials(newMaterials)
                                                }}
                                                styles={{
                                                    container: (base) => ({
                                                        ...base,
                                                        width: '100%',
                                                    }),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                size="small"
                                                fullWidth
                                                value={item.quantity}
                                                onChange={(e) => {
                                                    const newMaterials = [...selectGeneralSubCategoryRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        quantity: e.target.value,
                                                    }
                                                    setSelectGeneralSubCategoryRawMaterials(newMaterials)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Select
                                                value={item.unit || null}
                                                options={unitArr}
                                                placeholder="Select Unit"
                                                onChange={(selected) => {
                                                    const newMaterials = [...selectGeneralSubCategoryRawMaterials]
                                                    newMaterials[index] = {
                                                        ...newMaterials[index],
                                                        unit: selected,
                                                    }
                                                    setSelectGeneralSubCategoryRawMaterials(newMaterials)
                                                }}
                                                styles={{
                                                    container: (base) => ({
                                                        ...base,
                                                        width: '100%',
                                                    }),
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    const newMaterials = selectGeneralSubCategoryRawMaterials.filter((_, i) => i !== index)
                                                    setSelectGeneralSubCategoryRawMaterials(newMaterials)
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={handleSubmit}>
                        Save Bill of Material
                    </Button>
                </FormContainer>
            </Container>
        </>
    )
}
