import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllProductionWaste } from 'app/services/shiftData.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import ContentTable from 'app/components/Table/ContentTable'

export default function ViewWaste() {
    let counter = 1
    const [rawMaterialRequestArr, setRawMaterialRequestArr] = useState([])




    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = async () => {
        try {
            let { data: res } = await getAllProductionWaste()
            if (res) {
                console.log(res.data)
                setRawMaterialRequestArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }




    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))




    return (
        <Container>
            <SimpleCard title={'View Waste'}>
               
                <ContentTable
                    data={rawMaterialRequestArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Waste Type',
                            selector: (row) => row?.wasteType,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Quantity',
                            selector: (row) => row?.quantity,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Unit',
                            selector: (row) => row?.unit,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Shift',
                            selector: (row) => row?.shiftData?.shift,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Machine',
                            selector: (row) => row?.machineObj?.name,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Raw Material',
                            selector: (row) => row?.rawMaterialUsedArr.reduce((acc, el) => acc + el + ",", ''),
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Operator',
                            selector: (row) => row?.shiftData?.operatorName,
                            sortable: true,
                            searchable: true,
                        }
                    ]}
                />
            </SimpleCard>


        </Container>
    )
}
