import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'
import toast from 'react-hot-toast'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

const DateCell = styled(TableCell)(() => ({
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: 0,
    width: '10px',
    fontSize: '0.7rem',
    textAlign: 'center',
}))

export default function FirstLastPartApproval() {
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={4} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} rowSpan={1} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                        DEPT.: MOULDING
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3}></StyledTableCell>
                                    <StyledTableCell colSpan={3}>
                                        DOC. NO.: MLD-F-007
                                        <br />
                                        REV NO./ DATE: 00/10.10.2021 <br />
                                        Page 1 of 2
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                        TITLE : FIRST & LAST PART APPROVAL
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>M/C No.</StyledTableCell>
                                    <StyledTableCell colSpan={2}>FPA DATE</StyledTableCell>
                                    <StyledTableCell colSpan={2}>LPA DATE</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={2}>Part Name</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Part No</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Customer</StyledTableCell>
                                    <StyledTableCell colSpan={1}>Model</StyledTableCell>
                                    <StyledTableCell colSpan={1}>M/C Ton.</StyledTableCell>
                                    <StyledTableCell colSpan={2}>FPA Time/Shift</StyledTableCell>
                                    <StyledTableCell colSpan={2}>LPA Time/Shift</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>
                                        Checking Instrument
                                        <br /> Condition/Calibration:
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>Zero Setting of Instrument:</StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>After Checking put instrument properly in box:</StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                    <StyledTableCell colSpan={2}>Lux Level:</StyledTableCell>
                                    <StyledTableCell colSpan={1}></StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Item
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Standard
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        Insp.
                                        <br /> Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} align="center">
                                        First Five Part
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={6} align="center">
                                        Last Five Part
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        Remarks
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <DateCell>1</DateCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell align="center">Issue</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        dd/mm/yy
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        Revise
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={12} align="center">
                                        STATUS LEAGEND:- O-OK, X-NG, △-IMPROVEMENT REQUIRED
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell align="center">3</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        DD/MM/YY
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        [Revision Description]
                                    </StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        INSPECTED BY
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        VERIFIED BY(PROD.)
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        APPROVED BY (QA)
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={5} rowSpan={2} align="center">
                                        FINAL JUDGEMENT (TICK ONE)
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell rowSpan={2} align="center">
                                        4
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        DD/MM/YY
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} rowSpan={2} align="center">
                                        [Revision Description]
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        FPA
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center"></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        OK
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell rowSpan={2} align="center">
                                        5
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center">
                                        DD/MM/YY
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={4} rowSpan={2} align="center">
                                        [Revision Description]
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2} align="center">
                                        LPA
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2} align="center"></StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        NG
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={7} align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center"></StyledTableCell>
                                    <StyledTableCell align="center"></StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">
                                        DEVIATION
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}
