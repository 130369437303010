import { Button, Box } from '@mui/material'
import { styled, width } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllSupplier, getSupplierByRawMaterial, getSupplierBySupplierType } from 'app/services/Supplier.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'
import { getAllRawMaterial } from 'app/services/rawMaterial.service'
import Select from 'react-select'

export default function ViewSupplier() {
    let counter = 1
    const navigate = useNavigate()
    const [supplierArr, setSupplierArr] = useState([])
    const [searchrawMaterialTerm, setSearchrawMaterialTerm] = useState([])
    const [searchSupplierType, setSearchSupplierType] = useState('')
    const [rawMaterialArr, setRawMaterialArr] = useState([])

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getAllSupplier()
            if (res.success) {
                setSupplierArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Store?')) {
                // Delete logic
            }
        } catch (error) {
            toastError(error)
        }
        getUsers()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    useEffect(() => {
        const getRawMaterial = async () => {
            try {
                let { data: res } = await getAllRawMaterial()
                if (res.success) {
                    setRawMaterialArr(res.data)
                }
            } catch (error) {
                toastError(error)
            }
        }
        getRawMaterial()
    }, [])

    useEffect(() => {
        const handleSelectChange = async (searchrawMaterialTerm) => {
            if (searchrawMaterialTerm.length === 0) {
                getUsers()
                return
            }

            try {
                const { data: res } = await getSupplierByRawMaterial({ rawMaterialArr: searchrawMaterialTerm.map((el) => el.value) })
                if (res.success) {
                    setSupplierArr(res.data)
                } else {
                    setSupplierArr([])
                }
            } catch (error) {
                toastError(error)
            }
        }

        handleSelectChange(searchrawMaterialTerm)
    }, [searchrawMaterialTerm])

    const handleFilter = async (supplierType) => {
        try {
            const { data: res } = await getSupplierBySupplierType(supplierType)

            if (res?.data) {
                setSupplierArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    console.log(supplierArr, 'supplierArr')

    return (
        <Container>
            <SimpleCard title={'View Suppliers'}>
                <Box sx={{ display: 'flex', gap: 2, width: '100%', marginBottom: 2 }}>
                    <Box sx={{ flex: 1 }}>
                        <Select
                            options={rawMaterialArr.map((el) => ({ label: el.name, value: el._id }))}
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Raw Material"
                            name="rawMaterial"
                            onChange={setSearchrawMaterialTerm}
                            isSearchable
                            isClearable
                            value={searchrawMaterialTerm}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40,
                                    width: '100%',
                                }),
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                                menuList: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                        />
                    </Box>

                    {/* Wrap the second select in a Box with flex: 1 */}
                    <Box sx={{ flex: 1 }}>
                        <Select
                            options={[
                                { value: 'Trader', label: 'Trader' },
                                { value: 'Manufacturer', label: 'Manufacturer' },
                            ]}
                            className="basic-single-select"
                            classNamePrefix="select"
                            placeholder="Select Supplier Type"
                            name="supplierType"
                            onChange={async (selectedOption) => {
                                if (!selectedOption) {
                                    setSearchSupplierType('')
                                    await getUsers()
                                } else {
                                    setSearchSupplierType(selectedOption.value)
                                    await handleFilter(selectedOption.value)
                                }
                            }}
                            isSearchable
                            isClearable
                            value={searchSupplierType ? { value: searchSupplierType, label: searchSupplierType } : null}
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40,
                                    width: '100%',
                                }),
                                menu: (base) => ({ ...base, zIndex: 9999 }),
                                menuList: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                        />
                    </Box>

                    {/* The Clear Filter button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSearchrawMaterialTerm([])
                            setSearchSupplierType('')
                        }}
                    >
                        Clear Filter
                    </Button>
                </Box>

                <ContentTable
                    data={supplierArr}
                    columns={[
                        {
                            name: '#',
                            selector: () => counter++,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Contact',
                            selector: (row) => row?.contact,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Address',
                            selector: (row) => row?.address,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'GST In',
                            selector: (row) => row?.gstIn,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Raw Materials',
                            selector: (row) => row?.rawMaterialArr?.map((el) => el.rawMaterialName).join(', '),
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Supplier Type',
                            selector: (row) => row?.supplierType,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Update',
                            selector: (row) => (
                                <Button variant="contained" color="primary" onClick={() => navigate(`/AddSupplier?id=${row?._id}`)}>
                                    Update
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
