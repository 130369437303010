import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { getQrDataToDownload } from 'app/services/mrn.service'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getBinByBarCode } from 'app/services/Bin.service'

export default function DownloadBinBarcode() {
    const [qrArr, setQrArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const printRef = useRef()
    const [data, setData] = useState({})

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getBinByBarCode(params.id)
                setData(res.data.data)
            } catch (error) {
                toastError(error)
            }
        }
        fetchData()
    }, [])


    return (
        <>
            <div className="center_print" style={{ pageBreakAfter: 'always', height: '100%', display: 'grid', placeItems: 'center' }}>
                <div>
                    <Barcode height={50} value={params.id} width={3} />
                    <Typography gutterBottom sx={{ fontSize: '1.12rem', fontWeight: 'bold', color: '#333', fontFamily: 'monospace' }}>
                        Bin Size: {data[0]?.binSizeName || 'No bin size'}
                    </Typography>

                    <Typography gutterBottom sx={{ fontSize: '1.12rem', fontWeight: 'bold', color: '#333', fontFamily: 'monospace' }}>
                        Bin Type: {data[0]?.binTypeName || 'No bin type'}
                    </Typography>
                </div>
            </div>
        </>
    )
}
