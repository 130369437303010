import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import moment from 'moment'
import { getProductionPlanning, exportProductionPlansToExcel, getDeletePlannedData } from '../../../services/MouldingPlanning.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useNavigate } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function ViewProductionPlanning() {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'))
    const [productionPlans, setProductionPlans] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        fetchProductionPlans()
    }, [])

    const fetchProductionPlans = async () => {
        try {
            const query = `startDate=${startDate}&endDate=${endDate}`
            const { data: res } = await getProductionPlanning(query)
            if (res) {
                setProductionPlans(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleExportToExcel = async () => {
        try {
            const query = `startDate=${startDate}`
            await exportProductionPlansToExcel(query)
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            const confirmed = window.confirm("Do you want to delete...");
            if (!confirmed) return;
    
            const res = await getDeletePlannedData(id);  
            if (res) {
                toastSuccess("Deleted Successfully");
    
                // Update the state to remove the deleted item
                setProductionPlans((prevData) => prevData.filter((item) => item._id !== id)); 
            }
        } catch (error) {
            console.error('Error deleting planned data:', error);
            toastError(error?.message || "Failed to delete data");
        }
    };
    
    

    return (
        <Container>
            <SimpleCard title="View Production Plans">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography sx={{ mt: 3 }}>Select Date Range</Typography>
                    <Box>
                        <Button variant="contained" sx={{ mr: 2 }} color="primary" onClick={() => navigate('/admin/productionPlanning')}>
                            Add New
                        </Button>
                        <Button variant="contained" sx={{ mr: 2 }} color="primary" onClick={handleExportToExcel}>
                            Export to Excel
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => navigate('/admin/customFormatPlanning')}>
                            Custom Format
                        </Button>
                    </Box>
                </Box>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={3}>
                        <TextField type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate} label="Start Date" sx={{ width: 250, mt: 2 }} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3}>
                        <TextField type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate} label="End Date" sx={{ width: 250, mt: 2 }} InputLabelProps={{ shrink: true }} />
                    </Grid>
                </Grid>
                <Button onClick={fetchProductionPlans} variant="contained" color="primary" sx={{ mt: 5 }}>
                    Search
                </Button>
            </SimpleCard>

            {productionPlans.length > 0 && (
                <SimpleCard title="Production Plans" sx={{ mt: 5 }}>
                    <ContentTable
                    data={productionPlans}
                    columns={[
                        {
                            name: 'Date',
                            selector: (row) => row?.dateCompareString,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Product Name',
                            selector: (row) => row?.productName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Machine Name',
                            selector: (row) => row?.machineName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Mould Name',
                            selector: (row) => row?.mouldName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Shift A',
                            selector: (row) => row?.shiftAPlannedProductionQty,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Shift B',
                            selector: (row) => row?.shiftBPlannedProductionQty,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Total',
                            selector: (row) => row?.totalPlannedProduction,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
                </SimpleCard>
            )}
        </Container>
    )
}
