import React from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function PreHeatingRecord() {
    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={4} rowSpan={3}  sx={{backgroundColor: "#F6F0F0"}}>
                                    PRE HEATING CHART
                                </StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={4} align="center">
                                    <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                </StyledTableCell>
                                <StyledTableCell colSpan={9} rowSpan={2} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                    DEPT.: MOULDING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                <StyledTableCell colSpan={1}>MLD-F-011</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={2}>REV NO.:</StyledTableCell>
                                <StyledTableCell colSpan={1}>00</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>SR. No.</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>RM DETAIL</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>TEMP STD.</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>TIME STD.</StyledTableCell>

                                <StyledTableCell colSpan={9} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                    TITLE : HOPPER CLEANING CHECK SHEET
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>REV NO./ DATE:</StyledTableCell>
                                <StyledTableCell colSpan={1}>10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>1</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>ABS</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>85 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>02~03 Hrs. Min</StyledTableCell>
                                <StyledTableCell colSpan={2}>EFFT DATE:</StyledTableCell>
                                <StyledTableCell colSpan={1}>10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PC+ABS</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>85 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>02~03 Hrs. Min</StyledTableCell>
                                <StyledTableCell colSpan={1}>M/c No.:</StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}>M/c TON:</StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}>Hopper Capacity:</StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}>Month</StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>3</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PVC</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>70 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2 Hrs. Min</StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    Date
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    RM DETAIL
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    ACTUAL PREHEATING TEMP.
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    MATERIAL LOADING TIME / START TIME
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    M/C RUN TIME / PROCESS START TIME
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    SIGN. LOADER
                                </StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}>
                                    SIGN. SUP.
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}>
                                    Remarks
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>4</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>TPV</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>70 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2 Hrs. Min</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>5</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>TPU</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>70 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2 Hrs. Min</StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>6</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>Nylon</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>90 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2.5~3.0 Hrs.</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>7</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PMMA</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>80~90 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2 Hrs. Min</StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>8</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>POM</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>100 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>3 Hrs. Min</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>9</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PC</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>120 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>3 Hrs. Min</StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>10</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PBT+PET</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>120 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>3 Hrs. Min</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>11</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PET</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>160 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>04~05 Hrs.</StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>12</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>PP/PP/PS</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>80 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>1.5~2.0 Hrs.</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>13</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>TPS</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>70 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>2 Hrs. Min.</StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1} rowSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2} rowSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>14</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>Nyon GF-15~30%</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>90 ± 10° C</StyledTableCell>
                                <StyledTableCell colSpan={1} sx={{backgroundColor: "#F6F0F0"}}>3.0~3.5 Hrs.</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={4} rowSpan={6} sx={{backgroundColor: "#F6F0F0"}}>
                                    <strong>कार्यविधि:</strong>
                                    <br />1{')'} हॉपर का टेम्परेचर ON करें, टेम्परेचर का स्टैण्डर्ड के अनुसार आने के बाद मटेरियल को लोड करें तथा रिपोर्ट में ही टाइम रिकॉर्ड करें जब स्टैण्डर्ड के अनुसार टेम्परेचर आ चुका हो।
                                    <br />2{')'} स्टैण्डर्ड के अनुसार टेम्परेचर आने के बाद 2 से 3 घंटे तक स्टैण्डर्ड चार्ट का अनुसरण करके ही मशीन को चलाएं।
                                    <br />3{')'} ध्यान रखें इस प्रक्रिया में कोई भी दिक्कत आने पर अपने सुपरवाइजर को सूचित करें।
                                </StyledTableCell>

                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={1}></StyledTableCell>
                                <StyledTableCell colSpan={2}></StyledTableCell>
                            </TableRow>

                            <TableRow>
                              <StyledTableCell colSpan={3} align='center'>Rev No.:</StyledTableCell>
                              <StyledTableCell colSpan={5} align='center'>Rev. DATE:</StyledTableCell>
                              <StyledTableCell colSpan={5} align='center'>Detail Of Revision</StyledTableCell>
                              <StyledTableCell colSpan={2} align='center'>Reason of Revision</StyledTableCell>
                              <StyledTableCell colSpan={2} align='center'>Approved By</StyledTableCell>
                            </TableRow>
                            <TableRow>
                              <StyledTableCell colSpan={3} align='center'></StyledTableCell>
                              <StyledTableCell colSpan={5} align='center'></StyledTableCell>
                              <StyledTableCell colSpan={5} align='center'></StyledTableCell>
                              <StyledTableCell colSpan={2} align='center'></StyledTableCell>
                              <StyledTableCell colSpan={2} align='center'></StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}
