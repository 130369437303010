import { Button, TextField, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addITProducts } from 'app/services/ITProducts.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAllITProductSubCategory } from 'app/services/itProductSubCategory.service'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddITProducts() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('')
    const [subCategoryArr, setSubCategoryArr] = useState([])

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
                subCategory: selectedSubCategoryId
            }
            let { data: res } = await addITProducts(obj)
            if (res) {
                toastSuccess(res.message)
                navigate(-1)
            }
        } catch (error) {
            toastError(error)
        }
    }

       const getAllITProductSubCategoryArr = async () => {
            try {
                const { data: res } = await getAllITProductSubCategory()
                if (res) {
                    setSubCategoryArr(res.data)
                }
            } catch (error) {
                toastError(error)
            }
        }
    
        useEffect(() => {
            getAllITProductSubCategoryArr()
        }, [])

    return (
        <Container>
            <SimpleCard title="Add IT Products">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />

                    <TextField type="text" name="Description" onChange={(e) => setDescription(e.target.value)} value={description || ''} label="Description" sx={{ width: '100%', mt: 2 }} />

                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sub Category</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={selectedSubCategoryId} label="Moulding Category" onChange={(e) => setSelectedSubCategoryId(e.target.value)}>
                                {subCategoryArr?.map((el) => (
                                    <MenuItem value={el._id} key={el._id}>
                                        {el.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
