import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteJigById, getAllJigs, updateJigById } from 'app/services/Jigs.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import ContentTable from 'app/components/Table/ContentTable'

export default function ViewJigs() {
    let counter = 1
    const [displayArr, setDisplayArr] = useState([])



    useEffect(() => {
        getJigs()
    }, [])

    const getJigs = async () => {
        try {
            let { data: res } = await getAllJigs()
            if (res) {
                setDisplayArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }


    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Jig?')) {
                const res = await deleteJigById(id)
                if (res.data?.message) alert(res.data.message)
            }
        } catch (error) {
            toastError(error)
        }
        getJigs()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const handleUpateStatus = async (value, id) => {
        try {
            if (window.confirm("Do your really want to update jig status")) {

                const { data: res } = await updateJigById(id, { status: value });
                if (res) {
                    alert(res.message)
                    getJigs()
                }
            }
        } catch (error) {
            toastError(error)
        }
    }


    return (
        <Container>
            <SimpleCard title={'Manage Jigs'}>


                <ContentTable
                    data={displayArr}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Name',
                            selector: (row) => row?.name,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Jig Number',
                            selector: (row) => row?.arr?.reduce((acc, el) => acc + el.jigNumber + ', ', ''),
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Product',
                            selector: (row) => row?.productName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'QTY/JIG',
                            selector: (row) => row?.qtyOrJigs,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Qty Flight Bar',
                            selector: (row) => row?.qtyFlightBar,
                            sortable: true,
                            searchable: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => alert('Unable to delete jig . please contact administrator')}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
