import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/ProductBinMapping`

export const addProductBinMapping = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getBinProductMapping = (binId) => {
    return axios.get(`${url}/getByBinId/${binId}`)
}

export const deleteProductBinMapping = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}   