import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/plating-category`

export const addPlatingCategory = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllPlatingCategory = () => {
    return axios.get(`${url}`)
}

export const deletePlatingCategory = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getPlatingCategoryById = async (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const updatePlatingCategory = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}