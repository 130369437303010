import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'

const url = `${API_URL}/client`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addClient = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllClient = () => {
    return axios.get(`${url}`)
}

export const deleteClient = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getClientById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}
export const updateClient = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}


export const getClientProducts=()=>{
    return axios.get(`${url}/getClientProducts`)
}