import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { getAllProductRecipe, deleteProductRecipe } from '../../../services/ProductRecipe.service'
import { useNavigate } from 'react-router-dom'
import { getAllAssemblyBOM, deleteAssemblyBOM } from 'app/services/AssemblyBOM.service'

import { getAllBOM, deleteBOM } from 'app/services/BOM.service'
import ContentTable from 'app/components/Table/ContentTable'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function ViewProductRecipe() {
    let counter = 1
    const [recipeData, setRecipeData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        fetchRecipes()
    }, [])

    const fetchRecipes = async () => {
        try {
            const [bomResponse, assemblyBomResponse] = await Promise.all([getAllBOM(), getAllAssemblyBOM()])

            let bomData = bomResponse?.data?.data || []
            let assemblyBomData = assemblyBomResponse?.data?.data || []

            const mergedData = [...bomData, ...assemblyBomData]

            setRecipeData(mergedData)
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            const selectedRecipe = recipeData.find((item) => item._id === id)

            if (!selectedRecipe) {
                console.error('Recipe not found!')
                return
            }

            if (window.confirm('Are you sure you want to delete this recipe?')) {
                if (selectedRecipe.category === 'ASSEMBLY') {
                    const res = await deleteAssemblyBOM(id)
                    if (res.data?.message) toastSuccess(res.data.message)
                } else {
                    const res = await deleteBOM(id)
                    if (res.data?.message) toastSuccess(res.data.message)
                }
                fetchRecipes()
            }
        } catch (error) {
            toastError(error)
        }
    }

    console.log(recipeData, 'recipeData')

    const handleUpdate = (id) => {
        const selectedRecipe = recipeData.find((item) => item._id === id)

        if (!selectedRecipe) {
            console.error('Recipe not found!')
            return
        }

        if (selectedRecipe.category === 'ASSEMBLY') {
            navigate(`/admin/ProductRecipe/AddAssembly/?id=${id}`)
        } else {
            navigate(`/admin/ProductRecipe/Add?id=${id}`)
        }
    }

    return (
        <Container>
            <SimpleCard title="BOM">
                <ContentTable
                    data={recipeData}
                    columns={[
                        {
                            name: '#',
                            selector: (row) => counter++,
                            ignoreRowClick: true,
                            allowOverflow: true,
                            sortable: true,
                            button: true,
                        },
                        {
                            name: 'Product Name',
                            selector: (row) => row?.productName,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Category',
                            selector: (row) => row?.category,
                            searchable: true,
                            sortable: true,
                        },
                        {
                            name: 'Update',
                            selector: (row) => (
                                <Button variant="contained" color="primary" onClick={() => handleUpdate(row._id)}>
                                    Update
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                        {
                            name: 'Delete',
                            selector: (row) => (
                                <Button variant="contained" color="error" onClick={() => handleDelete(row._id)}>
                                    Delete
                                </Button>
                            ),
                            ignoreRowClick: true,
                            allowOverflow: true,
                            button: true,
                        },
                    ]}
                />
            </SimpleCard>
        </Container>
    )
}
