import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

import { styled } from '@mui/system'

import { getAllHopperCleaning } from 'app/services/hopperCleaningChecksheet.service'
import toast from 'react-hot-toast'

import { useLocation } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

const DateCell = styled(TableCell)(() => ({
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: 0,
    width: '30px',
    fontSize: '0.7rem',
    textAlign: 'center',
}))

export default function HopperCleaningChecksheet() {
    const id = new URLSearchParams(useLocation().search).get('id') || ''
    const [hopperCleaning, setHopperCleaning] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getAllHopperCleaning(id)
                setHopperCleaning(result.data.data)
            } catch (error) {
                toast.error(error.message)
            }
        }
        fetchData()
    }, [])

    console.log(hopperCleaning, 'hopperCleaning')

    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell colSpan={1} rowSpan={4} align="center">
                                    <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                </StyledTableCell>
                                <StyledTableCell colSpan={11} rowSpan={2} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                    DEPT.: MOULDING
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                <StyledTableCell colSpan={2}>MLD-F-007</StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={2}>REV NO./ DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2}>00/10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={11} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                    TITLE : HOPPER CLEANING CHECK SHEET
                                </StyledTableCell>
                                <StyledTableCell colSpan={2}>EFFT DATE:</StyledTableCell>
                                <StyledTableCell colSpan={2}>10.10.2021</StyledTableCell>
                            </TableRow>

                            <TableRow>
                                <StyledTableCell colSpan={4}>Page - 1 of 2</StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <StyledTableCell colSpan={1}>M/c No.:</StyledTableCell>
                                <StyledTableCell colSpan={2}>{hopperCleaning[0]?.machine.identificationNo}</StyledTableCell>
                                <StyledTableCell colSpan={1}>M/c Tonnage:</StyledTableCell>
                                <StyledTableCell colSpan={2}>{hopperCleaning[0]?.machine.loadingCapacity}</StyledTableCell>
                                <StyledTableCell colSpan={10} align="center">
                                    Month
                                </StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                    <Table size="small" aria-label="temperature parameters table">
                        <TableHead>
                            <StyledTableCell colSpan={1}>SR. No.</StyledTableCell>
                            <StyledTableCell colSpan={2}>क्या काम करना है</StyledTableCell>
                            <StyledTableCell colSpan={2}>सफाई कसेकरनी है</StyledTableCell>
                            <StyledTableCell colSpan={1}>Shift</StyledTableCell>
                            {
                                Array.from({ length: 31 }, (_, index) => (
                                    <DateCell key={index}>{index + 1}</DateCell>
                                ))
                            }
                        </TableHead>

                        <TableBody>
                            {hopperCleaning.map((item, index) => (
                                <React.Fragment key={item._id}>
                                    <TableRow>
                                        <StyledTableCell colSpan={1} rowSpan={2}>
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} rowSpan={2}>
                                            {item.question?.question || ''}
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} rowSpan={2}>
                                            {item.cleaningQuestion?.question || ''}
                                        </StyledTableCell>

                                        <StyledTableCell colSpan={1}>A</StyledTableCell>
                                        {[...Array(31)].map((_, i) => {
                                            const compareDate = new Date(item.dateCompareString).getDate()

                                            const shiftA = item.hopperCleaning.find((entry) => entry.shift === 'A')

                                            return (
                                                <DateCell key={`A-${i}`} colSpan={1}>
                                                    {compareDate === i + 1 ? (shiftA?.done ? '✔' : '✘') : ''}
                                                </DateCell>
                                            )
                                        })}
                                    </TableRow>

                                    <TableRow>
                                        <StyledTableCell colSpan={1}>B</StyledTableCell>
                                        {[...Array(31)].map((_, i) => {
                                            const compareDate = new Date(item.dateCompareString).getDate()
                                            const shiftB = item.hopperCleaning.find((entry) => entry.shift === 'B')

                                            return (
                                                <DateCell key={`B-${i}`} colSpan={1}>
                                                    {compareDate === i + 1 ? (shiftB?.done ? '✔' : '✘') : ''}
                                                </DateCell>
                                            )
                                        })}
                                    </TableRow>
                                </React.Fragment>
                            ))}

                            <TableRow>
                                <StyledTableCell colSpan={3} rowSpan={2}>
                                    Note: √ for Ok, X- for Not ok
                                </StyledTableCell>
                                <StyledTableCell colSpan={3} rowSpan={2}>
                                    Sign after Verification ( Production Supervisor )
                                </StyledTableCell>
                                {
                                    Array.from({ length: 31 }, (_, index) => (
                                        <DateCell key={index}></DateCell>
                                    ))
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    )
}
