import { Button, FormControlLabel, Radio, RadioGroup, TextField, Typography, Grid, Box } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import { addUserData } from 'app/redux/actions/User/user.actions'
import { USERS } from 'app/utils/constant'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import { addMouldData } from 'app/redux/actions/Mould/mould.action'
import Select from 'react-select'
const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddMould() {
    const dispatch = useDispatch()
    const productArr = useSelector((state) => state.product.productArr)

    // if (productArr) {
    //     setLocalProductsArr(productArr.map(el => {
    //         let obj = {
    //             ...el,
    //             checked: false
    //         }
    //         return obj
    //     }))
    // }
    const [cavityProductArr, setCavityProductArr] = useState([])
    const [localProductsArr, setLocalProductsArr] = useState([
        productArr &&
            productArr.map((el) => {
                let obj = {
                    ...el,
                    checked: false,
                }
                return obj
            }),
    ])
    const [name, setName] = useState('')
    const [mouldNumber, setMouldNumber] = useState('')
    const [cycleTime, setCycleTime] = useState(0)
    const [numberOfCavities, setNumberOfCavities] = useState(0)
    const [mouldWeight, setMouldWeight] = useState(0)
    const [mouldHeight, setMouldHeight] = useState(0)
    const [mouldWidth, setMouldWidth] = useState(0)
    const [mouldLength, setMouldLength] = useState(0)
    const handleSubmit = async () => {
        try {
            let tempArr = [...new Set(cavityProductArr.map((el) => el.productId))]
            let obj = {
                name,
                mouldNumber: mouldNumber,
                numberOfCavities: numberOfCavities,
                productArr: tempArr.map((el) => ({ productId: el })),
                cavityProductArr,
                cycleTime: parseInt(cycleTime),
            }
            dispatch(addMouldData(obj))
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    useEffect(() => {
        setLocalProductsArr(
            productArr &&
                productArr
                    .filter((el) => !el.assembly)
                    .map((el) => {
                        let obj = {
                            ...el,
                            label: el?.name,
                            value: el?._id,
                        }
                        return obj
                    })
        )
    }, [productArr]) // s

    useEffect(() => {
        if (parseInt(numberOfCavities)) {
            let tempArr = [...Array(parseInt(numberOfCavities)).keys()].map((el, i) => ({ productId: '', cavity: i + 1 }))
            setCavityProductArr(tempArr)
        }
    }, [numberOfCavities])

    const handleProductSelection = (index, obj) => {
        let tempArr = [...cavityProductArr]
        tempArr[index].productId = obj?._id
        setCavityProductArr([...tempArr])
    }

    useEffect(() => {
        getAllProducts()
    }, [])

    const getAllProducts = async () => {
        try {
            dispatch(getAllProductData())
        } catch (error) {
            toastError(error)
        }
    }

    // const handleProductSelect = (index) => {
    //     let tempArr = localProductsArr.map((el, i) => {
    //         if (i == index) {
    //             el.checked = !el.checked
    //         }
    //         return el
    //     })
    //     console.log(tempArr)
    //     setLocalProductsArr([...tempArr])
    // }

    return (
        <Container>
            <SimpleCard title="Add Mould">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <div style={{ marginTop: 15 }}>Mould name</div>
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />
                    <TextField type="text" name="Mould Number" onChange={(e) => setMouldNumber(e.target.value)} value={mouldNumber || ''} label="Mould Number" sx={{ width: '100%', mt: 2 }} />

                    {/* <div style={{ marginTop: 15 }}>Select Products</div>
                    <div className="row">
                        <div className="col-12">

                            {
                                localProductsArr && localProductsArr.map((el, index) => {
                                    return (
                                        <FormControlLabel key={`${new Date().getTime()}${index}`} control={<Checkbox onChange={() => handleProductSelect(index)} checked={el?.checked} />} label={el?.name} />
                                    )
                                })
                            }
                        </div>
                    </div> */}
                    <div style={{ marginTop: 15 }}>Cycle Time</div>
                    <TextField type="number" name="Cycle Time" onChange={(e) => setCycleTime(e.target.value)} value={cycleTime || ''} label="Cycle Time" sx={{ width: '100%', mt: 2 }} />
                    <div style={{ marginTop: 15 }}>Number Of Cavities</div>
                    <TextField type="number" name="Number Of Cavities" onChange={(e) => setNumberOfCavities(e.target.value)} value={numberOfCavities || ''} label="Number Of Cavities" sx={{ width: '100%', mt: 2 }} />

                    {cavityProductArr &&
                        cavityProductArr.length > 0 &&
                        cavityProductArr.map((el, i) => {
                            return (
                                <Grid key={i} container gap={2} sx={{ margin: '15px 0px' }}>
                                    <Grid
                                        item
                                        key={i}
                                        sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cavity {el?.cavity}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Select options={localProductsArr} onChange={(e) => handleProductSelection(i, e)} />
                                    </Grid>
                                </Grid>
                            )
                        })}

                    <div style={{ marginTop: 15 }}>Mould Weight</div>
                    <TextField type="number" name="Mould Weight" onChange={(e) => setMouldWeight(e.target.value)} value={mouldWeight || ''} label="Mould Weight" sx={{ width: '100%', mt: 2 }} />

                    <Box display="flex" flexDirection="column" alignItems="center" gap={2} width="100%" sx={{ marginTop: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Mould Length
                                </Typography>
                                <TextField type="number" name="Mould Length" fullWidth value={mouldLength || ''} onChange={(e) => setMouldLength(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Mould Width
                                </Typography>
                                <TextField type="number" name="Mould Width" fullWidth value={mouldWidth || ''} onChange={(e) => setMouldWidth(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Mould Height
                                </Typography>
                                <TextField type="number" name="Mould Height" fullWidth value={mouldHeight || ''} onChange={(e) => setMouldHeight(e.target.value)} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Add Mould
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
