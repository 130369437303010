import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'
const url = `${API_URL}/question`

export const addQuestion = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllQuestion = () => {
    return axios.get(`${url}`)
}

export const deleteQuestion = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
