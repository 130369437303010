import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Button,
    Grid,
    styled
} from '@mui/material'
import { getActualPlannedData } from 'app/services/MouldingPlanning.service'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import { useState } from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '12px 16px',
    fontSize: '13px',
    textAlign: 'center',
    '&.header': {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.text.primary,
        fontWeight: 600,
        textAlign: 'center',
        padding: '16px 12px',
        height: '60px'
    },
    '&.section-header': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        fontWeight: 700,
        fontSize: '14px',
        padding: '16px'
    },
    '&.product-name': {
        maxWidth: '300px',
        whiteSpace: 'normal',
        lineHeight: '1.2'
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
    '& td': {
        textAlign: 'center'
    }
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: '12px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
}))

export default function Actualplanneddata() {
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'))
    const [planningData, setPlanningData] = useState([])

    const handleSubmit = async() => {
        try {
            let {data:res} = await getActualPlannedData(`date=${selectedDate}`)
            if(res) {
                setPlanningData(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value)
    }

    return (
        <Box sx={{ m: 3 }}>
            {/* Filter Section */}
            <StyledPaper>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                            Production Planning Details
                        </Typography>
                        <TextField
                            type="date"
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ 
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            fullWidth
                            sx={{ 
                                height: '48px',
                                borderRadius: '8px',
                                mt: { xs: 0, md: 3.5 }
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </StyledPaper>

            {/* Updated table container with horizontal scroll */}
            <TableContainer 
                component={Paper} 
                sx={{ 
                    borderRadius: '12px', 
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        height: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px'
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555'
                    }
                }}
            >
                <Table 
                    size="small" 
                    sx={{ 
                        border: 1, 
                        borderColor: 'divider',
                        '& th, & td': {
                            minWidth: '100px',
                            maxWidth: 'none',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            textAlign: 'center'
                        },
                        '& th.header': {
                            verticalAlign: 'middle'
                        },
                        '& td': {
                            verticalAlign: 'middle'
                        }
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className="section-header" colSpan={5}>Machine & Product Details</StyledTableCell>
                            <StyledTableCell className="section-header" colSpan={6}>Planned Production</StyledTableCell>
                            <StyledTableCell className="section-header" colSpan={9}>Actual Production</StyledTableCell>
                        </TableRow>
                        <TableRow>
                            {/* Machine & Product Details */}
                            <StyledTableCell className="header">S.No</StyledTableCell>
                            <StyledTableCell className="header">Machine</StyledTableCell>
                            <StyledTableCell className="header">Product Name</StyledTableCell>
                            <StyledTableCell className="header">Mould Name</StyledTableCell>
                            <StyledTableCell className="header">Cavity</StyledTableCell>

                            {/* Planned Production */}
                            <StyledTableCell className="header">Hourly Plan</StyledTableCell>
                            <StyledTableCell className="header">Total Plan Qty</StyledTableCell>
                            <StyledTableCell className="header">Cycle Time</StyledTableCell>
                            <StyledTableCell className="header">Gross Wt.</StyledTableCell>
                            <StyledTableCell className="header">Req. Time (Hrs)</StyledTableCell>
                            <StyledTableCell className="header">Plan RM (Kg)</StyledTableCell>

                            {/* Actual Production */}
                            <StyledTableCell className="header">Hourly Actual</StyledTableCell>
                            <StyledTableCell className="header">OK Qty</StyledTableCell>
                            <StyledTableCell className="header">Rejected Qty</StyledTableCell>
                            <StyledTableCell className="header">Rejected %</StyledTableCell>
                            <StyledTableCell className="header">Lumps</StyledTableCell>
                            <StyledTableCell className="header">Efficiency %</StyledTableCell>
                            <StyledTableCell className="header">RM Consumption</StyledTableCell>
                            <StyledTableCell className="header">Running Time</StyledTableCell>
                            <StyledTableCell className="header">Moved to SFG</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {planningData && planningData.length > 0 ? (
                            planningData.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{index+1}</StyledTableCell>
                                    <StyledTableCell>{row.machineName}</StyledTableCell>
                                    <StyledTableCell sx={{ minWidth: '200px' }}>{row.productName}</StyledTableCell>
                                    <StyledTableCell>{row.mouldName}</StyledTableCell>
                                    <StyledTableCell>{row.cavity}</StyledTableCell>

                                    {/* Planned Production */}
                                    <StyledTableCell>{row.hourlyProduction}</StyledTableCell>
                                    <StyledTableCell>{row.totalPlannedProduction}</StyledTableCell>
                                    <StyledTableCell>{row.cycleTime}</StyledTableCell>
                                    <StyledTableCell>{row.grossWt}</StyledTableCell>
                                    <StyledTableCell>{row.reqtime}</StyledTableCell>
                                    <StyledTableCell>{row.planrm}</StyledTableCell>

                                    {/* Actual Production */}
                                    <StyledTableCell>{row.hourlyActualProduction?.toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{row.actualOkQty}</StyledTableCell>
                                    <StyledTableCell>{row.actualRejectedQty}</StyledTableCell>
                                    <StyledTableCell>{row.actualRejectedPercentage?.toFixed(2)}%</StyledTableCell>
                                    <StyledTableCell>{row.actualLumps}</StyledTableCell>
                                    <StyledTableCell>{row.prodEfficiency}%</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <TableRow>
                                <StyledTableCell colSpan={20} sx={{ textAlign: 'center', py: 4 }}>
                                    <Typography variant="subtitle1" color="text.secondary">
                                        No data available for selected date
                                    </Typography>
                                </StyledTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
