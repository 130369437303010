import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/AssemblyProduct`

export const addAssemblyProductToDB = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllAssemblyProduct = () => {
    return axios.get(`${url}`)
}

export const getAssemblyProductBOM = (id) => {
    return axios.get(`${url}/getBOMById/${id}`)
}

export const updateAssemblyProduct = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const deleteAssemblyProduct = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getAssemblyProductById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}