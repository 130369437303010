import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { deleteBinSize, getAllBinSize, updateBinSize, addBinSize } from 'app/services/BinSizes.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    }
}))

export default function ViewBinSize() {
    const [binSizes, setBinSizes] = useState([])
    const [loading, setLoading] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedBinSize, setSelectedBinSize] = useState(null)
    const [newBinSize, setNewBinSize] = useState({ 
        name: '', 
        description: '',
        height: '',
        width: '',
        length: ''
    })

    const handleEditClick = (row) => {
        setSelectedBinSize(row)
        setOpenEditModal(true)
    }

    const handleCloseModal = () => {
        setOpenEditModal(false)
        setSelectedBinSize(null)
    }

    const handleUpdate = async () => {
        try {
            await updateBinSize(selectedBinSize._id, {
                name: selectedBinSize.name,
                description: selectedBinSize.description,
                height: selectedBinSize.height,
                width: selectedBinSize.width,
                length: selectedBinSize.length
            })
            getBinSizes()
            handleCloseModal()
        } catch (error) {
            toastError(error)
        }
    }

    const handleOpenAddModal = () => {
        setOpenAddModal(true)
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false)
        setNewBinSize({ name: '', description: '', height: '', width: '', length: '' })
    }

    const handleAdd = async () => {
        try {
            await addBinSize(newBinSize)
            getBinSizes()
            handleCloseAddModal()
        } catch (error) {
            toastError(error)
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '80px',
            center: true
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            searchable: true,
            center: true
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: true,
            searchable: true,
            center: true
        },
        {
            name: 'Height',
            selector: (row) => row.height,
            sortable: true,
            center: true
        },
        {
            name: 'Width',
            selector: (row) => row.width,
            sortable: true,
            center: true
        },
        {
            name: 'Length',
            selector: (row) => row.length,
            sortable: true,
            center: true
        },
        {
            name: 'Created At',
            selector: (row) => moment(row.createdAt).format('DD-MM-YYYY HH:mm'),
            sortable: true,
            center: true
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditClick(row)}
                        sx={{ mr: 1 }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(row._id)}
                    >
                        Delete
                    </Button>
                </>
            ),
            center: true
        }
    ]

    const getBinSizes = async () => {
        setLoading(true)
        try {
            let { data: res } = await getAllBinSize()
            if (res) {
                setBinSizes(res.data)
            }
        } catch (error) {
            toastError(error)
        }
        setLoading(false)
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Bin Size?')) {
                let { data: res } = await deleteBinSize(id)
                if (res) {
                    getBinSizes()
                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getBinSizes()
    }, [])

    const renderTextField = (value, onChange, label) => (
        <TextField
            margin="dense"
            label={label}
            type="text"
            fullWidth
            value={value}
            onChange={onChange}
            sx={{ mb: 2 }}
        />
    )

    return (
        <Container>
            <SimpleCard title="View Bin Sizes">
                <ContentTable
                    title=""
                    columns={columns}
                    data={binSizes}
                    loading={loading}
                    reload={getBinSizes}
                    extraButtonFn={handleOpenAddModal}
                    extraButtonText="Add Bin Size"
                />
            </SimpleCard>

            {/* Edit Modal */}
            <Dialog 
                open={openEditModal} 
                onClose={handleCloseModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Edit Bin Size
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {renderTextField(
                        selectedBinSize?.name || '',
                        (e) => setSelectedBinSize({...selectedBinSize, name: e.target.value}),
                        "Name"
                    )}
                    {renderTextField(
                        selectedBinSize?.description || '',
                        (e) => setSelectedBinSize({...selectedBinSize, description: e.target.value}),
                        "Description"
                    )}
                    {renderTextField(
                        selectedBinSize?.height || '',
                        (e) => setSelectedBinSize({...selectedBinSize, height: e.target.value}),
                        "Height"
                    )}
                    {renderTextField(
                        selectedBinSize?.width || '',
                        (e) => setSelectedBinSize({...selectedBinSize, width: e.target.value}),
                        "Width"
                    )}
                    {renderTextField(
                        selectedBinSize?.length || '',
                        (e) => setSelectedBinSize({...selectedBinSize, length: e.target.value}),
                        "Length"
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleUpdate} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Modal */}
            <Dialog 
                open={openAddModal} 
                onClose={handleCloseAddModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Add New Bin Size
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseAddModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {renderTextField(
                        newBinSize.name,
                        (e) => setNewBinSize({...newBinSize, name: e.target.value}),
                        "Name"
                    )}
                    {renderTextField(
                        newBinSize.description,
                        (e) => setNewBinSize({...newBinSize, description: e.target.value}),
                        "Description"
                    )}
                    {renderTextField(
                        newBinSize.height,
                        (e) => setNewBinSize({...newBinSize, height: e.target.value}),
                        "Height"
                    )}
                    {renderTextField(
                        newBinSize.width,
                        (e) => setNewBinSize({...newBinSize, width: e.target.value}),
                        "Width"
                    )}
                    {renderTextField(
                        newBinSize.length,
                        (e) => setNewBinSize({...newBinSize, length: e.target.value}),
                        "Length"
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddModal}>Cancel</Button>
                    <Button 
                        onClick={handleAdd} 
                        variant="contained" 
                        color="primary"
                        disabled={!newBinSize.name.trim()}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
