import { Button, TextField } from '@mui/material'

import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addDefectSubCategory, getDefectSubCategoryById, updateDefectSubCategory } from 'app/services/defectSubCategory.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddDefect() {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    


    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const id = searchParams.get('id');

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const { data: res } = await getDefectSubCategoryById(id);
                    if (res?.data) {
                        setName(res.data.name);
                        setDescription(res.data.description);
                    }
                } catch (error) {
                    toastError(error);
                }
            }
        };
        fetchData();
    }, [id]);

    

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
            }
            if(id){
                let {data:res}=await updateDefectSubCategory(id,obj);
                if(res){
                    toastSuccess(res.message);
                    navigate('/admin/viewDefectsubcategory')
                }
            }
            else{
                let {data:res}=await addDefectSubCategory(obj);
                if(res){
                    toastSuccess(res.message);
                    navigate('/admin/viewDefectsubcategory')
                }

            }
        } catch (error) {
            toastError(error)
        }
    }


    return (
        <Container>
            <SimpleCard title="Add Defect Sub Category">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />

                    <TextField type="text" name="name" onChange={(e) => setDescription(e.target.value)} value={description || ''} label="Description" sx={{ width: '100%', mt: 2 }} />
                  
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
