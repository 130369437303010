import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addBin, getAllBin } from 'app/services/Bin.service'
import { getAllProduct } from 'app/services/product.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Select from 'react-select';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function BinProductAssign() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [capacity, setCapacity] = useState(0);
    const [isMouldingBin, setIsMoudlingBin] = useState(false)
    const [unit, setUnit] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();


    const [selectedProductArr, setSelectedProductArr] = useState([]);
    const [quantity, setQuantity] = useState(0);

    let [binArr, setBinArr] = useState([]);


    let [productArr, setProductArr] = useState([]);


    const getBinsData = async () => {
        try {
            let { data: res } = await getAllBin();
            if (res) {
                setBinArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const getProductData = async () => {
        try {
            let { data: res } = await getAllProduct();
            if (res) {
                setProductArr(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getBinsData();
        getProductData();
    }, [])


    const handleSubmit = async () => {
        try {

            toastSuccess("Products Successfully Assigned to bin");
            // let obj={
            //     binId,
            //     prod
            // }
            
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="Bin Product Assign">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >

                    <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={binArr.map(el => ({ ...el, label: el.name, value: el._id }))} placeholder="Select Bin" />
                    <br />
                    <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} isMulti options={productArr.map(el => ({ ...el, label: el.name, value: el._id }))} onChange={(e) => setSelectedProductArr(e)} placeholder="Select Product" />

                    {
                        selectedProductArr && selectedProductArr.length > 0 && selectedProductArr.map(el => {
                            return (
                                <>
                                <Typography variant='subtitle1'>Quantity For : {el.label}</Typography>
                                <TextField type="text" name="Quantity"  label="Quantity" sx={{ width: '100%', mt: 2 }} />
                                </>
                            )
                        })
                    }


                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
