import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/mould-setup-and-unloading-checksheet`

export const addMouldSetupAndUnloading = (obj) => {
     return axios.post(`${url}/`, obj)
}

export const getAllMouldSetupAndUnloading = (checkSheetName) => {
     return axios.get(`${url}/`, { params: { checkSheetName } });
 }

export const getProcessParamereByID = (id) => {
     return axios.get(`${url}/getInjectionMouldingById/${id}`)
}


export const deleteMouldSetup = (id) => {
     return axios.delete(`${url}/deleteInjectionMouldingById/${id}`)
}

export const getAllMachine = () => {
     return axios.get(`${url}/getAllMachineFromMouldSetupChecksheet`)
}