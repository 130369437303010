import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/process-parameter-checksheet`

export const addProcessParameter = (obj) => {
     return axios.post(`${url}/`, obj)
}

export const getAllProcessParameter = () => {
     return axios.get(`${url}/`)
}

export const getProcessParamereByID = (id) => {
     return axios.get(`${url}/getInjectionMouldingById/${id}`)
}


export const deleteProcessParameter = (id) => {
     return axios.delete(`${url}/deleteInjectionMouldingById/${id}`)
}

export const getAllMachine = () => {
     return axios.get(`${url}/getAllMachineFromProcessParameterChecksheet`)
}