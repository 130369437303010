import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { styled } from '@mui/system'
import toast from 'react-hot-toast'
import { getAll4MChangeInspectionReport } from 'app/services/fourMChangeInspectionReport.service'

const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

export default function FourMmChangeInspectionReport() {

  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try{
        const res = await getAll4MChangeInspectionReport();
        //console.log(res.data.data, "vfsdjgdfmhdas")
        setData(res.data.data)
      } catch(error){
        toast.error("Not Found")
      }
    }

    fetchData()
  }, [])

  console.log(data, "data")
    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2} sx={{ marginY: 2 }}>
                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={3} align="center">
                                        <img src={'/assets/images/moulding-sheet.png'} alt="logo" />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={10} rowSpan={1} align="center" sx={{ fontWeight: '700', fontSize: '2rem' }}>
                                        DEPT.: PRODUCTION
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>DOC. NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>PRD-F-008</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={10} rowSpan={2} align="center" sx={{ fontWeight: '500', fontSize: '1.5rem' }}>
                                        TITLE : ABNORMAL CONDITION INSPECTION REPORT
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>REV.</StyledTableCell>
                                    <StyledTableCell colSpan={2}>00/10.10.2021</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2}>EFF. DATE</StyledTableCell>
                                    <StyledTableCell colSpan={2}>12-03-25</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell colSpan={1}>DATE:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.dateCompareString}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>SHIFT:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.shift}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>TIME:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.time}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>MACHINE NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.machineNo}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>SECTION</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.section}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1}>PART NAME:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.productName}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>PART NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.productNo}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>SETUP:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUp}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>LOT NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.lotNo}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={4} rowSpan={3} align="center">
                                        Tick '✔' in Appropriate Box
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={3} align="center">
                                        Type of change
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Man
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                            >
                                              {data[0]?.man ? '✔' : ''}
                                            </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Machine
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.machine ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Material
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.material ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Method
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.method ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        Change Condition
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Planned
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.planed ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Unplanned
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.unplaned ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Abnormal
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.abnormal ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3} align="center">
                                        Inspection Type
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Setup
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.setup ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Retroactive
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.retroactive ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>

                                    <StyledTableCell colSpan={2} align="center">
                                        <Box display="flex" alignItems="center" justifyContent="between">
                                            Suspected Lot
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: 'inline-block',
                                                    width: 20,
                                                    height: 20,
                                                    border: '2px solid black',
                                                    marginLeft: 1,
                                                }}
                                                >
                                                {data[0]?.suspectedLot ? '✔' : ''}
                                              </Box>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={14} align="left" header>
                                        Change Details
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={7}>SETUP APPROVAL:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Date & Time:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>MTC NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={2}>
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Parameter
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Specification
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} align="center">
                                        Observation
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell>1</StyledTableCell>
                                    <StyledTableCell>2</StyledTableCell>
                                    <StyledTableCell>3</StyledTableCell>
                                    <StyledTableCell>4</StyledTableCell>
                                    <StyledTableCell>5</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Result</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                  data[0]?.setUpApproval.data?.map((item, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.parameter}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.specification}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.method}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type1}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type2}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type3}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type4}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type5}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.observation.result}</StyledTableCell>
                                      </TableRow>
                                    );
                                  })
                                }
                                <TableRow>
                                    <StyledTableCell colSpan={8}>Remarks(if any)</StyledTableCell>
                                    <StyledTableCell>Checked By:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUpApproval?.checkedBy}</StyledTableCell>
                                    <StyledTableCell>Verified By:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUpApproval?.verifiedBy}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={7}>Dimensional Check : Retroactive</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Date & Time:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>MTC NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={2}>
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Parameter
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Specification
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} align="center">
                                        Observation
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell>1</StyledTableCell>
                                    <StyledTableCell>2</StyledTableCell>
                                    <StyledTableCell>3</StyledTableCell>
                                    <StyledTableCell>4</StyledTableCell>
                                    <StyledTableCell>5</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Result</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                  data[0]?.dimensionalCheckRetroactive.data?.map((item, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.parameter}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.specification}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.method}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type1}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type2}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type3}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type4}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type5}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.observation.result}</StyledTableCell>
                                      </TableRow>
                                    );
                                  })
                                }
                                <TableRow>
                                    <StyledTableCell colSpan={8}>Remarks(if any)</StyledTableCell>
                                    <StyledTableCell>Checked By:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUpApproval?.checkedBy}</StyledTableCell>
                                    <StyledTableCell>Verified By:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUpApproval?.verifiedBy}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={7}>Dimensional Check : Suspected Lot</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Date & Time:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>MTC NO.:</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={1} rowSpan={2}>
                                        Sr. No.
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Parameter
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Specification
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Method
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={7} align="center">
                                        Observation
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell>1</StyledTableCell>
                                    <StyledTableCell>2</StyledTableCell>
                                    <StyledTableCell>3</StyledTableCell>
                                    <StyledTableCell>4</StyledTableCell>
                                    <StyledTableCell>5</StyledTableCell>
                                    <StyledTableCell colSpan={2}>Result</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                  data[0]?.dimensionalCheckSuspectedLot.data?.map((item, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <StyledTableCell colSpan={1}>{index + 1}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.parameter}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.specification}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.method}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type1}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type2}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type3}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type4}</StyledTableCell>
                                        <StyledTableCell>{item.observation.type5}</StyledTableCell>
                                        <StyledTableCell colSpan={2}>{item.observation.result}</StyledTableCell>
                                      </TableRow>
                                    );
                                  })
                                }
                                <TableRow>
                                    <StyledTableCell colSpan={8}>Remarks(if any)</StyledTableCell>
                                    <StyledTableCell>Checked By:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUpApproval?.checkedBy}</StyledTableCell>
                                    <StyledTableCell>Verified By:</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.setUpApproval?.verifiedBy}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={6}>Retroactive Check ( Result of 100% Inspection)</StyledTableCell>
                                    <StyledTableCell colSpan={1}>Lot Qty.</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.retroactiveCheck?.lotQty}</StyledTableCell>
                                    <StyledTableCell colSpan={1}>Inspection Time</StyledTableCell>
                                    <StyledTableCell colSpan={2}>{data[0]?.retroactiveCheck?.inspectionTime}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Total Qty
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2}>OK Qty</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>Rework OK Qty</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>Total Rejct Qty</StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        Rejection Details (Defect vs. Qty)
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2}>New Lot no. Applied on Tag</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>4M tag Attached (Y/N)</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>Sign QA Inspector</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <StyledTableCell colSpan={2}>{data[0]?.retroactiveCheck?.totalQty}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.retroactiveCheck?.okQty}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.retroactiveCheck?.reworkOkQty}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.retroactiveCheck?.totalRejectedQty}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>{data[0]?.retroactiveCheck?.newLotNo}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.retroactiveCheck?.tagAttached}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.retroactiveCheck?.signQAInspector}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto' }}>
                        <Table size="small" aria-label="temperature parameters table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={6}>Suspected Check ( Result of 100% Inspection)</StyledTableCell>
                                    <StyledTableCell colSpan={1}>Lot Qty.</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                    <StyledTableCell colSpan={1}>Inspection Time</StyledTableCell>
                                    <StyledTableCell colSpan={2}></StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={2} rowSpan={2}>
                                        Total Qty
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2}>OK Qty</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>Rework OK Qty</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>Total reject Qty</StyledTableCell>
                                    <StyledTableCell colSpan={4} align="center">
                                        Rejection Details (Defect vs. Qty)
                                    </StyledTableCell>
                                    <StyledTableCell rowSpan={2}>New Lot no. Applied on Tag</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>4M tag Attached (Y/N)</StyledTableCell>
                                    <StyledTableCell rowSpan={2}>Sign QA Inspector</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                <StyledTableCell colSpan={2}>{data[0]?.suspectedLotCheck?.totalQty}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.suspectedLotCheck?.okQty}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.suspectedLotCheck?.reworkOkQty}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.suspectedLotCheck?.totalRejectedQty}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>{data[0]?.suspectedLotCheck?.newLotNo}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.suspectedLotCheck?.tagAttached}</StyledTableCell>
                                    <StyledTableCell>{data[0]?.suspectedLotCheck?.signQAInspector}</StyledTableCell>
                                </TableRow>

                                <TableRow>
                                    <StyledTableCell colSpan={3}>Sign QA:</StyledTableCell>
                                    <StyledTableCell colSpan={3}>{data[0]?.signQA}</StyledTableCell>
                                    <StyledTableCell colSpan={3}>Sign QA Head:</StyledTableCell>
                                    <StyledTableCell colSpan={3}>{data[0]?.signQAHead}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}
