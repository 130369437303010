import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/Bin`

export const addBin = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllBinDataByBinType = (id) => {
    return axios.get(`${url}/getAllBin/${id}`)
}

export const getAllBin = () => {
    return axios.get(`${url}`)
}

export const deleteBin = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getBinByRackId = async (id) => {
    return axios.get(`${url}/getByRackId/${id}`)
}

export const getBinById = async (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const bulkGenerateBin=async(obj)=>{
    return axios.post(`${url}/bulkGenerateBin`,obj)
}

export const getAllBinByBinType = async () => {
    return axios.get(`${url}/getAllBinsWithCountBinType`)
}

export const getBinByBarCode = async (id) => {
    return axios.get(`${url}/getBinByBarCode`, {params: {barcodeId: id}})
}