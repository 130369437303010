import React, { useState, useEffect } from 'react'
import { Box, CardContent, Typography, Grid, TextField, Button, Divider, CardHeader, useTheme, Paper, InputAdornment, Table, TableBody, TableCell, TableRow, TableHead, TableContainer } from '@mui/material'
import { Search, CalendarToday, BookmarkSharp } from '@mui/icons-material'
import Select from 'react-select'
import moment from 'moment'
import { getAllMachine } from 'app/services/machine.service'
import { SHIFT1_TIME, SHIFT2_TIME } from 'app/utils/constant'
import { getProductById } from 'app/services/product.service'
import { addMouldingPlanning } from 'app/services/MouldingPlanning.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { getAllBOM } from 'app/services/BOM.service'
import { useNavigate } from 'react-router-dom'

export default function Production_Planning() {

    const navigate = useNavigate();
    const theme = useTheme()
    const [machine, setMachine] = useState({})
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [machineArr, setMachineArr] = useState([])
    const [selectedMachine, setSelectedMachine] = useState(null)
    const [mouldArr, setMouldArr] = useState([])
    const [mould, setMould] = useState({})
    const [selectedMould, setSelectedMould] = useState(null)
    const [shiftA, setShiftA] = useState('')
    const [shiftB, setShiftB] = useState('')
    const [total, setTotal] = useState('')
    const [hourlyProduction, setHourlyProduction] = useState('')
    const [productionData, setProductionData] = useState({})
    const [bom, setBom] = useState([])
    const [product, setProduct] = useState([])

    useEffect(() => {
        const fetchMachineData = async () => {
            try {
                const res = await getAllMachine()
                setMachine(res.data.data)
                if (res) {
                    const tempArr = res.data.data.map((el) => ({
                        label: el.name.trim(),
                        value: el._id,
                    }))
                    setMachineArr(tempArr)
                } else {
                    setMachineArr([])
                }
            } catch (error) {
                setMachineArr([])
            }
        }
        fetchMachineData()
    }, [])

    useEffect(() => {
        if (selectedMachine?.value && Array.isArray(machine) && machine.length > 0) {
            const matchedMachine = machine.find((m) => m._id.toString() === selectedMachine.value.toString())
            if (matchedMachine?.mouldArr?.length > 0) {
                const formattedMouldArr = matchedMachine.mouldArr.map((item) => ({
                    label: item.mouldingObj?.name || 'Unnamed Mould',
                    value: item.mouldingObj?._id || '',
                    machineNumber:item.identificationNo
                }))
                setMouldArr(formattedMouldArr)
            } else {
                setMouldArr([])
            }
        } else {
            setMouldArr([])
        }
    }, [selectedMachine, machine])

    useEffect(() => {
        if (Array.isArray(machine) && machine.length > 0) {
            const matchedMachine = machine.find((m) => m._id.toString() === selectedMachine.value.toString())
            const mouldData = matchedMachine?.mouldArr
                ?.filter((item) => selectedMould.value === item.mouldingObj?._id)
                .map((item) => item.mouldingObj)
                .filter(Boolean)

            if (!mouldData || mouldData.length === 0) {
                setMould({})
                return
            }

            setMould(mouldData)
        }
    }, [selectedMould])

    console.log(mould, 'mould')

    const parseTime = (time) => {
        if (!time) return 0
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 60 + minutes
    }

    const handleSearch = async () => {
        const dates = []
        let currentDate = moment(startDate)
        while (currentDate <= moment(endDate)) {
            dates.push(currentDate.format('YYYY-MM-DD'))
            currentDate = currentDate.add(1, 'days')
        }

        const data = []

        const productArr = []

        for (const el of mould[0].productArr) {
            try {
                const res = await getProductById(el.productId)
                if (res && res.data) {
                    productArr.push(res.data.data)
                    setProduct(prev => [...prev, res.data.data])
                }
            } catch (error) {
                console.error(`Error fetching product with ID ${el.productId}:`, error)
            }
        }


        try {
            const res = await getAllBOM();
            if (res && res.data) {
                const filteredBOM = productArr.flatMap((product) =>
                    res.data.data.filter((item) =>
                        item.productId?.toString() === product._id?.toString() &&
                        item.category?.trim().toUpperCase() === 'MOULDING'
                    )
                );
        
                setBom((prevBom) => [...prevBom, ...filteredBOM]);
            }
        } catch (error) {
            console.error('Error fetching BOM data:', error);
        }
        
        let shiftA = 0
        let shiftB = 0
        let total = 0
        let hourlyProduction = 0

        const cycleTime = mould[0]?.cycleTime
        if (cycleTime) {
            let start = parseTime(startTime)
            let end = parseTime(endTime)

            if (start >= SHIFT1_TIME.shiftStartTime && start < SHIFT1_TIME.shiftEndTime) {
                if (end <= SHIFT1_TIME.shiftEndTime) {
                    let timeRange = Math.abs(end - start)
                    shiftA = (timeRange * 60) / cycleTime
                    hourlyProduction = 3600 / cycleTime
                } else {
                    alert('Please choose the correct timing as it is going beyond the time limit.')
                }
            } else {
                let adjustedStart = start >= SHIFT2_TIME.shiftStartTime ? start : start + 1440
                let adjustedEnd = end > adjustedStart ? end : end + 1440

                let timeRange = Math.abs(adjustedEnd - adjustedStart)
                shiftB = (timeRange * 60) / cycleTime
                hourlyProduction = 3600 / cycleTime
            }

            total = shiftA + shiftB
        }

        const mappedDates = dates.map((date) => ({
            date,
            product: productArr.length ? productArr.map((product) => product?.name || 'Unnamed Product') : ['Unnamed Product'],
            shiftA,
            shiftB,
            total,
            hourlyProduction,
            grandTotal: total * productArr.length,
        }))

        let totalProduction = 0
        totalProduction = mappedDates.reduce((acc, date) => acc + date.grandTotal, 0)

        data.push({
            ...mould,
            total: totalProduction,
            dates: mappedDates,
        })

        setProductionData(data)
    }

    const handleSubmit = async () => {
        try {
            const selectedMachineId = selectedMachine?.value;
            const selectedMachineData = machine.find((m) => m._id.toString() === selectedMachineId?.toString());
            console.log(selectedMachineData,"SELECTED MACHINE DATA")
            const obj = {
                productionArr: productionData?.map((el) => ({
                    productObj: product?.map((item) => ({
                        productId: item?._id,
                        productName: item?.name,
                    })),

                    mouldId: mould[mould.length-1]?._id,

                    datesArr: el?.dates?.map((elx) => ({
                        date: elx.date,
                        startTime,
                        endTime,
                        machineObj: {
                            machineId: selectedMachineId,
                            name: selectedMachineData?.name || 'Unnamed Machine',
                            machineNumber:selectedMachineData?.identificationNo,
                            loadingCapacity: selectedMachineData?.loadingCapacity || 0,
                        },
                        shiftA: Number(elx.shiftA),
                        shiftB: Number(elx.shiftB),
                        hourlyProduction: Number(elx.hourlyProduction),
                        totalProduction: Number(elx.total),
                    })),
                })),

    
               
            };
    
            console.log(obj, 'Obj');
    
             const { data: res } = await addMouldingPlanning(obj);
             if (res) {
                 navigate('/admin/ViewProductionPlanning')
                 toastSuccess(res.message);
             }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            toastError(error?.message || 'An error occurred while submitting data');
        }
    };
    
    

    return (
        <Box sx={{ p: 2 }}>
            <Paper
                elevation={3}
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: 6,
                    },
                }}
            >
                <CardContent sx={{ p: 3 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <CalendarToday fontSize="small" />
                        Select Date Range
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={6} md={3}>
                            <TextField
                                fullWidth
                                type="date"
                                onChange={(e) => setStartDate(e.target.value)}
                                value={startDate}
                                label="Start Date"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarToday color="primary" fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': { borderColor: 'primary.main' },
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                fullWidth
                                type="date"
                                onChange={(e) => setEndDate(e.target.value)}
                                value={endDate}
                                label="End Date"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarToday color="primary" fontSize="small" />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': { borderColor: 'primary.main' },
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div style={{ paddingTop: '24px' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 600,
                                        letterSpacing: '0.5px',
                                        fontSize: '0.9rem',
                                        marginBottom: '8px',
                                        color: '#333',
                                    }}
                                >
                                    Select Machine
                                </Typography>
                                <Select
                                    value={selectedMachine}
                                    options={machineArr}
                                    placeholder="Select a machine"
                                    onChange={(e) => setSelectedMachine(e)}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            height: '56px',
                                            borderRadius: '4px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#1976d2',
                                            },
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        placeholder: (base) => ({
                                            ...base,
                                            color: '#757575',
                                        }),
                                    }}
                                    menuPortalTarget={document.body}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 600,
                                        letterSpacing: '0.5px',
                                        fontSize: '0.9rem',
                                        marginBottom: '8px',
                                        color: '#333',
                                    }}
                                >
                                    Select Mould
                                </Typography>
                                <Select
                                    value={selectedMould}
                                    options={mouldArr}
                                    placeholder="Select Mould"
                                    onChange={(e) => setSelectedMould(e)}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            height: '56px',
                                            borderRadius: '4px',
                                            boxShadow: 'none',
                                            '&:hover': {
                                                borderColor: '#1976d2',
                                            },
                                        }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        placeholder: (base) => ({
                                            ...base,
                                            color: '#757575',
                                        }),
                                    }}
                                    menuPortalTarget={document.body}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 600,
                                        letterSpacing: '0.5px',
                                        fontSize: '0.9rem',
                                        marginBottom: '8px',
                                        color: '#333',
                                    }}
                                >
                                    Select Start Time
                                </Typography>
                                <TextField
                                    required
                                    placeholder="From"
                                    type="time"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        style: { height: '56px' },
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: 600,
                                        letterSpacing: '0.5px',
                                        fontSize: '0.9rem',
                                        marginBottom: '8px',
                                        color: '#333',
                                    }}
                                >
                                    Select End Time
                                </Typography>
                                <TextField
                                    required
                                    placeholder="To"
                                    type="time"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        style: { height: '56px' },
                                    }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <Divider sx={{ my: 3 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => handleSearch()}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<Search />}
                            sx={{
                                px: 4,
                                py: 1,
                                borderRadius: 2,
                                boxShadow: 2,
                                transition: 'all 0.3s',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: 4,
                                },
                            }}
                        >
                            Search
                        </Button>
                    </Box>

                    <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>
                        Mould: {mould[0]?.name}
                    </Typography>

                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Cycle Time: {mould[0]?.cycleTime}
                    </Typography>

                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 3 }}>
                        Total Product : {productionData[0]?.total}
                    </Typography>

                    <TableContainer sx={{ maxHeight: 450, overflow: 'auto', mt: 4 }}>
                        <Table
                            sx={{
                                minWidth: 650,
                                borderCollapse: 'separate',
                                borderSpacing: 0,
                                boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                            aria-label="production data table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            padding: '16px',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderRight: '1px solid #e0e0e0',
                                            borderBottom: '2px solid #1976d2',
                                        }}
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            padding: '16px',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderRight: '1px solid #e0e0e0',
                                            borderBottom: '2px solid #1976d2',
                                        }}
                                    >
                                        Product
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            padding: '16px',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderRight: '1px solid #e0e0e0',
                                            borderBottom: '2px solid #1976d2',
                                        }}
                                    >
                                        Hourly Production
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            padding: '16px',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderRight: '1px solid #e0e0e0',
                                            borderBottom: '2px solid #1976d2',
                                        }}
                                    >
                                        Shift A
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            padding: '16px',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderRight: '1px solid #e0e0e0',
                                            borderBottom: '2px solid #1976d2',
                                        }}
                                    >
                                        Shift B
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            padding: '16px',
                                            fontWeight: 700,
                                            fontSize: '1rem',
                                            borderBottom: '2px solid #1976d2',
                                        }}
                                    >
                                        Total Plan Qty
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(productionData) &&
                                    productionData.map((el) => (
                                        <React.Fragment key={el._id}>
                                            {el.dates.map((dateData) =>
                                                dateData?.product?.map((productName, index) => (
                                                    <TableRow
                                                        key={`${dateData.date}-${index}`}
                                                        sx={{
                                                            '&:nth-of-type(odd)': {
                                                                backgroundColor: '#f9f9f9',
                                                            },
                                                            '&:hover': {
                                                                backgroundColor: '#f0f7ff',
                                                                transition: 'background-color 0.2s ease',
                                                            },
                                                            borderBottom: '1px solid #e0e0e0',
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{
                                                                padding: '16px',
                                                                fontWeight: 500,
                                                                borderRight: '1px solid #f0f0f0',
                                                            }}
                                                        >
                                                            {dateData?.date}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                padding: '16px',
                                                                fontWeight: 500,
                                                                color: productName ? '#333' : '#999',
                                                                borderRight: '1px solid #f0f0f0',
                                                            }}
                                                        >
                                                            {productName || 'Unnamed Product'}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                padding: '16px',
                                                                fontWeight: 600,
                                                                color: dateData?.shiftA > 0 ? '#1976d2' : '#666',
                                                                borderRight: '1px solid #f0f0f0',
                                                            }}
                                                        >
                                                            {dateData?.hourlyProduction}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                padding: '16px',
                                                                fontWeight: 600,
                                                                color: dateData?.shiftA > 0 ? '#1976d2' : '#666',
                                                                borderRight: '1px solid #f0f0f0',
                                                            }}
                                                        >
                                                            {dateData?.shiftA}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                padding: '16px',
                                                                fontWeight: 600,
                                                                color: dateData?.shiftB > 0 ? '#1976d2' : '#666',
                                                                borderRight: '1px solid #f0f0f0',
                                                            }}
                                                        >
                                                            {dateData?.shiftB}
                                                        </TableCell>
                                                        <TableCell
                                                            align="center"
                                                            sx={{
                                                                padding: '16px',
                                                                fontWeight: 700,
                                                                fontSize: '1.05rem',
                                                                color: dateData.total > 0 ? '#1565c0' : '#666',
                                                            }}
                                                        >
                                                            {dateData.total || 0}
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )}
                                        </React.Fragment>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                        <Button
                            onClick={() => handleSubmit()}
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{
                                px: 4,
                                py: 1,
                                borderRadius: 2,
                                boxShadow: 2,
                                transition: 'all 0.3s',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: 4,
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                </CardContent>
            </Paper>
        </Box>
    )
}
