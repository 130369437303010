import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, Typography, Box, Paper, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Container } from '@mui/material'
import { CATEGORY } from 'app/utils/constant'
import { SimpleCard } from 'app/components'
import Select from 'react-select'
import { getAllProduct } from '../../../services/product.service'
import { getAllAssemblyProduct } from 'app/services/AssemblyProducts.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { getBOMByProductId } from 'app/services/BOM.service'
import { getAssemblyBOMById } from 'app/services/AssemblyBOM.service'
import { getAllPlatingCategory } from 'app/services/platingCategory.service'
import { use } from 'echarts'
import { ca } from 'date-fns/locale'

const Overview = () => {
    const [product, setProduct] = useState([])
    const [productArr, setProductArr] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedProductDetails, setSelectedProductDetails] = useState(null)
    const [productError, setProductError] = useState(false)
    const [bom, setBom] = useState([])
    const [finalBOM, setFinalBOM] = useState([])
    const [isFinalProduct, setIsFinalProduct] = React.useState(false)
    const [platingArr, setPlatingArr] = useState([])

    const handleFinalProductChange = (event) => {
        setIsFinalProduct(event.target.checked)
    }

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                let products = []

                if (isFinalProduct) {
                    const { data: res } = await getAllAssemblyProduct()
                    products = res?.data || []
                } else {
                    const { data: res } = await getAllProduct()
                    products = res?.data || []
                }

                setProduct(products)
                console.log(products, 'products')

                if (products.length) {
                    const tempArr = products.map((el) => ({
                        label: el.name,
                        value: el._id,
                        description: el.description,
                        partNo: el.id,
                    }))
                    setProductArr(tempArr)
                }
            } catch (error) {
                toastError(error)
            }
        }

        fetchProducts()
    }, [isFinalProduct])

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption)
        // console.log(selectedOption,"@@")

        let tempProductIndex = productArr.findIndex((elx) => elx.value == selectedOption?.value)
        //console.log(tempProductIndex, '@@', productArr[tempProductIndex])
        if (tempProductIndex != -1) {
            setSelectedProductDetails(productArr[tempProductIndex])
        }
        setProductError(false)
    }

    useEffect(() => {
        const fetchBOMForFinalProduct = async () => {
            try {
                const filteredProduct = product.find((p) => p._id === selectedProduct?.value)
                console.log(filteredProduct, 'filteredProduct')

                if (!filteredProduct?.productArr?.length) return
                setBom([])

                const { data: res } = await getAssemblyBOMById(filteredProduct._id)
                setFinalBOM([res])
            } catch (error) {
                toastError(error)
            }
        }

        const fetchBOMForProduct = async () => {
            try {
                const { data: res } = await getBOMByProductId(selectedProductDetails?.value)
                if (res) {
                    setBom(res.data)
                }
            } catch (error) {
                toastError(error)
            }
        }

        if (isFinalProduct) {
            fetchBOMForFinalProduct()
        } else if (selectedProductDetails) {
            fetchBOMForProduct()
        }
    }, [isFinalProduct, selectedProduct, selectedProductDetails, product])

   useEffect(() => {
       const fetchPlatingCategory = async () => {
           try {
               const { data: res } = await getAllPlatingCategory()
               setPlatingArr(res?.data || [])
           } catch (error) {
               toastError(error)
           }    
       }

       fetchPlatingCategory()
   }, [])

    return (
        <SimpleCard>
            <h3>Category:</h3>
            <div>
                <FormControlLabel control={<Checkbox checked={isFinalProduct} onChange={handleFinalProductChange} />} label="Final Product" />
            </div>

            <div className="form-row mt-2">
                <h4 className="form-label-cell">PART NAME:</h4>
                <div className="form-value-cell">
                    <Select
                        value={selectedProduct}
                        options={productArr}
                        placeholder="Select Product"
                        onChange={handleProductChange}
                        styles={{
                            control: (base) => ({
                                ...base,
                                borderColor: productError ? 'red' : base.borderColor,
                            }),
                            container: (base) => ({
                                ...base,
                                width: '100%',
                            }),
                        }}
                    />
                    {productError && <Typography color="error">Please select a product</Typography>}
                </div>
            </div>

            {isFinalProduct === false && (
                <Box sx={{ maxWidth: 1000, mx: 'auto', my: 4 }}>
                    {/* Header */}
                    <Box
                        sx={{
                            bgcolor: '#ffcdb2',
                            p: 1,
                            textAlign: 'center',
                            mb: 1,
                            borderRadius: '4px 4px 0 0',
                        }}
                    >
                        <Typography variant="h5" component="h1" fontWeight="bold">
                            BILL OF MATERIAL
                        </Typography>
                    </Box>

                    {bom &&
                        bom?.map((mouldBOM) => (
                            <TableContainer component={Paper} elevation={3} sx={{ mb: 4, width: '100%' }}>
                                <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                                    <TableBody>
                                        {/* Part Name and Number */}
                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                            <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold">
                                                    PART NAME:
                                                </Typography>
                                                <Typography>{mouldBOM?.productName || 'YTA CHROME BSM ASSEMBLED SET'}</Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold">
                                                    PART NO.
                                                </Typography>
                                                <Typography>{mouldBOM?.partNo || 'BPA003288'}</Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                            <TableCell colSpan={4} sx={{ p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold">
                                                    ADDITIONAL DESCRIPTION:
                                                </Typography>
                                                <Typography>{mouldBOM?.additionalInfo || '-------'}</Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                            <TableCell colSpan={2} sx={{ p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold">
                                                    CATEGORY:
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography sx={{ flexGrow: 1 }}>{mouldBOM?.category || 'FINISHED GOOD'}</Typography>
                                                </Box>
                                            </TableCell>

                                            {mouldBOM?.category === 'PLATING' && (
                                                <TableCell colSpan={2} sx={{ p: 1 }}>
                                                    <Typography variant="subtitle2" fontWeight="bold">
                                                        PLATING
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography
                                                            sx={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                gap: '4px',
                                                            }}
                                                        >
                                                            {mouldBOM?.platingCategory?.map((el, index, arr) => (
                                                                <span key={index}>
                                                                    {el}
                                                                    {index < arr.length - 1 && ','}
                                                                </span>
                                                            ))}
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                            )}
                                        </TableRow>

                                        {/* HSN Code and GST Rate */}
                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    HSN CODE:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.hsnCode || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    UNIT:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.unit || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    PACKING STANDARD:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.packingStandard || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    ULTERNET UNIT:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.ulternetUnit || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    MACHINE:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.machineName || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        {/* <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    PART CAVAITY:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.cavity || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow> */}

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    MASTER BATCH:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.masterBatch || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    GROSS WEIGHT:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.grossWeight || ''} {mouldBOM?.weightUnit || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    PART WEIGHT:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.partWeight || ''} {mouldBOM?.weightUnit || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                    RUNNER WEIGHT:
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                    {mouldBOM?.runnerWeight || ''} {mouldBOM?.weightUnit || ''}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>

                                        {mouldBOM?.mouldingRawMaterialArr && mouldBOM.mouldingRawMaterialArr.length > 0 && (
                                            <>
                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                    <TableCell colSpan={6} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                            RAW MATERIALS
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                {mouldBOM.mouldingRawMaterialArr.map((item, index) => (
                                                    <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                {item.rawMaterialName}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                {item?.rawMaterialQty || ''} {item?.unit || ''}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </>
                                        )}

                                        {Array.isArray(mouldBOM?.mouldingRawMaterialSubCategoryArr) && mouldBOM.mouldingRawMaterialSubCategoryArr.length > 0 && (
                                            <>
                                                {/* <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                    <TableCell colSpan={6} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                            SUBCATEGORY RAW MATERIALS
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow> */}
                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                    <TableCell colSpan={1} sx={{ p: 1 }}>
                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}></Typography>
                                                    </TableCell>

                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                            NAME & SIZE
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                            QTY
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                {Array.isArray(mouldBOM?.mouldingRawMaterialSubCategoryArr) &&
                                                    mouldBOM.mouldingRawMaterialSubCategoryArr.map((item, index) => (
                                                        <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                            <TableCell colSpan={1} sx={{ width: '50%', p: 1, bgcolor: '#f5f5f5' }}>
                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                    {item.subCategoryName}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                    {item.rawMaterialName}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                    {item.subCategoryQty} {item.unit}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ))}
                </Box>
            )}

            {isFinalProduct && (
                <Box sx={{ maxWidth: 1400, mx: 'auto', my: 4 }}>
                    {/* Header */}
                    <Box
                        sx={{
                            bgcolor: '#ffcdb2',
                            p: 1,
                            textAlign: 'center',
                            mb: 1,
                            borderRadius: '4px 4px 0 0',
                        }}
                    >
                        <Typography variant="h5" component="h1" fontWeight="bold">
                            BILL OF MATERIAL
                        </Typography>
                    </Box>
                    {Array.isArray(finalBOM) &&
                        finalBOM?.map((bomItem, indexBOM) =>
                            bomItem.data?.map((mouldBOM, index) => {
                                return (
                                    <>
                                        <TableContainer key={index} component={Paper} elevation={3} sx={{ mb: 4, width: '100%' }}>
                                            <Table size="large" sx={{ tableLayout: 'fixed', width: '100%' }}>
                                                <TableBody>
                                                    {/* Part Name and Number */}
                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                PRODUCT NAME:
                                                            </Typography>
                                                            <Typography>{mouldBOM?.productName || ''}</Typography>
                                                        </TableCell>
                                                        <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                ASSEMBLY NO.
                                                            </Typography>
                                                            <Typography>{mouldBOM?.assemblyNo || ''}</Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={4} sx={{ p: 1 }}>
                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                CATEGORY:
                                                            </Typography>
                                                            <Typography>{mouldBOM?.category || ''}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={4} sx={{ p: 1, width: '100%' }}>
                                                            <Box sx={{ marginBottom: 2, width: '100%', display: 'grid', placeItems: 'start', gridTemplateColumns: 'repeat(1, 1fr)', gap: 2 }}>
                                                                <Box sx={{ marginY: 4, width: '100%', display: 'grid', placeItems: 'start', gridTemplateColumns: 'repeat(1, 1fr)', gap: 2 }}>
                                                                    {mouldBOM.productsArr?.map((bomItem, bomIndex) => (
                                                                        <Box
                                                                            key={bomIndex}
                                                                            sx={{ bgcolor: '#FAF1E6', p: 1, borderRadius: 4, width: '100%', display: 'grid', placeItems: 'start', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, padding: 2 }}
                                                                        >
                                                                            {bomItem?.boms?.map((bom, index) => (
                                                                                <>
                                                                                    {bom.productName && index === 0 && (
                                                                                        <Typography variant="h6" fontWeight="bold" sx={{ textAlign: 'center', gridColumn: '1 / -1', textTransform: 'uppercase' }} gutterBottom>
                                                                                            {bom?.productName}
                                                                                        </Typography>
                                                                                    )}

                                                                                    <TableContainer component={Paper} elevation={3} sx={{ mb: 4, width: '100%' }}>
                                                                                        <Table size="small" sx={{ tableLayout: 'fixed', width: '100%' }}>
                                                                                            <TableHead>
                                                                                                <TableRow sx={{ '& th': { border: '1px solid #e0e0e0' } }}>
                                                                                                    <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#ffcdb2' }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                                            {bom?.category} : {bom?.productName}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                                            PART NAME:
                                                                                                        </Typography>
                                                                                                        <Typography>{bom?.productName || 'YTA CHROME BSM ASSEMBLED SET'}</Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                                            PART NO.
                                                                                                        </Typography>
                                                                                                        <Typography>{bom?.partNo || 'BPA003288'}</Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                    <TableCell colSpan={4} sx={{ p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                                            ADDITIONAL DESCRIPTION:
                                                                                                        </Typography>
                                                                                                        <Typography>{bom?.additionalInfo || '-------'}</Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                    <TableCell colSpan={2} sx={{ p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                                            CATEGORY:
                                                                                                        </Typography>
                                                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            <Typography sx={{ flexGrow: 1 }}>{bom?.category || 'FINISHED GOOD'}</Typography>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    {bom?.category === 'PLATING' && (
                                                                                                        <TableCell colSpan={2} sx={{ p: 1 }}>
                                                                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                                                                PLATING
                                                                                                            </Typography>
                                                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                <Typography
                                                                                                                    sx={{
                                                                                                                        display: 'flex',
                                                                                                                        flexWrap: 'wrap',
                                                                                                                        gap: '4px',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {bom?.platingCategory?.map((el, index, arr) => (
                                                                                                                        <span key={index}>
                                                                                                                            {platingArr.find((item) => item._id === el)?.name}
                                                                                                                            {index < arr.length - 1 && ','}
                                                                                                                        </span>
                                                                                                                    ))}
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </TableCell>
                                                                                                    )}
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            HSN CODE:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.hsnCode || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            UNIT:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.unit || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            PACKING STANDARD:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.packingStandard || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            ULTERNET UNIT:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.ulternetUnit || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            MACHINE:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.machineName || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            MASTER BATCH:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.masterBatch || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            GROSS WEIGHT:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.grossWeight || ''} {bom?.weightUnit || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            PART WEIGHT:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.partWeight || ''} {bom?.weightUnit || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0', marginTop: 2 } }}>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            RUNNER WEIGHT:
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={2} sx={{ width: '45%', p: 1 }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                            {bom?.runnerWeight || ''} {bom?.weightUnit || ''}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                {bom?.mouldingRawMaterialArr && bom.mouldingRawMaterialArr.length > 0 && (
                                                                                                    <>
                                                                                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                            <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#f5f5f5' }}>
                                                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                                                    RAW MATERIALS
                                                                                                                </Typography>
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                        {bom.mouldingRawMaterialArr.map((item, index) => (
                                                                                                            <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                                <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                                                    <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                                        {item.rawMaterialName}
                                                                                                                    </Typography>
                                                                                                                </TableCell>

                                                                                                                <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                                                    <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                                        {item?.rawMaterialQty || ''} {item?.unit || ''}
                                                                                                                    </Typography>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        ))}
                                                                                                    </>
                                                                                                )}

                                                                                                {Array.isArray(bom?.mouldingRawMaterialSubCategoryArr) && bom.mouldingRawMaterialSubCategoryArr.length > 0 && (
                                                                                                    <>
                                                                                                        <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                            <TableCell colSpan={1} sx={{ p: 1 }}>
                                                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}></Typography>
                                                                                                            </TableCell>

                                                                                                            <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                                    NAME & SIZE
                                                                                                                </Typography>
                                                                                                            </TableCell>

                                                                                                            <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                                    QTY
                                                                                                                </Typography>
                                                                                                            </TableCell>
                                                                                                        </TableRow>

                                                                                                        {Array.isArray(bom?.mouldingRawMaterialSubCategoryArr) &&
                                                                                                            bom.mouldingRawMaterialSubCategoryArr.map((item, index) => (
                                                                                                                <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                                    <TableCell colSpan={1} sx={{ width: '50%', p: 1, bgcolor: '#f5f5f5' }}>
                                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                                            {item.subCategoryName}
                                                                                                                        </Typography>
                                                                                                                    </TableCell>

                                                                                                                    <TableCell colSpan={2} sx={{ width: '50%', p: 1 }}>
                                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                                            {item.rawMaterialName}
                                                                                                                        </Typography>
                                                                                                                    </TableCell>

                                                                                                                    <TableCell colSpan={1} sx={{ width: '50%', p: 1 }}>
                                                                                                                        <Typography variant="subtitle2" fontWeight="normal" sx={{ textAlign: 'left' }}>
                                                                                                                            {item.subCategoryQty} {item.unit}
                                                                                                                        </Typography>
                                                                                                                    </TableCell>
                                                                                                                </TableRow>
                                                                                                            ))}
                                                                                                    </>
                                                                                                )}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </>
                                                                            ))}

                                                                            <TableContainer component={Paper} sx={{ mt: 2, width: '100%' }}>
                                                                                <Table sx={{ width: '100%' }}>
                                                                                    <TableHead>
                                                                                        <TableRow sx={{ '& th': { border: '1px solid #e0e0e0' } }}>
                                                                                            <TableCell colSpan={8} sx={{ p: 1, bgcolor: '#ffcdb2' }}>
                                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                                    RAW MATERIALS
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {Array.isArray(bomItem?.rawMaterialArr) && bomItem.rawMaterialArr.length > 0 && (
                                                                                            <>
                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                    <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#f5f5f5', textAlign: 'center', width: '50%' }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                                            RAW MATERIALS
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#f5f5f5', textAlign: 'center', width: '50%' }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                                            QTY
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                                {bomItem.rawMaterialArr.map((item, index) => (
                                                                                                    <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                        <TableCell colSpan={4} sx={{ width: '50%', p: 1 }}>
                                                                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                                {item?.rawMaterialName || 'N/A'}
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                        <TableCell colSpan={4} sx={{ width: '50%', p: 1 }}>
                                                                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                                {item?.rawMaterialQty || 'N/A'} {item?.unit || 'N/A'}
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                            </>
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>

                                                                            <TableContainer component={Paper} sx={{ mt: 2, width: '100%' }}>
                                                                                <Table sx={{ width: '100%' }}>
                                                                                    <TableHead>
                                                                                        <TableRow sx={{ '& th': { border: '1px solid #e0e0e0' } }}>
                                                                                            <TableCell colSpan={3} sx={{ p: 1, bgcolor: '#ffcdb2' }}>
                                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                                    RAW MATERIAL SUB-CATEGORY
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {Array.isArray(bomItem?.rawMaterialSubCategoryArr) && bomItem?.rawMaterialSubCategoryArr?.length > 0 && (
                                                                                            <>
                                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                    <TableCell sx={{ p: 1, width: '35%' }}></TableCell>
                                                                                                    <TableCell sx={{ p: 1, width: '40%' }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            NAME & SIZE
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell sx={{ p: 1, width: '20%' }}>
                                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                            QTY
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                {bomItem?.rawMaterialSubCategoryArr?.map((item, index) => (
                                                                                                    <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                                        <TableCell
                                                                                                            sx={{
                                                                                                                width: '35%',
                                                                                                                p: 1,
                                                                                                                bgcolor: '#f5f5f5',
                                                                                                                whiteSpace: 'nowrap',
                                                                                                                overflow: 'hidden',
                                                                                                                textOverflow: 'ellipsis',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                                                                {item?.subCategoryName || 'N/A'}
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                        <TableCell
                                                                                                            sx={{
                                                                                                                width: '40%',
                                                                                                                p: 1,
                                                                                                                whiteSpace: 'nowrap',
                                                                                                                overflow: 'hidden',
                                                                                                                textOverflow: 'ellipsis',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography variant="subtitle2" fontWeight="normal">
                                                                                                                {item?.rawMaterialName || 'N/A'}
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                        <TableCell
                                                                                                            sx={{
                                                                                                                width: '20%',
                                                                                                                p: 1,
                                                                                                                whiteSpace: 'nowrap',
                                                                                                                overflow: 'hidden',
                                                                                                                textOverflow: 'ellipsis',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography variant="subtitle2" fontWeight="normal">
                                                                                                                {item?.subCategoryQty ? `${item.subCategoryQty} ${item.unit || ''}` : 'N/A'}
                                                                                                            </Typography>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                ))}
                                                                                            </>
                                                                                        )}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </Box>
                                                                    ))}
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={4} sx={{ p: 1 }}>
                                                            <TableContainer component={Paper} sx={{ mt: 2, width: '100%' }}>
                                                                <Table sx={{ width: '100%' }}>
                                                                    <TableHead>
                                                                        <TableRow sx={{ '& th': { border: '1px solid #e0e0e0' } }}>
                                                                            <TableCell colSpan={8} sx={{ p: 1, bgcolor: '#ffcdb2' }}>
                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                    RAW MATERIALS
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {Array.isArray(mouldBOM?.generalRawMaterialArr) && mouldBOM.generalRawMaterialArr.length > 0 && (
                                                                            <>
                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                    <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#f5f5f5', textAlign: 'center', width: '50%' }}>
                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                            RAW MATERIALS
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell colSpan={4} sx={{ p: 1, bgcolor: '#f5f5f5', textAlign: 'center', width: '50%' }}>
                                                                                        <Typography variant="subtitle2" fontWeight="bold">
                                                                                            QTY
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                {mouldBOM.generalRawMaterialArr.map((item, index) => (
                                                                                    <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                        <TableCell colSpan={4} sx={{ width: '50%', p: 1 }}>
                                                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                {item?.rawMaterialName || 'N/A'}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell colSpan={4} sx={{ width: '50%', p: 1 }}>
                                                                                            <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                                {item?.rawMaterialQty || 'N/A'} {item?.unit || 'N/A'}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                        <TableCell colSpan={4} sx={{ p: 1 }}>
                                                            <TableContainer component={Paper} sx={{ mt: 2, width: '100%' }}>
                                                                <Table sx={{ width: '100%' }}>
                                                                    <TableHead>
                                                                        <TableRow sx={{ '& th': { border: '1px solid #e0e0e0' } }}>
                                                                            <TableCell colSpan={3} sx={{ p: 1, bgcolor: '#ffcdb2' }}>
                                                                                <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'center' }}>
                                                                                    RAW MATERIAL SUB-CATEGORY
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {Array.isArray(mouldBOM?.generalRawMaterialSubCategoryArr) && mouldBOM?.generalRawMaterialSubCategoryArr?.length > 0 && (
                                                                            <>
                                                                                <TableRow sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                    <TableCell sx={{ p: 1, width: '35%' }}></TableCell>
                                                                                    <TableCell sx={{ p: 1, width: '40%' }}>
                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                            NAME & SIZE
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell sx={{ p: 1, width: '20%' }}>
                                                                                        <Typography variant="subtitle2" fontWeight="bold" sx={{ textAlign: 'left' }}>
                                                                                            QTY
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>

                                                                                {mouldBOM?.generalRawMaterialSubCategoryArr?.map((item, index) => (
                                                                                    <TableRow key={index} sx={{ '& td, & th': { border: '1px solid #e0e0e0' } }}>
                                                                                        <TableCell
                                                                                            sx={{
                                                                                                width: '35%',
                                                                                                p: 1,
                                                                                                bgcolor: '#f5f5f5',
                                                                                                whiteSpace: 'nowrap',
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                            }}
                                                                                        >
                                                                                            <Typography variant="subtitle2" fontWeight="bold">
                                                                                                {item?.subCategoryName || 'N/A'}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            sx={{
                                                                                                width: '40%',
                                                                                                p: 1,
                                                                                                whiteSpace: 'nowrap',
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                            }}
                                                                                        >
                                                                                            <Typography variant="subtitle2" fontWeight="normal">
                                                                                                {item?.rawMaterialName || 'N/A'}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            sx={{
                                                                                                width: '20%',
                                                                                                p: 1,
                                                                                                whiteSpace: 'nowrap',
                                                                                                overflow: 'hidden',
                                                                                                textOverflow: 'ellipsis',
                                                                                            }}
                                                                                        >
                                                                                            <Typography variant="subtitle2" fontWeight="normal">
                                                                                                {item?.subCategoryQty ? `${item.subCategoryQty} ${item.unit || ''}` : 'N/A'}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </>
                                )
                            })
                        )}
                </Box>
            )}
        </SimpleCard>
    )
}

export default Overview
