import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/MouldingPlanning`

export const addMouldingPlanning = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getProductionPlanning=()=>{
    return axios.get(`${url}/`)
}

export const exportProductionPlansToExcel=(query)=>{
    return window.open(`${url}/exportMouldingPlanningToExcel?${query}`)
}

export const getActualPlannedData=(query)=>{
    return axios.get(`${url}/getActualVsPlanned?${query}`)
}

export const getDeletePlannedData = (query) => {
    return axios.delete(`${url}/deleteById/${query}`)
}

export const getProductionPlanningByDate = (date) => {
    return axios.get(`${url}/getMouldingPlanningByDate`, { params: { date } })
}
