import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { deleteBinType, getAllBinType, updateBinType, addBinType } from 'app/services/BinType.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    }
}))

export default function ViewBinType() {
    const [binTypes, setBinTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [selectedBinType, setSelectedBinType] = useState(null)
    const [newBinType, setNewBinType] = useState({ name: '', description: '' })
    const navigate = useNavigate()

    const handleEditClick = (row) => {
        setSelectedBinType(row)
        setOpenEditModal(true)
    }

    const handleCloseModal = () => {
        setOpenEditModal(false)
        setSelectedBinType(null)
    }

    const handleUpdate = async () => {
        try {
            await updateBinType(selectedBinType._id, {
                name: selectedBinType.name,
                description: selectedBinType.description
            })
            getBinTypes()
            handleCloseModal()
        } catch (error) {
            toastError(error)
        }
    }

    const handleOpenAddModal = () => {
        setOpenAddModal(true)
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false)
        setNewBinType({ name: '', description: '' })
    }

    const handleAdd = async () => {
        try {
            await addBinType(newBinType)
            getBinTypes()
            handleCloseAddModal()
        } catch (error) {
            toastError(error)
        }
    }

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '80px',
            center: true
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            searchable: true,
            center: true
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: true,
            searchable: true,
            center: true
        },
        {
            name: 'Created At',
            selector: (row) => moment(row.createdAt).format('DD-MM-YYYY HH:mm'),
            sortable: true,
            center: true
        },
        {
            name: 'Updated At',
            selector: (row) => moment(row.updatedAt).format('DD-MM-YYYY HH:mm'),
            sortable: true,
            center: true
        },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditClick(row)}
                        sx={{ mr: 1 }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDelete(row._id)}
                    >
                        Delete
                    </Button>
                </>
            ),
            center: true
        }
    ]

    const getBinTypes = async () => {
        setLoading(true)
        try {
            let { data: res } = await getAllBinType()
            if (res) {
                setBinTypes(res.data)
            }
        } catch (error) {
            toastError(error)
        }
        setLoading(false)
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Bin Type?')) {
                let { data: res } = await deleteBinType(id)
                if (res) {
                    getBinTypes()
                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getBinTypes()
    }, [])

    return (
        <Container>
            <SimpleCard title="View Bin Types">
                <ContentTable
                    title=""
                    columns={columns}
                    data={binTypes}
                    loading={loading}
                    reload={getBinTypes}
                    extraButtonFn={handleOpenAddModal}
                    extraButtonText="Add Bin Type"
                />
            </SimpleCard>

            {/* Edit Modal */}
            <Dialog 
                open={openEditModal} 
                onClose={handleCloseModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Edit Bin Type
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={selectedBinType?.name || ''}
                        onChange={(e) => setSelectedBinType({
                            ...selectedBinType,
                            name: e.target.value
                        })}
                        sx={{ mb: 3 }}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={selectedBinType?.description || ''}
                        onChange={(e) => setSelectedBinType({
                            ...selectedBinType,
                            description: e.target.value
                        })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleUpdate} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Modal */}
            <Dialog 
                open={openAddModal} 
                onClose={handleCloseAddModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    Add New Bin Type
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseAddModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newBinType.name}
                        onChange={(e) => setNewBinType({
                            ...newBinType,
                            name: e.target.value
                        })}
                        sx={{ mb: 3 }}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={newBinType.description}
                        onChange={(e) => setNewBinType({
                            ...newBinType,
                            description: e.target.value
                        })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddModal}>Cancel</Button>
                    <Button 
                        onClick={handleAdd} 
                        variant="contained" 
                        color="primary"
                        disabled={!newBinType.name.trim()}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
