import React, { useEffect, useState } from 'react'
import { Box, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { getProductionPlanningByDate } from 'app/services/MouldingPlanning.service'
import moment from 'moment'
import { returnShiftFromTime } from 'app/utils/utils'

// Enhanced styled table cell with improved typography
const StyledTableCell = styled(TableCell)(({ theme, header, subheader }) => ({
    ...(header && {
        backgroundColor: '#D9EAFD',
        color: '#000000',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: theme.spacing(1),
    }),
    ...(subheader && {
        backgroundColor: '#FFF7E0',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    }),
    border: '1px solid rgba(224, 224, 224, 1)',
    padding: theme.spacing(1),
}))

const CustomFormatPlanning = () => {
    const [date, setDate] = useState('')
    const [planning, setPlanning] = useState([])

    useEffect(() => {
        const fetchPlanningData = async () => {
            try {
                const response = await getProductionPlanningByDate(date)
                setPlanning(response.data.data)
            } catch (error) {
                console.error('Error fetching production planning data:', error)
            }
        }
        fetchPlanningData()
    }, [date])

    console.log(planning, 'planning')

    const groupedPlanning = planning.reduce((acc, row) => {
        const key = `${row.startTime}-${row.endTime}`
        if (!acc[key]) acc[key] = []
        acc[key].push(row)
        return acc
    }, {})

    const groupedByShift = {}

    Object.entries(groupedPlanning).forEach(([timeKey, rows]) => {
        const shift = returnShiftFromTime(timeKey)

        if (!groupedByShift[shift]) {
            groupedByShift[shift] = []
        }
        groupedByShift[shift].push(...rows.map((row) => ({ ...row, timeKey })))
    })

   

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, m: 3 }}>
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        color: '#1976d2',
                        textTransform: 'uppercase',
                        letterSpacing: '0.5px',
                        minWidth: '60px',
                    }}
                >
                    DATE:
                </Typography>
                <TextField
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    defaultValue={new Date().toISOString().split('T')[0]}
                    InputProps={{
                        style: {
                            fontSize: '1rem',
                            fontWeight: 500,
                        },
                        sx: {
                            '& input': {
                                py: 1.5,
                                color: '#333',
                            },
                            '&:hover': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1976d2',
                                },
                            },
                            '&.Mui-focused': {
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1976d2',
                                    borderWidth: 2,
                                },
                            },
                            borderRadius: 1,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            transition: 'all 0.3s ease',
                        },
                    }}
                    sx={{
                        width: '220px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#e0e0e0',
                            },
                        },
                    }}
                />
            </Box>
            {date && (
                <Box sx={{ p: 2, backgroundColor: '#fafafa' }}>
                    <Grid container spacing={2} sx={{ marginY: 2 }}>
                        <TableContainer component={Paper} sx={{ marginX: 2, overflowX: 'auto', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                            <Table size="small" aria-label="temperature parameters table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell colSpan={1} rowSpan={4} align="center">
                                            <img src={'/assets/images/moulding-sheet.png'} alt="logo" style={{ paddingY: '5px' }} />
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontWeight: '700', fontSize: '2rem', backgroundColor: '#1976d2', color: 'white' }}>
                                            DEPT.: MOULDING
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem', backgroundColor: '#e3f2fd' }}>
                                            DOC. NO.:
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                            MLD-F-001
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem', backgroundColor: '#e3f2fd' }}>
                                            REV NO.:
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                            01
                                        </StyledTableCell>
                                    </TableRow>

                                    <TableRow>
                                        <StyledTableCell colSpan={7} rowSpan={2} align="center" sx={{ fontSize: '1rem', backgroundColor: '#bbdefb' }}>
                                            TITLE : PROCESS PARAMETER SHEET (INJECTION MOULDING)
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem', backgroundColor: '#e3f2fd' }}>
                                            REV. DATE:
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                            10.10.2021
                                        </StyledTableCell>
                                    </TableRow>

                                    <TableRow>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem', backgroundColor: '#e3f2fd' }}>
                                            EFFT. DATE:
                                        </StyledTableCell>
                                        <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                            28.08.2024
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>

                        {Object.entries(groupedByShift).map(([shift, rows], groupIndex) => (
                            <TableContainer key={shift} component={Paper} sx={{ marginX: 2, overflowX: 'auto', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', marginTop: 2 }}>
                                <Table size="small" aria-label="temperature parameters table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell colSpan={3} sx={{ fontSize: '1rem' }}>
                                                DATE:
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={3} sx={{ fontSize: '1rem' }}>
                                                {date}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} sx={{ fontSize: '1rem' }}>
                                                SHIFT:
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '1rem' }} align="center">
                                                {shift}
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={6} sx={{ fontSize: '1rem' }}></StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell colSpan={3} sx={{ fontSize: '1rem' }}>
                                                SHIFT TIME:
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={3} sx={{ fontSize: '1rem' }}>
                                                {shift === 'A' ? '08:00 AM - 08:00 PM' : '08:00 PM - 08:00 AM'}
                                            </StyledTableCell>

                                            <StyledTableCell colSpan={1} sx={{ fontSize: '1rem' }}></StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '1rem' }}></StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '1rem' }}></StyledTableCell>
                                            <StyledTableCell colSpan={5} sx={{ fontSize: '1rem' }} align="center" header>
                                                PLANNING
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '1rem' }}></StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                SL. NO.
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                MACHINE
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                TIME (HH:MM)
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={2} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                ITEM NAME & NO.
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                CAVATY
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                GROSS WT.
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                CYCLE TIME
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                HOURLY PRODUCTION
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                PLANNED QTY
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                TOTAL REQ. TIME (Hrs)
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                PLAN RJM
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} rowSpan={2} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                TOTAL QTY
                                            </StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                NUMBER
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                TONNAGE
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={1} sx={{ fontSize: '0.85rem', backgroundColor: '#e3f2fd' }} align="center">
                                                QTY-KG
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => (
                                            <TableRow key={`${groupIndex}-${index}`} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                                                {/* {JSON.stringify(row,null,2)} */}
                                                <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                                <StyledTableCell align="center">{row.machineNumber}</StyledTableCell>
                                                <StyledTableCell align="center">{row.loadingCapacity}</StyledTableCell>
                                                <StyledTableCell colSpan={2} align="center">
                                                    {' '}
                                                    {moment(row.timeKey.split('-')[0], 'HH:mm').format('hh:mm A')} - {moment(row.timeKey.split('-')[1], 'HH:mm').format('hh:mm A')}
                                                </StyledTableCell>
                                                <StyledTableCell colSpan={2} align="left">
                                                    {row.productName}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{row.cavity}</StyledTableCell>
                                                <StyledTableCell align="center">{row.grossWt}</StyledTableCell>
                                                <StyledTableCell align="center">{row.cycleTime}</StyledTableCell>
                                                <StyledTableCell align="center">{row.hourlyProduction}</StyledTableCell>
                                                <StyledTableCell align="center">{row.totalPlannedProduction}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {Math.round(
                                                        moment(row.endTime, 'HH:mm').diff(
                                                            moment(row.startTime, 'HH:mm').isBefore(moment(row.endTime, 'HH:mm')) ? moment(row.startTime, 'HH:mm') : moment(row.startTime, 'HH:mm').subtract(1, 'day'),
                                                            'hours',
                                                            true
                                                        )
                                                    )}{' '}
                                                    hrs
                                                </StyledTableCell>

                                                <StyledTableCell align="center">{row?.rm}</StyledTableCell>
                                                <StyledTableCell align="center">{row.totalPlannedProduction}</StyledTableCell>
                                            </TableRow>
                                        ))}
                                        {/* <TableRow sx={{ backgroundColor: '#e3f2fd' }}>
                                            <StyledTableCell colSpan={3} sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                                TOTAL Qty
                                            </StyledTableCell>
                                            <StyledTableCell align="center" sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                                100
                                            </StyledTableCell>
                                            <StyledTableCell colSpan={10} sx={{ fontSize: '1rem', fontWeight: 'bold' }}></StyledTableCell>
                                        </TableRow> */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ))}
                    </Grid>
                </Box>
            )}
        </>
    )
}

export default CustomFormatPlanning
