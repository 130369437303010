import axios from 'axios'
import { API_URL } from '../utils/constant'
const url = `${API_URL}/ClientProductPlanning`

export const addClientProductPlanning = (data) => {
    return axios.post(`${url}/add`, data)
}

export const getClientProductPlanning = (query) => {
    return axios.get(`${url}/get?${query}`)
}

// Add other CRUD operations as needed
